import React, { useEffect, useState } from "react";
import { Field} from "formik";
import { useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { Register2val } from "../Validation/register2val";
import axios from "axios";
import { Register4val } from "../Validation/register4val";
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';

const Alternativeformtwo = ({unregisterdealer,gstdata,values,setFieldValue,errors,touched,setFieldError,handleSubmit,isValid,dirty,setFieldTouched}) => {
  const [verifystatus_gst, setverifystatus_gst] = useState(0);
  const [checkbox, setcheckbox] = useState(false);
  const [checkbox2, setcheckbox2] = useState(false);
  const [checkbox3, setcheckbox3] = useState(false);
  const [alternative, setalternative] = useState(false);
  const [gstData, setgstData] = useState({});
  const [mesgforerror, setmesgforerror] = useState("");
  const [currentStep, setCurrentStep] = useState(2);
  const [cityna,setcityna] = useState("")
  const [office_cityna,setoffice_cityna] = useState("")
  const [outlet_cityna,setoutlet_cityna] = useState("")

  const nvg = useNavigate();
// console.log("the is step 4",step1id)

  const fetchstateandcity = async (value,setFieldError,setFieldValue) =>{
    if(value.length == 6){
  try {
    let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
    const response = await axios.get(url);
    console.log(" new response vvv",response)
    if(response.data.city == "NA"){
      setFieldValue('office_city','');
      setoffice_cityna("NA");
      setFieldTouched('office_city', false);
    setFieldError('office_city',"Please Enter Your City")
    }else{
      setFieldValue('office_city',response.data.city);
      setFieldTouched('office_city', true);
      setcityna("");
    }
    setFieldValue('office_state',response.data.state)
    setFieldTouched('office_state', true);
    const countrydata = countriesdata.filter(item2=>item2.printable_name == response.data.country.trim())
    console.log(";;;;;;",countrydata)
    setFieldValue('office_country',countrydata[0].printable_name);
    setFieldTouched('office_country', true);
  } catch (error) {
    setFieldError('office_pincode',error.response.data.message)
  }
  }else{
    setFieldValue('office_city','')
    setFieldValue('office_state','')
    setFieldValue('office_country','')
    setFieldTouched('office_city',false)
    setFieldTouched('office_state',false)
    setFieldTouched('office_country',false)
  }
  }
  const fetchstateandcity2 = async (value,setFieldError,setFieldValue) =>{
    if(value.length == 6){
  try {
    let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
    const response = await axios.get(url);
    console.log(" new response vvv",response)
    if(response.data.city == "NA"){
      setFieldValue('outlet_city','');
      setFieldTouched('outlet_city', false);
      setoutlet_cityna("NA");
    setFieldError('outlet_city',"Please Enter Your City")
    }else{
      setFieldValue('outlet_city',response.data.city);
      setcityna("");
      setFieldTouched('outlet_city', true);

    }
    setFieldValue('outlet_state',response.data.state)
    setFieldTouched('outlet_state', true);

    const countrydata = countriesdata.filter(item2=>item2.printable_name == response.data.country.trim())
    console.log(";;;;;;",countrydata)
    setFieldValue('outlet_country',countrydata[0].printable_name);
    setFieldTouched('outlet_country', true);
  
  } catch (error) {
    setFieldError('outlet_pincode',error.response.data.message)
  }
  }else{
    setFieldValue('outlet_city','')
    setFieldValue('outlet_state','')
    setFieldValue('outlet_country','')
    setFieldTouched('outlet_city',false)
    setFieldTouched('outlet_state',false)
    setFieldTouched('outlet_country',false)
  }
  }


  const setgstaddress = (status,setFieldValue,setFieldError) =>{
if(status == false){
  setcheckbox(true)

  setFieldValue("office_address", `${
    gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name == null ? '' : gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name} ${
    (gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.door_number)
  } ${gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.location}`);
  // setFieldValue("office_address2",step1id.second_address);
  setFieldValue("office_postalcode",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.pincode);
  setFieldValue("office_state",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.state_name);
  setFieldValue("office_city",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.dst);
  setFieldValue("office_country",'India' );
  // setFieldError("office_address","dddd")
  // setFieldError("office_postalcode","23444")
  // setFieldError("office_state","")
  // setFieldError("office_city","")
  // setFieldError("office_country","")

}else{
  // setcheckbox(false)
  setFieldValue("office_address",'');
  // setFieldValue("office_address2",'');
  setFieldValue("office_postalcode",'');
  setFieldValue("office_state",'');
  setFieldValue("office_city",'');
  setFieldValue("office_country",'');
}
  }


  
  const setgstaddress2 = (status,setFieldValue) =>{
if(status == false){
  setcheckbox2(true)

  setFieldValue("outlet", `${
    gstdata[0]?.result?.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name == null ? '' : gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name} ${
    (gstdata[0]?.result?.source_output.principal_place_of_business_fields.principal_place_of_business_address.door_number)
  } ${gstdata[0]?.result?.source_output.principal_place_of_business_fields.principal_place_of_business_address.location}`);
  // setFieldValue("outlet2",step1id.second_address);
  setFieldValue("outlet_postalcode",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.pincode);
  setFieldValue("outlet_state",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.state_name);
  setFieldValue("outlet_city",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.dst);
  setFieldValue("outlet_country",'India' );
  setFieldError("outlet_address","")
  setFieldError("outlet_postalcode","")
  setFieldError("outlet_state","")
  setFieldError("outlet_city","")
  setFieldError("outlet_country","")

}else{
  // setcheckbox(false)
  setFieldValue("outlet_address",'');
  // setFieldValue("outlet_address2",'');
  setFieldValue("outlet_postalcode",'');
  setFieldValue("outlet_state",'');
  setFieldValue("outlet_city",'');
  setFieldValue("outlet_country",'');
}
  }





  const setgstaddress3 = (status,setFieldValue,value) =>{
    if(status == false){
      if(value.office_postalcode.length == 6){
        setcheckbox3(true)
     
        setFieldValue("outlet_address", value.office_address);
        // setFieldValue("outlet_address2",step1id.second_address);
        setFieldValue("outlet_postalcode",value.office_postalcode);
        setFieldValue("outlet_state",value.office_state);
        setFieldValue("outlet_city",value.office_city);
        setFieldValue("outlet_country",value.office_country );
        setFieldError("outlet_address","")
  setFieldError("outlet_postalcode","")
  setFieldError("outlet_state","")
  setFieldError("outlet_city","")
  setFieldError("outlet_country","")
      }
     
    }else{
      // setcheckbox(false)
      setFieldValue("outlet_address",'');
      // setFieldValue("outlet_address2",'');
      setFieldValue("outlet_postalcode",'');
      setFieldValue("outlet_state",'');
      setFieldValue("outlet_city",'');
      setFieldValue("outlet_country",'');
    }
      }




//   const autofillvalue3 = (setFieldValue,step1idcopy) =>{
//     console.log("this is form next fun",step1idcopy)
// setTimeout(() => {
//   if(step1id.first_address == step1idcopy.first_address && step1id.second_address == step1idcopy.second_address && step1id.postal_code == step1idcopy.postal_code && step1id.state == step1idcopy.state && step1id.city == step1idcopy.city && step1id.country == step1idcopy.country){
//     setcheckbox(true)
//   }
//   setFieldValue("address",step1idcopy.first_address);
//   setFieldValue("address2",step1idcopy.second_address);
//   setFieldValue("postalcode",step1idcopy.postal_code);
//   setFieldValue("state",step1idcopy.state);
//   setFieldValue("city",step1idcopy.city);
//   setFieldValue("country",step1idcopy.country );
// }, 300);
    
 
//   }

  const [countriesdata,setcountriesdata] = useState([])
  useEffect(()=>{ 
 async function getcountries() {
 let url = `${process.env.REACT_APP_API_URL}api/countries/`;
   try {
     const response = await axios.get(url);
     setcountriesdata(response.data)
   } catch (error) {
   }
 }
 getcountries()
   },[])

  return (
          <div className="">
                  <div className="row">
                  
                    
                <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px",display:'flex',justifyContent:'space-between' }}
                          htmlFor="text"
                        > 
                        <span>
                         Business Address <span style={{color:'red'}}>*</span>
                        </span>
                        {/* <span style={{fontSize:'13px',display:unregisterdealer == true ? "none" : ''}}>
                        {checkbox == false ? <input type="checkbox" name="" id="" onChange={()=>{setcheckbox(!checkbox);setgstaddress(checkbox,setFieldValue,setFieldError)}} /> : <input type="checkbox" onChange={()=>{setcheckbox(!checkbox);setgstaddress(checkbox,setFieldValue,setFieldError)}} checked name="" id="" /> }  
                        Same As GST Address
                        </span> */}
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="office_address"
                              as="textarea"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Business Address`}
                            value={values.office_address}
                            style={{minHeight:"80px",border:'1px solid #ced4da ', borderColor:
                              touched.office_address && errors.office_address
                                ? "red"
                                : touched.office_address && !errors.office_address
                                ? values.office_address == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            
                            onChange={(e) => {
                              setFieldValue("office_address", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.office_address && touched.office_address ? (
                            <p style={{ color: "red" }}>{errors.office_address}</p>
                          ) : null} */}
                        </span>
                    
                      </div>   


                      {/* <div style={{position:'relative',overflow:'hidden'}}>
                      <button type="button" ref={buttonRefthree} style={{position:'absolute',visibility:'hidden',top:'0px',left:'0px'}} onClick={()=>{autofillvalue3(setFieldValue,step3id)}} ></button>
</div> */}

                      
                    


                      {/* <div className="col-lg-12 col-md-12">
  <h4 style={{fontSize:'19px'}}>ALTERNATIVE CONTACT PERSON DETAILS</h4>
</div> */}
                 <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Pincode <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="office_postalcode"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Pincode`}
                            value={values.office_postalcode}
                         style={{border:'1px solid #ced4da ', borderColor:
                          touched.office_postalcode && errors.office_postalcode
                            ? "red"
                            : touched.office_postalcode && !errors.office_postalcode
                            ? values.office_postalcode == "" ? "#ced4da" : "green"
                            : "#ced4da"}}
                         
                          onChange={(e)=>{setFieldValue("office_postalcode",e.target.value.replace(/[^0-9\ ]/gi, ""));fetchstateandcity(e.target.value.replace(/[^0-9\ ]/gi, ""),setFieldError,setFieldValue)}}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.office_postalcode && touched.office_postalcode ? (
                            <p style={{ color: "red" }}>{errors.office_postalcode}</p>
                          ) : null} */}
                        </span>
  
                       
                      </div>   
                      { values.office_state !== '' && values.office_state !== null && office_cityna == 'NA' ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="office_city"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your City`}
                            value={values.office_city}
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.office_city && errors.office_city
                                ? "red"
                                : touched.office_city && !errors.office_city
                                ? values.office_city == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                        
                            onChange={(e) => {
                              setFieldValue("office_city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.office_city && touched.office_city ? (
                            <p style={{ color: "red" }}>{errors.office_city}</p>
                          ) : null} */}
                        </span>
                      </div> : <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="office_city"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your City`}
                            value={values.office_city}
                            readOnly
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.office_city && errors.office_city
                                ? "red"
                                : touched.office_city && !errors.office_city
                                ? values.office_city == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                          
                            onChange={(e) => {
                              setFieldValue("office_city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.office_city && touched.office_city ? (
                            <p style={{ color: "red" }}>{errors.office_city}</p>
                          ) : null} */}
                        </span>
                        
                      </div>}
                       
                      <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          State <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="office_state"
                          readOnly
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your State`}
                            value={values.office_state}
                          style={{border:'1px solid #ced4da ', borderColor:
                            touched.office_state && errors.office_state
                              ? "red"
                              : touched.office_state && !errors.office_state
                              ? values.office_state == "" ? "#ced4da" : "green"
                              : "#ced4da"}}
                            onChange={(e) => {
                              setFieldValue("office_state", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.office_state && touched.office_state ? (
                            <p style={{ color: "red" }}>{errors.office_state}</p>
                          ) : null} */}
                        </span>

                      </div> 

                     
                    <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Country <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="office_country"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Country`}
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.office_country && errors.office_country
                                ? "red"
                                : touched.office_country && !errors.office_country
                                ? values.office_country == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                          readOnly
                            value={values.office_country}
                            // style={{
                            //   borderBottom:
                            //     errors.office_country && touched.office_country
                            //       ? "1px solid red"
                            //       : "",
                            // }}
                            onChange={(e) => {
                              setFieldValue("office_country", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.office_country && touched.office_country ? (
                            <p style={{ color: "red" }}>{errors.office_country}</p>
                          ) : null} */}
                        </span>

                      </div>


                      <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px",display:'flex',justifyContent:'space-between' }}
                          htmlFor="text"
                        > <span>
                          Outlet Address 
                        
                        </span>

                        {/* <span style={{fontSize:'14px',display:unregisterdealer == true ? "none" :'flex',flexDirection:'column'}}> */}
                        {/* {checkbox3 == false ? <span style={{fontSize:'13px'}} >
                        {checkbox2 == false ? <input type="checkbox" name="" id="" onChange={()=>{setcheckbox2(!checkbox2);setgstaddress2(checkbox2,setFieldValue)}} /> : <input type="checkbox" onChange={()=>{setcheckbox2(!checkbox2);setgstaddress2(checkbox2,setFieldValue)}} checked name="" id="" /> }  
                        Same As GST Address
                          </span> : ''} */}


                          {/* {checkbox2 == false ? <span style={{fontSize:'13px',display:values.office_postalcode == gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.pincode && values.office_address == `${
    gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name == null ? '' : gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name} ${
    (gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.door_number)
  } ${gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.location}` ? "none" : ''}}>

               {checkbox3 == false ? <input type="checkbox" name="" id="" onChange={()=>{setcheckbox3(!checkbox3);setgstaddress3(checkbox3,setFieldValue,values)}} /> : <input type="checkbox" onChange={()=>{setcheckbox3(!checkbox3);setgstaddress3(checkbox3,setFieldValue,values)}} checked name="" id="" /> }  
                        Same As Registered Address
                          </span> : ''} */}
                        {/* </span> */}






                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                              as="textarea"
                            name="outlet_address"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Outlet Address`}
                            value={values.outlet_address}
                            style={{minHeight:"80px",border:'1px solid #ced4da ', borderColor:
                              touched.outlet_address && errors.outlet_address
                                ? "red"
                                : touched.outlet_address && !errors.outlet_address
                                ? values.outlet_address == "" ? "#ced4da" : "green"
                                : "#ced4da"
                            }}
                            onChange={(e) => {
                              setFieldValue("outlet_address", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.outlet_address && touched.outlet_address ? (
                            <p style={{ color: "red" }}>{errors.outlet_address}</p>
                          ) : null} */}
                        </span>
                     
                      </div>  






                      <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Pincode 
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="outlet_postalcode"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Pincode`}
                            value={values.outlet_postalcode}
                        style={{border:'1px solid #ced4da ', borderColor:
                          touched.outlet_postalcode && errors.outlet_postalcode
                            ? "red"
                            : touched.outlet_postalcode && !errors.outlet_postalcode
                            ? values.outlet_postalcode == "" ? "#ced4da" : "green"
                            : "#ced4da"}}
                         
                          onChange={(e)=>{setFieldValue("outlet_postalcode",e.target.value.replace(/[^0-9\ ]/gi, ""));fetchstateandcity2(e.target.value.replace(/[^0-9\ ]/gi, ""),setFieldError,setFieldValue)}}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.outlet_postalcode && touched.outlet_postalcode ? (
                            <p style={{ color: "red" }}>{errors.outlet_postalcode}</p>
                          ) : null} */}
                        </span>
  
                       
                      </div> 
                      {values.outlet_state !== '' && values.outlet_state !== null && outlet_cityna == 'NA' ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City 
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="outlet_city"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your City`}
                            value={values.outlet_city}
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.outlet_city && errors.outlet_city
                                ? "red"
                                : touched.outlet_city && !errors.outlet_city
                                ? values.outlet_city == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            // style={{
                            //   borderBottom:
                            //     errors.outlet_city && touched.outlet_city
                            //       ? "1px solid red"
                            //       : "",
                            // }}
                            onChange={(e) => {
                              setFieldValue("outlet_city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.outlet_city && touched.outlet_city ? (
                            <p style={{ color: "red" }}>{errors.outlet_city}</p>
                          ) : null} */}
                        </span>
                      </div> : <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City 
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="outlet_city"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your City`}
                            value={values.outlet_city}
                            readOnly
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.outlet_city && errors.outlet_city
                                ? "red"
                                : touched.outlet_city && !errors.outlet_city
                                ? values.outlet_city == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            onChange={(e) => {
                              setFieldValue("outlet_city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.outlet_city && touched.outlet_city ? (
                            <p style={{ color: "red" }}>{errors.outlet_city}</p>
                          ) : null} */}
                        </span>
                        
                      </div>}
                       
                       <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          State 
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="outlet_state"
                          readOnly
                            className="login-input mb-0"
                            // placeholder={`Enter Your State`}
                            value={values.outlet_state}
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.outlet_state && errors.outlet_state
                                ? "red"
                                : touched.outlet_state && !errors.outlet_state
                                ? values.outlet_state == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            onChange={(e) => {
                              setFieldValue("outlet_state", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.outlet_state && touched.outlet_state ? (
                            <p style={{ color: "red" }}>{errors.outlet_state}</p>
                          ) : null} */}
                        </span>

                      </div> 

                     
                      <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Country 
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="outlet_country"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Country`}
                          readOnly
                            value={values.outlet_country}
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.outlet_country && errors.outlet_country
                                ? "red"
                                : touched.outlet_country && !errors.outlet_country
                                ? values.outlet_country == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            
                            onChange={(e) => {
                              setFieldValue("outlet_country", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.outlet_country && touched.outlet_country ? (
                            <p style={{ color: "red" }}>{errors.outlet_country}</p>
                          ) : null} */}
                        </span>

                      </div>
                       
                     

{/* <div className="col-xl-12 col-md-12">
  <h4 className="py-2"> 
   <input type="checkbox" name="" style={{padding:'25px'}} onChange={()=>{setalternative(!alternative)}} id="" />  ALTERNATIVE CONTACT PERSON DETAILS
  </h4>
</div> */}


     {/* {alternative == false ? "" : <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Alternative Contact Person Name <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="alt_name"
                            className="login-input mb-0"
                            placeholder={`Enter Alternative Contact Person Name`}
                            value={values.alt_name}
                          
                            onChange={(e) => {
                              setFieldValue("alt_name", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                      
                        </span>

                      </div>}
                      {alternative == false ? "" : <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Alternative Contact Person Designation <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="alt_des"
                            className="login-input mb-0"
                            placeholder={`Enter Alternative Contact Person Designation`}
         
                            value={values.alt_des}
                 
                            onChange={(e) => {
                              setFieldValue("alt_des", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                     
                        </span>

                      </div>}
                      {alternative == false ? "" : <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Alternative Contact Person Email <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="alt_email"
                            className="login-input mb-0"
                            placeholder={`Enter Alternative Contact Person Email`}
                            value={values.alt_email}
                           
                            onChange={(e) => {
                              setFieldValue("alt_email", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                        
                        </span>

                      </div>}


                      {alternative == false ? "" : <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Alternative Contact Person Mobile <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="alt_mobile"
                            className="login-input mb-0"
                            placeholder={`Enter Alternative Contact Person Mobile No`}
                            value={values.alt_mobile}
                            onChange={(e) => {
                              setFieldValue("alt_mobile", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                         
                        </span>

                      </div>} */}






                  </div>
               
          </div>
       
  );
};

export default Alternativeformtwo;
