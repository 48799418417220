import * as Yup from "yup";

export const Register6val = Yup.object({
  companytype: Yup.string()
    .label("Company Type")
    .required("Company Type is required"),
    // companytype2: Yup.string()
    // .label("Company Type")
    // .required("Company Type is required"),
    industrytype: Yup.string()
    .label("Intustry Type")
    .required("Nature of Business is required"),
    weblink: Yup.string(),
  contactperson: Yup.string()
    .label("Name of Account User")
    .required("Name of Account User is required"),
    companyname: Yup.string()
    .label("Company Name")
    .required("Name of Company is required"),
    designation: Yup.string()
    .label("Designation")
    .required("Designation is required"),
    email: Yup.string()
    .label("Email Address")
    .email()
    .required("Email Address is required"),
    mobile: Yup.number()
    .label("Mobile No")
    .min(1000000000, "Mobile number must be at least 10 digits")
    .max(9999999999, "Mobile number cannot be more than 10 digits")
    .required("Please Enter Your Mobile Number"),
      mobileotp: Yup.string()
    .required("Please Enter Your Mobile OTP"),
      emailotp: Yup.string()
    .required("Please Enter Your Email OTP"),
    terms: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions"),
    GSTIN: Yup.string()
    .label("GST No"),
    // .required("GST No is required"),
    // businessname: Yup.string()
    // .label("businessname"),
    // address: Yup.string()
    // .label("Address")
    // .max(100)
    // .required("Address is required"),
    // address2: Yup.string()
    // .label("Address 2")
    // .max(60)
    // .required("Address 2 is required"),
    // postalcode: Yup.number()
    // .label("Pincode")
    // .min(100000, "Pincode must be at least 6 digits")
    // .max(999999, "Pincode cannot be more than 6 digits")
    // .required("Pincode is required"),
    // city: Yup.string()
    // .required("City is required"),
    // state: Yup.string()
    // .required(" State is required"),
    // country: Yup.string()
    // .required("Country is required"),
    pan: Yup.string()
    .required("Pan Card is required"),
    // .min(1000000000, "PAN No must be at least 10 digits")
    // .max(9999999999, "PAN No cannot be more than 10 digits"),
    // businesslegaltype: Yup.string()
    // .label("Business Legal Type")
    // .required(" Business Legal Type is required"),
    registrationdate: Yup.date()
    .label("Date of Establishment")
    .required("Date of Establishment is required"),
    // alt_name: Yup.string()
    // .label("Full Name"),
    // .required("Full Name is required"),
    // alt_des: Yup.string()
    // .label("Designation"),
    // .required("Designation is required"),
    // alt_email: Yup.string()
    // .label("Email ID")
    // .email(),
    // .required("Email ID is required"),
    // alt_mobile: Yup.number()
    // .label("Mobile No")
    // .min(1000000000, "Mobile number must be at least 10 digits")
    // .max(9999999999, "Mobile number cannot be more than 10 digits"),
    // .required("Please Enter Your Mobile Number"),
    reg_address: Yup.string()
    .label("Registered Address")
    .max(140)
    .required("Registered Address is required"),
    reg_postalcode: Yup.number()
    .label("Pincode")
    .min(100000, "Pincode must be at least 6 digits")
    .max(999999, "Pincode cannot be more than 6 digits")
    .required("Pincode is required"),
    reg_city: Yup.string()
    .label("City")
    .required("City is required"),
    reg_state: Yup.string()
    .label("State")
    .required(" State is required"),
    reg_country: Yup.string()
    .required("Country is required"),
    office_address: Yup.string()
    .label("Business Address")
    .required()
    .max(140)
    .required("Address is required"),
    office_postalcode: Yup.number()
    .label("Pincode")
    .min(100000, "Pincode must be at least 6 digits")
    .max(999999, "Pincode cannot be more than 6 digits")
    .required("Pincode is required"),
    office_city: Yup.string()
    .required("City is required"),
    office_state: Yup.string()
    .required(" State is required"),
    office_country: Yup.string()
    .required("Country is required"),
    outlet_address: Yup.string()
    .label("Outlet Address")
    // .required()
    .max(140),
    // .required("Address is required"),
    outlet_postalcode: Yup.number()
    .label("Pincode")
    .min(100000, "Pincode must be at least 6 digits")
    .max(999999, "Pincode cannot be more than 6 digits"),
    // .required("Pincode is required"),
    outlet_city: Yup.string(),
    // .required("City is required"),
    outlet_state: Yup.string(),
    // .required(" State is required"),
    outlet_country: Yup.string(),
    // .required("Country is required"),
    war_address: Yup.string()
    .label("Warehouse Address")
    // .required()
    .max(140),
    // .required("Address is required"),
    war_postalcode: Yup.number()
    .label("Pincode")
    .min(100000, "Pincode must be at least 6 digits")
    .max(999999, "Pincode cannot be more than 6 digits"),
    // .required("Pincode is required"),
    war_city: Yup.string(),
    // .required("City is required"),
    war_state: Yup.string(),
    // .required(" State is required"),
    war_country: Yup.string(),
    // .required("Country is required"),
});
