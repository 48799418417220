import React, { useEffect, useState } from "react";
import { Field} from "formik";
import { useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { Register2val } from "../Validation/register2val";
import axios from "axios";
import { Register4val } from "../Validation/register4val";
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';

const Alternativeform = ({unregisterdealer,gstdata,values,setFieldValue,errors,touched,setFieldError,handleSubmit,isValid,dirty,setFieldTouched}) => {
  const [verifystatus_gst, setverifystatus_gst] = useState(0);
  const [checkbox, setcheckbox] = useState(false);
  const [checkbox2, setcheckbox2] = useState(false);
  const [checkbox3, setcheckbox3] = useState(false);
  const [alternative, setalternative] = useState(false);
  const [gstData, setgstData] = useState({});
  const [mesgforerror, setmesgforerror] = useState("");
  const [currentStep, setCurrentStep] = useState(2);
  const [cityna,setcityna] = useState("")
  const [reg_cityna,setreg_cityna] = useState("")
  const [war_cityna,setwar_cityna] = useState("")

  const nvg = useNavigate();
// console.log("the is step 4",step1id)

  const fetchstateandcity = async (value,setFieldError,setFieldValue) =>{
    if(value.length == 6){
  try {
    let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
    const response = await axios.get(url);
    console.log(" new response vvv",response)
    if(response.data.city == "NA"){
      setFieldValue('reg_city','');
      setreg_cityna("NA");
      setFieldTouched('reg_city',false)
    setFieldError('reg_city',"Please Enter Your City")
    }else{
      setFieldValue('reg_city',response.data.city);
      setFieldTouched('reg_city',true)
      setcityna("");
    }
    setFieldValue('reg_state',response.data.state)
    setFieldTouched('reg_state',true)
    const countrydata = countriesdata.filter(item2=>item2.printable_name == response.data.country.trim())
    console.log(";;;;;;",countrydata)
    setFieldValue('reg_country',countrydata[0].printable_name);
    setFieldTouched('reg_country',true)

  
  } catch (error) {
    setFieldError('reg_pincode',error.response.data.message)
  }
  }else{
    setFieldValue('reg_city','')
    setFieldValue('reg_state','')
    setFieldValue('reg_country','')
    // setFieldTouched('reg_city',false)
    // setFieldTouched('reg_state',false)
    // setFieldTouched('reg_country',false)
  }
  }
  const fetchstateandcity2 = async (value,setFieldError,setFieldValue) =>{
    if(value.length == 6){
  try {
    let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
    const response = await axios.get(url);
    console.log(" new response vvv",response)
    if(response.data.city == "NA"){
      setFieldValue('war_city','');
      setwar_cityna("NA");
      setFieldTouched('war_city',false)

    setFieldError('war_city',"Please Enter Your City")
    }else{
      setFieldValue('war_city',response.data.city);
      setcityna("");
      setFieldTouched('war_city',true)

    }
    setFieldValue('war_state',response.data.state)
    setFieldTouched('war_state',true)

    const countrydata = countriesdata.filter(item2=>item2.printable_name == response.data.country.trim())
    console.log(";;;;;;",countrydata)
    setFieldValue('war_country',countrydata[0].printable_name);
    setFieldTouched('war_country',true)
  
  } catch (error) {
    setFieldError('war_pincode',error.response.data.message)
  }
  }else{
    setFieldValue('war_city','')
    setFieldValue('war_state','')
    setFieldValue('war_country','')
    setFieldTouched('war_city',false)
    setFieldTouched('war_state',false)
    setFieldTouched('war_country',false)
  }
  }


  const setgstaddress = (status,setFieldValue,setFieldError) =>{
if(status == false){
  setcheckbox(true)

  setFieldValue("reg_address", `${
    gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name == null ? '' : gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name} ${
    (gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.door_number)
  } ${gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.location}`);
  // setFieldValue("reg_address2",step1id.second_address);
  setFieldValue("reg_postalcode",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.pincode);
  setFieldValue("reg_state",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.state_name);
  setFieldValue("reg_city",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.dst);
  setFieldValue("reg_country",'India' );
  // setFieldError("reg_address","dddd")
  // setFieldError("reg_postalcode","23444")
  // setFieldError("reg_state","")
  // setFieldError("reg_city","")
  // setFieldError("reg_country","")

}else{
  // setcheckbox(false)
  setFieldValue("reg_address",'');
  // setFieldValue("reg_address2",'');
  setFieldValue("reg_postalcode",'');
  setFieldValue("reg_state",'');
  setFieldValue("reg_city",'');
  setFieldValue("reg_country",'');
}
  }


  
  const setgstaddress2 = (status,setFieldValue) =>{
if(status == false){
  setcheckbox2(true)

  setFieldValue("war_address", `${
    gstdata[0]?.result?.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name == null ? '' : gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name} ${
    (gstdata[0]?.result?.source_output.principal_place_of_business_fields.principal_place_of_business_address.door_number)
  } ${gstdata[0]?.result?.source_output.principal_place_of_business_fields.principal_place_of_business_address.location}`);
  // setFieldValue("war_address2",step1id.second_address);
  setFieldValue("war_postalcode",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.pincode);
  setFieldValue("war_state",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.state_name);
  setFieldValue("war_city",gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.dst);
  setFieldValue("war_country",'India' );
  setFieldError("war_address","")
  setFieldError("war_postalcode","")
  setFieldError("war_state","")
  setFieldError("war_city","")
  setFieldError("war_country","")

}else{
  // setcheckbox(false)
  setFieldValue("war_address",'');
  // setFieldValue("war_address2",'');
  setFieldValue("war_postalcode",'');
  setFieldValue("war_state",'');
  setFieldValue("war_city",'');
  setFieldValue("war_country",'');
}
  }





  const setgstaddress3 = (status,setFieldValue,value) =>{
    if(status == false){
      if(value.reg_postalcode.length == 6){
        setcheckbox3(true)
     
        setFieldValue("war_address", value.reg_address);
        // setFieldValue("war_address2",step1id.second_address);
        setFieldValue("war_postalcode",value.reg_postalcode);
        setFieldValue("war_state",value.reg_state);
        setFieldValue("war_city",value.reg_city);
        setFieldValue("war_country",value.reg_country );
        setFieldError("war_address","")
  setFieldError("war_postalcode","")
  setFieldError("war_state","")
  setFieldError("war_city","")
  setFieldError("war_country","")
      }
     
    }else{
      // setcheckbox(false)
      setFieldValue("war_address",'');
      // setFieldValue("war_address2",'');
      setFieldValue("war_postalcode",'');
      setFieldValue("war_state",'');
      setFieldValue("war_city",'');
      setFieldValue("war_country",'');
    }
      }




//   const autofillvalue3 = (setFieldValue,step1idcopy) =>{
//     console.log("this is form next fun",step1idcopy)
// setTimeout(() => {
//   if(step1id.first_address == step1idcopy.first_address && step1id.second_address == step1idcopy.second_address && step1id.postal_code == step1idcopy.postal_code && step1id.state == step1idcopy.state && step1id.city == step1idcopy.city && step1id.country == step1idcopy.country){
//     setcheckbox(true)
//   }
//   setFieldValue("address",step1idcopy.first_address);
//   setFieldValue("address2",step1idcopy.second_address);
//   setFieldValue("postalcode",step1idcopy.postal_code);
//   setFieldValue("state",step1idcopy.state);
//   setFieldValue("city",step1idcopy.city);
//   setFieldValue("country",step1idcopy.country );
// }, 300);
    
 
//   }

  const [countriesdata,setcountriesdata] = useState([])
  useEffect(()=>{ 
 async function getcountries() {
 let url = `${process.env.REACT_APP_API_URL}api/countries/`;
   try {
     const response = await axios.get(url);
     setcountriesdata(response.data)
   } catch (error) {
   }
 }
 getcountries()
   },[])

  return (
          <div className="">
                  <div className="row">
                  
                    
                <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px",display:'flex',justifyContent:'space-between' }}
                          htmlFor="text"
                        > 
                        <span>
                         Registered Address <span style={{color:'red'}}>*</span>
                        </span>
                        {/* <span style={{fontSize:'13px',display:unregisterdealer == true ? "none" : ''}}>
                        {checkbox == false ? <input type="checkbox" name="" id="" onChange={()=>{setcheckbox(!checkbox);setgstaddress(checkbox,setFieldValue,setFieldError)}} /> : <input type="checkbox" onChange={()=>{setcheckbox(!checkbox);setgstaddress(checkbox,setFieldValue,setFieldError)}} checked name="" id="" /> }  
                        Same As GST Address
                        </span> */}
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                              as="textarea"
                            name="reg_address"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Registered Address`}
                            value={values.reg_address}
                            style={{minHeight:"80px",border:'1px solid #ced4da ', borderColor:
                              touched.reg_address && errors.reg_address
                                ? "red"
                                : touched.reg_address && !errors.reg_address
                                ? values.reg_address == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            
                            onChange={(e) => {
                              setFieldValue("reg_address", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.reg_address && touched.reg_address ? (
                            <p style={{ color: "red" }}>{errors.reg_address}</p>
                          ) : null} */}
                        </span>
                    
                      </div>   


                      {/* <div style={{position:'relative',overflow:'hidden'}}>
                      <button type="button" ref={buttonRefthree} style={{position:'absolute',visibility:'hidden',top:'0px',left:'0px'}} onClick={()=>{autofillvalue3(setFieldValue,step3id)}} ></button>
</div> */}

                      
                    


                      {/* <div className="col-lg-12 col-md-12">
  <h4 style={{fontSize:'19px'}}>ALTERNATIVE CONTACT PERSON DETAILS</h4>
</div> */}
                 <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Pincode <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="reg_postalcode"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Pincode`}
                            value={values.reg_postalcode}
                         style={{border:'1px solid #ced4da ', borderColor:
                          touched.reg_postalcode && errors.reg_postalcode
                            ? "red"
                            : touched.reg_postalcode && !errors.reg_postalcode
                            ? values.reg_postalcode == "" ? "#ced4da" : "green"
                            : "#ced4da"}}
                         
                          onChange={(e)=>{setFieldValue("reg_postalcode",e.target.value.replace(/[^0-9\ ]/gi, ""));fetchstateandcity(e.target.value.replace(/[^0-9\ ]/gi, ""),setFieldError,setFieldValue)}}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.reg_postalcode && touched.reg_postalcode ? (
                            <p style={{ color: "red" }}>{errors.reg_postalcode}</p>
                          ) : null} */}
                        </span>
  
                       
                      </div>   
                      { values.reg_state !== '' && values.reg_state !== null && reg_cityna == 'NA' ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="reg_city"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your City`}
                            value={values.reg_city}
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.reg_city && errors.reg_city
                                ? "red"
                                : touched.reg_city && !errors.reg_city
                                ? values.reg_city == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                        
                            onChange={(e) => {
                              setFieldValue("reg_city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.reg_city && touched.reg_city ? (
                            <p style={{ color: "red" }}>{errors.reg_city}</p>
                          ) : null} */}
                        </span>
                      </div> : <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="reg_city"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your City`}
                            value={values.reg_city}
                            readOnly
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.reg_city && errors.reg_city
                                ? "red"
                                : touched.reg_city && !errors.reg_city
                                ? values.reg_city == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                          
                            onChange={(e) => {
                              setFieldValue("reg_city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.reg_city && touched.reg_city ? (
                            <p style={{ color: "red" }}>{errors.reg_city}</p>
                          ) : null} */}
                        </span>
                        
                      </div>}
                       
                      <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          State <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="reg_state"
                          readOnly
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your State`}
                            value={values.reg_state}
                          style={{border:'1px solid #ced4da ', borderColor:
                            touched.reg_state && errors.reg_state
                              ? "red"
                              : touched.reg_state && !errors.reg_state
                              ? values.reg_state == "" ? "#ced4da" : "green"
                              : "#ced4da"}}
                            onChange={(e) => {
                              setFieldValue("reg_state", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.reg_state && touched.reg_state ? (
                            <p style={{ color: "red" }}>{errors.reg_state}</p>
                          ) : null} */}
                        </span>

                      </div> 

                     
                    <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Country <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="reg_country"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Country`}
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.reg_country && errors.reg_country
                                ? "red"
                                : touched.reg_country && !errors.reg_country
                                ? values.reg_country == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                          readOnly
                            value={values.reg_country}
                            // style={{
                            //   borderBottom:
                            //     errors.reg_country && touched.reg_country
                            //       ? "1px solid red"
                            //       : "",
                            // }}
                            onChange={(e) => {
                              setFieldValue("reg_country", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.reg_country && touched.reg_country ? (
                            <p style={{ color: "red" }}>{errors.reg_country}</p>
                          ) : null} */}
                        </span>

                      </div>


                      <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px",display:'flex',justifyContent:'space-between' }}
                          htmlFor="text"
                        > <span>
                          Warehouse Address 
                        
                        </span>

                        {/* <span style={{fontSize:'14px',display:unregisterdealer == true ? "none" :'flex',flexDirection:'column'}}> */}
                        {/* {checkbox3 == false ? <span style={{fontSize:'13px'}} >
                        {checkbox2 == false ? <input type="checkbox" name="" id="" onChange={()=>{setcheckbox2(!checkbox2);setgstaddress2(checkbox2,setFieldValue)}} /> : <input type="checkbox" onChange={()=>{setcheckbox2(!checkbox2);setgstaddress2(checkbox2,setFieldValue)}} checked name="" id="" /> }  
                        Same As GST Address
                          </span> : ''} */}


                          {/* {checkbox2 == false ? <span style={{fontSize:'13px',display:values.reg_postalcode == gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.pincode && values.reg_address == `${
    gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name == null ? '' : gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name} ${
    (gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.door_number)
  } ${gstdata[0]?.result.source_output.principal_place_of_business_fields.principal_place_of_business_address.location}` ? "none" : ''}}>

               {checkbox3 == false ? <input type="checkbox" name="" id="" onChange={()=>{setcheckbox3(!checkbox3);setgstaddress3(checkbox3,setFieldValue,values)}} /> : <input type="checkbox" onChange={()=>{setcheckbox3(!checkbox3);setgstaddress3(checkbox3,setFieldValue,values)}} checked name="" id="" /> }  
                        Same As Registered Address
                          </span> : ''} */}
                        {/* </span> */}






                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="war_address"
                            as="textarea"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Warehouse Address`}
                            value={values.war_address}
                            style={{minHeight:"80px",border:'1px solid #ced4da ', borderColor:
                              touched.war_address && errors.war_address
                                ? "red"
                                : touched.war_address && !errors.war_address
                                ? values.war_address == "" ? "#ced4da" : "green"
                                : "#ced4da"
                            }}
                            onChange={(e) => {
                              setFieldValue("war_address", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.war_address && touched.war_address ? (
                            <p style={{ color: "red" }}>{errors.war_address}</p>
                          ) : null} */}
                        </span>
                     
                      </div>  






                      <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Pincode 
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="war_postalcode"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Pincode`}
                            value={values.war_postalcode}
                        style={{border:'1px solid #ced4da ', borderColor:
                          touched.war_postalcode && errors.war_postalcode
                            ? "red"
                            : touched.war_postalcode && !errors.war_postalcode
                            ? values.war_postalcode == "" ? "#ced4da" : "green"
                            : "#ced4da"}}
                         
                          onChange={(e)=>{setFieldValue("war_postalcode",e.target.value.replace(/[^0-9\ ]/gi, ""));fetchstateandcity2(e.target.value.replace(/[^0-9\ ]/gi, ""),setFieldError,setFieldValue)}}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.war_postalcode && touched.war_postalcode ? (
                            <p style={{ color: "red" }}>{errors.war_postalcode}</p>
                          ) : null} */}
                        </span>
  
                       
                      </div> 
                      {values.war_state !== '' && values.war_state !== null && war_cityna == 'NA' ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City 
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="war_city"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your City`}
                            value={values.war_city}
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.war_city && errors.war_city
                                ? "red"
                                : touched.war_city && !errors.war_city
                                ? values.war_city == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            // style={{
                            //   borderBottom:
                            //     errors.war_city && touched.war_city
                            //       ? "1px solid red"
                            //       : "",
                            // }}
                            onChange={(e) => {
                              setFieldValue("war_city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.war_city && touched.war_city ? (
                            <p style={{ color: "red" }}>{errors.war_city}</p>
                          ) : null} */}
                        </span>
                      </div> : <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City 
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="war_city"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your City`}
                            value={values.war_city}
                            readOnly
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.war_city && errors.war_city
                                ? "red"
                                : touched.war_city && !errors.war_city
                                ? values.war_city == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            onChange={(e) => {
                              setFieldValue("war_city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.war_city && touched.war_city ? (
                            <p style={{ color: "red" }}>{errors.war_city}</p>
                          ) : null} */}
                        </span>
                        
                      </div>}
                       
                       <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          State 
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="war_state"
                          readOnly
                            className="login-input mb-0"
                            // placeholder={`Enter Your State`}
                            value={values.war_state}
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.war_state && errors.war_state
                                ? "red"
                                : touched.war_state && !errors.war_state
                                ? values.war_state == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            onChange={(e) => {
                              setFieldValue("war_state", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.war_state && touched.war_state ? (
                            <p style={{ color: "red" }}>{errors.war_state}</p>
                          ) : null} */}
                        </span>

                      </div> 

                     
                      <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Country 
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="war_country"
                            className="login-input mb-0 newdesigninput"
                            // placeholder={`Enter Your Country`}
                          readOnly
                            value={values.war_country}
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.war_country && errors.war_country
                                ? "red"
                                : touched.war_country && !errors.war_country
                                ? values.war_country == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            
                            onChange={(e) => {
                              setFieldValue("war_country", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {/* {errors.war_country && touched.war_country ? (
                            <p style={{ color: "red" }}>{errors.war_country}</p>
                          ) : null} */}
                        </span>

                      </div>
                       
                     

{/* <div className="col-xl-12 col-md-12">
  <h4 className="py-2"> 
   <input type="checkbox" name="" style={{padding:'25px'}} onChange={()=>{setalternative(!alternative)}} id="" />  ALTERNATIVE CONTACT PERSON DETAILS
  </h4>
</div> */}


     {/* {alternative == false ? "" : <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Alternative Contact Person Name <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="alt_name"
                            className="login-input mb-0"
                            placeholder={`Enter Alternative Contact Person Name`}
                            value={values.alt_name}
                          
                            onChange={(e) => {
                              setFieldValue("alt_name", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                      
                        </span>

                      </div>}
                      {alternative == false ? "" : <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Alternative Contact Person Designation <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="alt_des"
                            className="login-input mb-0"
                            placeholder={`Enter Alternative Contact Person Designation`}
         
                            value={values.alt_des}
                 
                            onChange={(e) => {
                              setFieldValue("alt_des", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                     
                        </span>

                      </div>} */}
                      {/* {alternative == false ? "" : <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Alternative Contact Person Email <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="alt_email"
                            className="login-input mb-0"
                            placeholder={`Enter Alternative Contact Person Email`}
                            value={values.alt_email}
                      
                            onChange={(e) => {
                              setFieldValue("alt_email", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                      
                        </span>

                      </div>} */}


                      {/* {alternative == false ? "" : <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Alternative Contact Person Mobile <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="alt_mobile"
                            className="login-input mb-0"
                            placeholder={`Enter Alternative Contact Person Mobile No`}
                          
                            value={values.alt_mobile}
                          
                            onChange={(e) => {
                              setFieldValue("alt_mobile", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                         
                        </span>

                      </div>} */}










                      {/* <div className="col-xl-12 col-md-12 d-flex justify-content-end py-4" style={{gap:'4px'}}>
                  {alternative == false ? unregisterdealer == false ? values.reg_address == '' || values.reg_postalcode == '' || values.reg_city == '' ? <button
                        type="button"
                        disabled
                        // onClick={handleSubmit}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Next
                        <span>
                          <FaArrowRightLong color="white" />
                        </span>
                      </button> : <button
                        type="button"
                        disabled={!(isValid && dirty)}
                        onClick={handleSubmit}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Next
                        <span>
                          <FaArrowRightLong color="white" />
                        </span>
                      </button> : <button
                        type="button"
                        disabled={!(isValid && dirty)}
                        onClick={handleSubmit}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Next
                        <span>
                          <FaArrowRightLong color="white" />
                        </span>
                      </button> : values.alt_name == '' || values.alt_des == '' || values.alt_email == '' || values.alt_mobile == '' ? <button
                        type="button"
                        disabled
                        onClick={handleSubmit}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Next
                        <span>
                          <FaArrowRightLong color="white" />
                        </span>
                      </button> : unregisterdealer == false ? values.reg_address == '' || values.reg_postalcode == '' || values.reg_city == '' ? <button
                        type="button"
                        disabled
                        // onClick={handleSubmit}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Next
                        <span>
                          <FaArrowRightLong color="white" />
                        </span>
                      </button> : <button
                        type="button"
                        disabled={!(isValid && dirty)}
                        onClick={handleSubmit}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Next
                        <span>
                          <FaArrowRightLong color="white" />
                        </span>
                      </button> : <button
                        type="button"
                        disabled={!(isValid && dirty)}
                        onClick={handleSubmit}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Next
                        <span>
                          <FaArrowRightLong color="white" />
                        </span>
                      </button>}
                      
                    </div>  */}
                   
                  </div>
               
          </div>
       
  );
};

export default Alternativeform;
