import * as Yup from "yup";

export const vrequest = Yup.object({
    categories: Yup.array()
    .label("Categories")
    .required("Enter at least one Category"),
    vouchertype: Yup.string()
    .label("Voucher Type")
    .required("Voucher Type is required"),
    Clubbable: Yup.string()
    .label("Clubbable")
    .required(),
    GST: Yup.string()
    .label("GST")
    .required(),
    Shipping: Yup.string()
    .label("Shipping")
    .required(),
    quantity: Yup.number()
    .label("Quantity")
    .required("Please Enter Quantity"),
    amount: Yup.number()
    .label("Amount")
    .required("Please Enter Amount"),
});
