import React, { useEffect, useRef, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { gettoken, removeToken } from "../../Localstorage/Store";
import axios from "axios";
import countryList from "country-list";
import AddressformComp from "./AddressformComp";
import EditressformComp from "./EditressformComp";
import { getCode, getName } from "country-list";
import { FaMoneyCheckAlt } from "react-icons/fa";

// import {getCountry} from 'countries';
export const Profile = () => {

  const dropdownRefs = useRef([]);
  const [owl, setowl] = useState("tab-1");
  const [addaddress, setaddaddress] = useState(false);
  const [editmode, seteditmode] = useState(false);
  console.log("check edit mode", getCode("United Kingdom"));
  // console.log("check edit mode fff",getCountry('United Kingdom'));
  const [filter, setfilter] = useState(true);
  const [loading, setloading] = useState(false);
  const currentwdith = window.innerWidth;
  const nvg = useNavigate();
  const location = useLocation();
  const tokenvalue = gettoken();
  const logoutfunction = () => {
    removeToken();
    // nvg('/')
    // window.location.reload();
    window.location.href = "/";
  };

  const [getsingleaddress, setgetsingleaddress] = useState({});
  const [createaddressstatus, setcreateaddressstatus] = useState(false);
  const [createaddresserror, setcreateaddresserror] = useState(false);
  const [createaddressmsg, setcreateaddressmsg] = useState("");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [mobileno, setmobileno] = useState("");
  const [dob, setdob] = useState("");
  const [gender, setgender] = useState("Male");
  console.log("gender fvalie is here", gender)
  const [delid, setdelid] = useState(0);
    const [visibleDropdown, setVisibleDropdown] = useState(null);
  
    const toggleDropdown = (id) => {
      setVisibleDropdown(visibleDropdown === id ? null : id);
    };
  const opencreateform = () => {
    seteditmode(false);
    setaddaddress(false);
    setTimeout(() => {
      setaddaddress(!addaddress);
    }, 500);
  };
  const closeform = () => {
    seteditmode(false);
    setaddaddress(false);
  };

  const editformopen = (item) => {
    setgetsingleaddress(item);
    seteditmode(false);
    setaddaddress(false);
    setTimeout(() => {
      seteditmode(true);
      setaddaddress(true);
    }, 500);
  };

  const createaddress = async (value, setFieldError) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };

      setloading(true);
      // let url = `${process.env.REACT_APP_API_URL}api/useraddresses/`;
      let url = `${process.env.REACT_APP_API_URL}homepageapi/address/create/`;
      const response = await axios.post(url, value, config);
      if (response.data?.id) {
        setcreateaddressstatus(true);
        setcreateaddressmsg("Successfully Created");

        setTimeout(() => {
          setcreateaddressstatus(false);
          setcreateaddressmsg("");
          closeform();
          getaddress();
          setowl("tab-3");
          // window.location.reload();
        }, 1500);
      } else {
        createaddresserror(true);
      }
    } catch (error) {
      console.log("firstnnnnnnnnn,,,", error.response);
      setFieldError("mobile", error.response.data.phone_number[0]);
    }
  };

  //  edit address api start here
  const delrecord = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
      },
    };
    // let url = `${process.env.REACT_APP_API_URL}api/useraddresses/`;
    let url = `${process.env.REACT_APP_API_URL}homepageapi/address/${delid}`;
    const response = await axios.delete(url, config);
    window.location.reload();
  };
  //  edit address api end here

  //  edit address api start here
  const editaddress = async (values, id, setFieldError) => {
    try {
      console.log("edir add ", values);
      const formdata = new FormData();
      formdata.append("first_name", values.first_name);
      formdata.append("last_name", values.last_name);
      formdata.append("phone_number", `+91${values.mobile}`);
      formdata.append("line1", values.address1);
      formdata.append("line2", values.address2);
      formdata.append("address_type", values.address_type);
      formdata.append(
        "country",
        values.country.length > 2 ? getCode(values.country) : values.country
      );
      formdata.append("state", values.state);
      formdata.append("email", values.email);
      formdata.append("line4", values.city);
      formdata.append("postcode", values.pincode);
      //  formdata.append("user", tokenvalue.id);
      if (tokenvalue.msg == "Voucher login") {
        formdata.append("user", tokenvalue.user_id);
      } else {
        formdata.append("user", tokenvalue.id);
      }
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };

      setloading(true);
      // let url = `${process.env.REACT_APP_API_URL}api/useraddresses/`;
      let url = `${process.env.REACT_APP_API_URL}homepageapi/address/${id}/update/`;
      const response = await axios.put(url, formdata, config);
      if (response.data?.id) {
        setcreateaddressstatus(true);
        setcreateaddressmsg("Successfully Update");

        setTimeout(() => {
          setcreateaddressstatus(false);
          setcreateaddressmsg("");
          //  window.location.reload();
          closeform();
          getaddress();
          setowl("tab-3");
        }, 1500);
      } else {
        createaddresserror(true);
      }
    } catch (error) {
      console.log("firstnnnnnnnnn,,,", error.response);
      setFieldError("mobile", error.response.data.phone_number[0]);
    }
  };
  //  edit address api end here

  const [errortrue, seterrortrue] = useState(false);
  const [fnameerror, setfnameerror] = useState("");
  const [lnameerror, setlnameerror] = useState("");
  const [emailerror, setemailerror] = useState("");
  const [mobileeror, setmobileeror] = useState("");
  //  edit user api start here
  const edituser = async (e) => {
    e.preventDefault();
    if (fname == "" || email == "" || lname == "" || mobileno == "") {
      seterrortrue(true);
      if (fname == "") {
        setfnameerror("Please Enter First Name");
      }
      if (lname == "") {
        setlnameerror("Please Enter Last Name");
      }
      if (email == "") {
        setemailerror("Please Enter Last Name");
      }
      if (mobileno == "") {
        setmobileeror("Please Enter Last Name");
      }
    } else {
      const formdata = {
        user: {
          email: email,
          first_name: fname,
          last_name: lname,
        },
        mobile_no: mobileno,
        gender: gender == '' || gender == " " || gender == null ? 'Male' : gender,
        date_of_birth: dob,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };

      setloading(true);
      console.log("dddkd", `ssssss${gender}`, formdata)
      // let url = `${process.env.REACT_APP_API_URL}api/useraddresses/`;
      let url = `${process.env.REACT_APP_API_URL}useraccount/clientinfo/`;
      const response = await axios.put(url, formdata, config);
      window.location.reload();
    }
  };
  //  edit user api end here

  // get single address api start here
  // async function getsingleaddress(item) {
  //     console.log("first mmmmmm",item);
  //     setsingleaddress(item);
  //     seteditmode(true);
  //     console.log("first mmmmmm",singleaddress);
  //     setaddaddress(true)

  // }
  // get single address api end here

  const [addressdataindex, setaddressdataindex] = useState(0);
  const [countriesdata, setcountriesdata] = useState([]);
  const [addressdata, setaddressdata] = useState([]);
  const [voucherdata, setvoucherdata] = useState([]);
  const [walletdata, setwalletdata] = useState([]);

  const [userdata, setuserdata] = useState({});

  async function getaddress() {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
        "Content-Type": "application/json",
      },
    };
    let url = `${process.env.REACT_APP_API_URL}homepageapi/address/list`;
    try {
      const response = await axios.get(url, config);
      setaddressdata(response.data);
      if (response.data) {
        setaddressdataindex(response.data[0]);


        const updatedAttributes2 = {};
        response.data.forEach((item, index) => {
              updatedAttributes2[item.id] = false;
          }
        );
        console.log("ddddccvvvvvbbnnnnnnn",updatedAttributes2)
        // setaddressdataindex(response.data[response.data.length - 1])
      }
    } catch (error) {
      // setloading(false)
      // console.log(error)
    }
  }



  const handleClickOutside = (event) => {
    if (
      dropdownRefs.current.every(
        (ref) => ref && !ref.contains(event.target)
      )
    ) {
      setVisibleDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (location.state.id == 1) {
      setowl("tab-1");
    } else {
      if (location.state.id == 2) {
        setowl("tab-2");
      } else {
        if (location.state.id == 3) {
          setowl("tab-3");
        } else {
          setowl("tab-4");
        }
      }
    }

    if (currentwdith < 730) {
      setfilter(false);
    } else {
      setfilter(true);
    }

    async function getcountries() {
      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${tokenvalue.access}`,
      //     'Content-Type': 'application/json',
      //   },
      // };
      let url = `${process.env.REACT_APP_API_URL}api/countries/`;
      try {
        const response = await axios.get(url);
        setcountriesdata(response.data);
        console.log("response of reso", response);
        console.log("response of countries response", countriesdata);
      } catch (error) {
        // setloading(false)
        // console.log(error)
      }
    }


    async function wallethistory() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
          "Content-Type": "application/json",
        },
      };
      // let url = `${process.env.REACT_APP_API_URL}homepageapi/GetVouchersView`;
      let url = `${process.env.REACT_APP_API_URL}wallet/client-wallet-history/`;
      try {
        const response = await axios.get(url, config);
        console.log("wallet data is here ", response);
        setwalletdata(response.data);
      } catch (error) {
        // setloading(false)
        // console.log(error)
      }
    }



    async function voucherlist() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
          "Content-Type": "application/json",
        },
      };
      // let url = `${process.env.REACT_APP_API_URL}homepageapi/GetVouchersView`;
      let url = `${process.env.REACT_APP_API_URL}my_api/get-voucher/`;
      try {
        const response = await axios.get(url, config);
        console.log("voucher dat a is here ", response);
        setvoucherdata(response.data);
      } catch (error) {
        // setloading(false)
        // console.log(error)
      }
    }
    async function userinfo() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${process.env.REACT_APP_API_URL}useraccount/clientinfo/`;
      try {
        const response = await axios.get(url, config);
        console.log("this is ere",response.data)
        if (response.data.UserProfile) {

          const parts = response.data.UserProfile.date_of_birth.split('-');

          // Create a new string with the parts rearranged into the desired format
          const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

          console.log("log2", response, formattedDate);

          // setuserdata(response.data.UserProfile);
          setuserdata({ ...response.data.UserProfile, formattedDate });
          setfname(response.data.UserProfile.first_name);
          setlname(response.data.UserProfile.last_name);
          const localNumber = response.data.UserProfile.mobile_number.startsWith("+91") ? response.data.UserProfile.mobile_number.slice(3) : response.data.UserProfile.mobile_number;
          setmobileno(localNumber);
          setemail(response.data.UserProfile.email);
          setgender(response.data.UserProfile.gender);
          setdob(response.data.UserProfile.date_of_birth);
        } else {
          console.log("log2 agin", response);
          let parts = '';
          let formattedDate = '';
if(response.data.ClientDetails.date_of_birth){
   parts = response.data.ClientDetails.date_of_birth.split('-');
   formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
}
          
          // Create a new string with the parts rearranged into the desired format
          
          // console.log("log 1",response.data.ClientDetails)
          console.log("log2", response, formattedDate);
          if(formattedDate != ''){
            setuserdata({ ...response.data.ClientDetails, formattedDate });
          }else{
            setuserdata({ ...response.data.ClientDetails });
          }
          setfname(response.data.ClientDetails.user.first_name);
          setlname(response.data.ClientDetails.user.last_name);
          const localNumber = response.data.ClientDetails.mobile_no.startsWith("+91") ? response.data.ClientDetails.mobile_no.slice(3) : response.data.ClientDetails.mobile_no;
          // setmobileno(response.data.ClientDetails.mobile_no);
          setmobileno(localNumber);
          setemail(response.data.ClientDetails.user.email);
          setgender(response.data.ClientDetails.gender);
          setdob(response.data?.ClientDetails?.date_of_birth);
        }
      } catch (error) {
        // setloading(false)
        // console.log(error)
      }
    }
    getcountries();
    getaddress();
    userinfo();
    voucherlist();
    wallethistory();
  }, []);
  console.log("dkddkd", userdata)
  return (
    <div>
      <Header />

      <div>
        {/* breadcrumb start */}
        <div
          className="breadcrumb-main marginfromtop"
          //  style={{backgroundColor:"#ececec"}}
          style={{ background: "#fff" }}
        >
          <div className="container m-0">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-contain">
                  <div>
                    <ul>
                      <li>
                        <a href="/">home</a>
                      </li>
                      <li>
                        {`>`}
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          {owl == "tab-1"
                            ? "Profile"
                            : owl == "tab-2"
                              ? "Order History"
                              : owl == "tab-3"
                                ? "Addresses List"
                                : owl == "tab-4"
                                  ? "Voucher History"
                                  : owl == "tab-5"
                                    ? "Edit Profile"
                                    : owl == "tab-8"
                                      ? "Wallet " : ""}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* breadcrumb End */}
        <section
          className="section-big-pt-space pb-2"
          //  style={{backgroundColor:"#ececec"}}
          style={{ backgroundColor: "rgb(249 249 249)" }}
        >
          <div className="col-lg-12 col-sm-12 col-xs-12 mt-lg-3 mt-xs-4 mb-5">
            {/* <div className='container-fluid bigdiv'> */}
            <div className="container-fuild emp-profile">
              <div
                className="row profile"
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <section className="tab-product-main-tab">
                  <div className="tab2-product d-flex justify-content-center main-tab2 newscroll">
                    <ul className="abc justify-content-center">
                      <li className={owl == "tab-1" ? "current" : ""}>
                        <a
                          href="javascript:void(0)"
                          className="size21 extradesign"
                          onClick={() => setowl("tab-1")}
                        >
                          <img
                            src="./images/icon/11.png"
                            className="sizeimg1"
                            alt="404"
                          />{" "}
                          &nbsp; Profile
                        </a>
                      </li>
                      {/* <li className={owl == "tab-2" ? "current" : ""}>
                        <a
                          href="javascript:void(0)"
                          className="size2"
                          onClick={() => setowl("tab-2")}
                        >
                       <img src="./images/icon/12.png" alt="404" /> &nbsp;
                          Orders History
                        </a>
                      </li> */}
                      <li className={owl == "tab-3" ? "current" : ""}>
                        <a
                          href="javascript:void(0)"
                          className="size22"
                          onClick={() => setowl("tab-3")}
                        >
                          {" "}
                          <img
                            src="./images/icon/14.png"
                            className="sizeimg2"
                            alt="404"
                          />{" "}
                          &nbsp; Addresses {currentwdith > 400 ? "List" : ""}
                        </a>
                      </li>
                      <li className={owl == "tab-4" ? "current" : ""} style={{display:tokenvalue.msg == "Voucher login" ? "inline-block" : "none"}}>
                        <a
                          href="javascript:void(0)"
                          className="size23"
                          onClick={() => setowl("tab-4")}
                        >
                          <img
                            src="./images/icon/13.png"
                            className="sizeimg3"
                            alt="404"
                          />{" "}
                          &nbsp; Voucher {currentwdith > 400 ? "History" : ""}
                        </a>
                      </li>

                       {/* <li className={owl == "tab-8" ? "current" : ""} style={{display:tokenvalue.msg == "Voucher login" ? "none":"inline-block"}}>
                        <a
                          href="javascript:void(0)"
                          className="size23"
                          onClick={() => setowl("tab-8")}
                        >

                          <img
                            className="size230"

                            src="./images/icon/walletsmall.png"
                            alt="404"
                          />{" "}
                          &nbsp; Wallet {currentwdith > 400 ? "" : ""}
                        </a>
                      </li> */}



                      {/* <li className={owl == 'tab-5' ? 'current' : ''} ><a href='javascript:void(0)' onClick={() => setowl('tab-5')}>medicine</a></li> */}
                    </ul>
                  </div>
                </section>

                <section className="tab-product-main-tab">
                  <div className="row mt-5">
                    {/* profile page start here  */}
                    <div
                      id="tab-1"
                      style={{ display: owl == "tab-1" ? "block" : "none" }}
                      className={
                        owl == "tab-1"
                          ? "tab-content active default product-block3"
                          : "tab-content product-block3"
                      }
                    >
                      <div className="row d-flex justify-content-center">
                        {/* <div className="col-md-4 d-flex  align-items-center" style={{flexDirection:'column'}}>
                      <div style={{width:'80%',display:'flex',justifyContent:'center'}}>
                      <img
                        src="./images/avtar/1.jpg"
                        alt="product"
                        className="UserImg  "
                        // style={{ justifyContent: "center" }}
                      />
                      </div>
                      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <h4 className="">Hello,Muskan Khan</h4>
                      </div>
                      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                      <p className="">name@example.com</p>
                      </div>
                      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                      <p className="">United Kingdom</p>
                      </div>
                    </div> */}
                        <div className="col-md-10">
                          <div className="row">
                            <div
                              className="col-12 d-flex justify-content-between"
                              style={{ marginBottom: "17px" }}
                            >
                              <h5 className="mb-3 acounttitle customwidth1" >
                                Personal-Information
                              </h5>
                              <div className="customwidth2" >
                                <input
                                  style={{
                                    outline: "none",
                                    padding: "5px 9px",
                                    fontSize: "11px",
                                  }}
                                  type="Submit"
                                  onClick={() => setowl("tab-5")}
                                  className="profile-edit-btn"
                                  name="btnAddMore"
                                  value="Edit Profile"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 ">
                              <div className="profile-head">
                                <div className="form-group d-flex justify-content-between">
                                  <label
                                    className="profilelabel customwidth1"
                                    style={{ fontWeight: "500" }}
                                    htmlFor="name"
                                  >
                                    First Name
                                  </label>

                                  <p
                                    className="profilelabel customwidth2"
                                    style={{ color: "#abb1b7", textWrap: 'nowrap' }}
                                  >
                                  {userdata?.user?.first_name
                                      ? userdata?.user?.first_name
                                      : userdata?.first_name}
                                  </p>
                                </div>
                                <div className="form-group d-flex justify-content-between">
                                  <label
                                    className="profilelabel customwidth1"
                                    style={{ fontWeight: "500" }}
                                    htmlFor="name"
                                  >
                                    Last Name
                                  </label>
                                  {/* <input
                          style={{outline:'none'}}
                            type="text"
                            readOnly
                            className="form-control"
                            id="name"
                            placeholder="Khan"
                            required
                          /> */}
                                  <p
                                    className="profilelabel customwidth2"
                                    style={{ color: "#abb1b7" }}
                                  >
                                    {userdata?.user?.last_name
                                      ? userdata?.user?.last_name
                                      : userdata?.last_name}
                                  </p>
                                </div>
                                <div className="form-group d-flex justify-content-between">
                                  <label
                                    className="profilelabel customwidth1"
                                    style={{ fontWeight: "500" }}
                                    htmlFor="review"
                                  >
                                    Mobile Number
                                  </label>
                                  {/* <input
                          style={{outline:'none'}}
                            type="text"
                            readOnly
                            className="form-control"
                            id="review"
                            placeholder="+99-85XXXXXXXX"
                            required
                          /> */}

                                  <p
                                    className="profilelabel customwidth2"
                                    style={{ color: "#abb1b7" }}
                                  >
                                    {userdata?.mobile_no
                                      ? userdata?.mobile_no
                                      : userdata?.mobile_number}
                                  </p>
                                </div>

                                <div className="form-group d-flex justify-content-between">
                                  <label
                                    className="profilelabel customwidth1"
                                    style={{ fontWeight: "500" }}
                                    htmlFor="dob"
                                  >
                                    Date of Birth
                                  </label>
                                  {/* <input
                          style={{outline:'none'}}
                            type="number"
                            readOnly
                            className="form-control"
                            id="review"
                            placeholder="+99-85XXXXXXXX"
                            required
                          /> */}
                                  <p
                                    className="profilelabel customwidth2"
                                    style={{ color: "#abb1b7" }}
                                  >
                                    {userdata?.formattedDate}
                                  </p>
                                </div>
                                {/* <div className="herobtn" style={{marginBottom:'20px',textAlign:"left"}}>
                          <input
                          style={{outline:'none',padding:"5px 9px"}}
                            type="button"
                            onClick={logoutfunction}
                            className="profile-edit-btn"
                            name="btnAddMore"
                            value="Logout"
                          />
                  
                        </div> */}
                              </div>
                            </div>
                            <div className="col-lg-12 ">
                              <div className="profile-head">
                                <div className="form-group d-flex justify-content-between">
                                  <label
                                    className="profilelabel customwidth1"
                                    style={{ fontWeight: "500" }}
                                    htmlFor="review"
                                  >
                                    Email ID
                                  </label>
                                  {/* <input
                          style={{outline:'none'}}
                            type="Email"
                            readOnly
                            className="form-control"
                            id="review"
                            placeholder="name@example.com"
                            required
                          /> */}
                                  <p
                                    className="profilelabel customwidth2"
                                    style={{ color: "#abb1b7" }}
                                  >
                                    {userdata?.user?.email
                                      ? userdata?.user?.email
                                      : userdata?.email}
                                  </p>
                                </div>

                                <div className="form-group d-flex justify-content-between">
                                  <label
                                    className="profilelabel customwidth1"
                                    style={{ fontWeight: "500" }}
                                    htmlFor="dob"
                                  >
                                    Gender
                                  </label>
                                  {/* <input
                          style={{outline:'none'}}
                            type="text"
                            readOnly
                            className="form-control"
                            id="review"
                            placeholder="Female"
                            required
                          /> */}
                                  <p
                                    className="profilelabel customwidth2"
                                    style={{ color: "#abb1b7" }}
                                  >
                                    {userdata?.gender}
                                  </p>
                                </div>
                                {tokenvalue.msg == "Voucher login" ? '' : <div className="form-group d-flex justify-content-between">
                                  <label
                                    className="profilelabel customwidth1"
                                    style={{ fontWeight: "500" }}
                                    htmlFor="dob"
                                  >
                                    Password
                                  </label>
                                
                                  <p
                                    className="profilelabel customwidth2"
                                    style={{ color: "#abb1b7" }}
                                  >
                                    ***********
                                  </p>
                                </div>}
                                <div
                                  className="herobtn"
                                  style={{ marginBottom: "20px" }}
                                >
                                  {/* <input
                          style={{outline:'none',padding:"5px 9px"}}
                            type="Submit"
                            className="profile-edit-btn"
                            name="btnAddMore"
                            value="Edit Profile"
                          /> */}
                                  {/* <input
                          style={{outline:'none',padding:"5px 9px"}}
                            type="Submit"
                            className="profile-edit-btn"
                            name="btnAddMore"
                            value="Edit Profile"
                          /> */}
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-lg-12 "
                              style={{ position: "relative", top: "-32px" }}
                            >
                              <div className="profile-head">
                                <div
                                  className="herobtn"
                                  style={{
                                    marginBottom: "20px",
                                    textAlign: "left",
                                  }}
                                >
                                  <input
                                    style={{
                                      outline: "none",
                                      padding: "5px 9px",
                                      fontSize: "12px",
                                    }}
                                    type="button"
                                    onClick={logoutfunction}
                                    className="profile-edit-btn"
                                    name="btnAddMore"
                                    value="Logout"
                                  />
                                  {/* <input
                          style={{outline:'none',padding:"5px 9px"}}
                            type="Submit"
                            className="profile-edit-btn"
                            name="btnAddMore"
                            value="Edit Profile"
                          /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="tab-5"
                      style={{ display: owl == "tab-5" ? "block" : "none" }}
                      className={
                        owl == "tab-5"
                          ? "tab-content active default product-block3"
                          : "tab-content product-block3"
                      }
                    >
                      <div className="row d-flex justify-content-center">
                        {/* <div className="col-md-4 d-flex  align-items-center" style={{flexDirection:'column'}}>
                      <div style={{width:'80%',display:'flex',justifyContent:'center'}}>
                      <img
                        src="./images/avtar/1.jpg"
                        alt="product"
                        className="UserImg  "
                        // style={{ justifyContent: "center" }}
                      />
                      </div>
                      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <h4 className="">Hello,Muskan Khan</h4>
                      </div>
                      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                      <p className="">name@example.com</p>
                      </div>
                      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                      <p className="">United Kingdom</p>
                      </div>
                    </div> */}
                        <div className="col-md-10">
                          <form onSubmit={edituser} >
                            <div className="row">
                              <div className="col-12 ">
                                <h5 className="mb-3">Personal-Information</h5>
                                {/* <input
                          style={{outline:'none',padding:"5px 9px"}}
                            type="Submit"
                            className="profile-edit-btn"
                            name="btnAddMore"
                            value="Edit Profile"
                          /> */}
                              </div>
                              <div className="col-lg-6 ">
                                <div className="profile-head">
                                  <div className="form-group ">
                                    <label
                                      style={{
                                        fontWeight: "500",
                                        margin: "0px",
                                        fontSize: "12px",
                                      }}
                                      htmlFor="name"
                                    >
                                      First Name
                                    </label>
                                    <input
                                      style={{
                                        outline: "none",
                                        fontSize: "12px",
                                      }}
                                      type="text"
                                      className="form-control"
                                      id="name"
                                      value={fname}
                                      required
                                      placeholder="Enter Your First Name"
                                      onChange={(e) => {
                                        setfname(e.target.value);
                                      }}
                                    />
                                    <div className="error">
                                      {errortrue ? (
                                        <p style={{ color: "red" }}>
                                          {fnameerror}
                                        </p>
                                      ) : null}
                                    </div>
                                    {/* <p style={{color:'#abb1b7'}}>Ajay</p> */}
                                  </div>
                                  <div className="form-group ">
                                    <label
                                      style={{
                                        fontWeight: "500",
                                        margin: "0px",
                                        fontSize: "12px",
                                      }}
                                      htmlFor="review"
                                    >
                                      Mobile Number
                                    </label>
                                    <input
                                      style={{
                                        outline: "none",
                                        fontSize: "12px",
                                      }}
                                      type="text"
                                      placeholder="Enter Your Mobile No"
                                      className="form-control"
                                      id="review"
                                      minLength={10}
                                      maxLength={10}
                                      value={mobileno}
                                      onChange={(e) => {
                                        setmobileno(e.target.value.replace(/\D/g, ""));
                                      }}
                                      required
                                    />
                                    <div className="error">
                                      {errortrue ? (
                                        <p style={{ color: "red" }}>
                                          {mobileeror}
                                        </p>
                                      ) : null}
                                    </div>
                                    {/* <p style={{color:'#abb1b7'}}>+99-8178609471</p> */}
                                  </div>

                                  <div className="form-group ">
                                    <label
                                      style={{
                                        fontWeight: "500",
                                        margin: "0px",
                                        fontSize: "12px",
                                      }}
                                      htmlFor="dob"
                                    >
                                      Date of Birth
                                    </label>
                                    <input
                                      style={{
                                        outline: "none",
                                        fontSize: "12px",
                                      }}
                                      type="date"
                                      // readOnly
                                      className="form-control"
                                      id="review"
                                      value={dob}
                                      required
                                      onChange={(e) => {
                                        setdob(e.target.value);
                                      }}
                                    />

                                    {/* <p style={{color:'#abb1b7'}}>23, july 2023</p> */}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 ">
                                <div className="profile-head">
                                  <div className="form-group ">
                                    <label
                                      style={{
                                        fontWeight: "500",
                                        margin: "0px",
                                        fontSize: "12px",
                                      }}
                                      htmlFor="name"
                                    >
                                      Last Name
                                    </label>
                                    <input
                                      style={{
                                        outline: "none",
                                        fontSize: "12px",
                                      }}
                                      type="text"
                                      placeholder="Enter Your Last Name"
                                      className="form-control"
                                      id="name"
                                      value={lname}
                                      onChange={(e) => {
                                        setlname(e.target.value);
                                      }}
                                      required
                                    />
                                    <div className="error">
                                      {errortrue ? (
                                        <p style={{ color: "red" }}>
                                          {lnameerror}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="form-group ">
                                    <label
                                      style={{
                                        fontWeight: "500",
                                        margin: "0px",
                                        fontSize: "12px",
                                      }}
                                      htmlFor="review"
                                    >
                                      Email ID
                                    </label>
                                    <input
                                      style={{
                                        outline: "none",
                                        fontSize: "12px",
                                      }}
                                      type="Email"
                                      // readOnly
                                      placeholder="Enter Your Email ID"
                                      className="form-control"
                                      id="review"
                                      required
                                      value={email}
                                      onChange={(e) => {
                                        setemail(e.target.value);
                                      }}
                                    />
                                    <div className="error">
                                      {errortrue ? (
                                        <p style={{ color: "red" }}>
                                          {emailerror}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="form-group ">
                                    <label
                                      style={{
                                        fontWeight: "500",
                                        margin: "0px",
                                        fontSize: "12px",
                                      }}
                                      htmlFor="dob"
                                    >
                                      Gender
                                    </label>
                                    <select
                                      class="form-select"
                                      value={gender}
                                      onChange={(e) => {
                                        setgender(e.target.value);
                                      }}
                                      style={{ fontSize: "12px" }}
                                      aria-label="Default select example"
                                    >
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                    </select>
                                    {/* <p style={{color:'#abb1b7'}}>Male</p> */}
                                  </div>
                                
                                </div>
                              </div>
                              <div className="col-12">
                              <div
                                    className="herobtn"
                                    style={{ marginBottom: "20px",display:'flex',justifyContent:tokenvalue.msg == "Voucher login" ? 'end' : 'space-between',width:'100%'}}
                                  >
                                   {tokenvalue.msg == "Voucher login" ? '' : <button type="button"
                                     style={{
                                      outline: "none",
                                      padding: "5px 9px",
                                      fontSize: "12px",
                                      margin:"0px auto 0px 0px"
                                    }}
                                    onClick={()=>{nvg('/changeyourpassword')}}
                                    className="profile-edit-btn">CHANGE PASSWORD</button>}
                                    <div>

                                    <input
                                      style={{
                                        outline: "none",
                                        marginRight: "4px",
                                        padding: "5px 9px",
                                        background: "#c1bdbd",
                                        fontSize: "12px",
                                      }}
                                      type="button"
                                      onClick={() => {
                                        setowl("tab-1");
                                      }}
                                      className="profile-edit-btn"
                                      name="btnAddMore"
                                      value="Cancel"
                                    />
                                    <input
                                      style={{
                                        outline: "none",
                                        padding: "5px 9px",
                                        fontSize: "12px",
                                      }}
                                      type="submit"
                                      // onClick={() => {edituser()}}
                                      className="profile-edit-btn"
                                      name="btnAddMore"
                                      value="Save"
                                    />
                                    </div>

                                  </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* profile page start end  */}

                    <div
                      id="tab-3"
                      style={{ display: owl == "tab-3" ? "block" : "none" }}
                      className={
                        owl == "tab-3"
                          ? "tab-content active default product-block3"
                          : "tab-content product-block3"
                      }
                    >
                      {createaddressstatus == true ? (
                        <div className="col-12 px-4 d-flex">
                          {" "}
                          <div
                            className="col-12 col-offset-2 alert alert-success mt-2 ms-1"
                            role="alert"
                          >
                            <h5
                              style={{
                                padding: "0px",
                                margin: "0px",
                                color: "#0a3622",
                              }}
                            >
                              {createaddressmsg}
                            </h5>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        {" "}
                        <h4
                          style={{ color: "#2B2A29", font: "Inter" }}
                          className="Manageadd acounttitle"
                        >
                          Manage Addresses
                        </h4>
                        <h4
                          className="acounttitle"
                          style={{ color: "#230BB3", cursor: "pointer" }}
                          onClick={() => {
                            opencreateform();
                          }}
                        >
                          + Add Address
                        </h4>
                      </div>
                      <div className="row">
                        {editmode == false ? (
                          <AddressformComp
                            addaddress={addaddress}
                            createfun={createaddress}
                            closefun={closeform}
                            item={{}}
                            editmode={editmode}
                          />
                        ) : (
                          <EditressformComp
                            addaddress={addaddress}
                            item={getsingleaddress}
                            editfun={editaddress}
                            closefun={closeform}
                            editmode={editmode}
                          />
                        )}

                        <div className="">
                          <div class="row details py-2 justify-content-start">
                           
                            {addressdata.map((item, index) =>
                                <div
                                  class="col-lg-6"
                                  style={{ marginBottom: "9px" }}
                                >
                                  <div class="card">
                                    <div class="card-body">
                                      <h5
                                        class="card-title acounttitle"
                                        style={{
                                          textTransform: "capitalize",
                                          paddingLeft: "9px",
                                        }}
                                      >
                                        {item.first_name}&nbsp;
                                        {item.last_name} {index == 0 ? "(Default)" : ""} <span style={{fontSize:'11px'}}>{`(${item.address_type})`}</span>
                                        {/* <span style={{fontSize:'11px'}}>{`(${item.address_type})`}</span> */}
                                        <span className="hello">
                                          {/* <i class="fa-solid fa-ellipsis-vertical"></i> */}
                                          <div class="dropdown">
                                           
                                              <i class="fa-solid fa-ellipsis-vertical" onClick={()=>{toggleDropdown(item.id)}}></i>
                                            
                                            <ul class="dropdown-menu dropdownMenu lateststyle " ref={(el) => (dropdownRefs.current[index] = el)} style={{display:visibleDropdown == item.id ? "block" : "none"}}>
                                              <li>
                                                <button
                                                  type="button"
                                                  style={{ fontSize: "12px" }}
                                                  onClick={() => {
                                                    editformopen(item);
                                                    setVisibleDropdown(null);
                                                  }}
                                                  class="dropdown-item"
                                                >
                                                  <i class="fa fa-pen-to-square "></i>
                                                  &nbsp;Edit
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  type="button"
                                                  style={{ fontSize: "12px" }}
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#exampleModal1400000"
                                                  class="dropdown-item"
                                                  onClick={() => {
                                                    setdelid(item.id);
                                                    setVisibleDropdown(null);
                                                  }}
                                                >
                                                  <i class="fa fa-trash-can "></i>
                                                  &nbsp;Delete
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </span>
                                      </h5>

                                      <div
                                        class="form-check"
                                        style={{ paddingLeft: "9px" }}
                                      >
                                        {/* <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                            
                            />  */}

                                        <label
                                          className="form-check-label mb-0"
                                          for="flexRadioDefault1"
                                        >
                                          <h5
                                            className=" acounttitle"
                                            style={{ paddingBottom: "4px" }}
                                          >
                                            Mobile:{" "}
                                            <span className="number2">
                                              {item.phone_number}
                                            </span>
                                          </h5>
                                          <h5 className=" acounttitle">
                                            Email ID:{" "}
                                            <span className="number2">
                                              {item.email}
                                            </span>
                                          </h5>
                                        </label>
                                      </div>
                                      <p
                                        className="small-text"
                                        style={{ paddingLeft: "9px" }}
                                      >
                                        {item.line1}
                                        &nbsp;
                                        {item.line2}
                                        &nbsp;
                                        {item.country_printable_name}
                                        {/* {countryList.getName(item.country)} */}
                                        &nbsp;
                                        {item.state}
                                        &nbsp;
                                        {item.line4}-{item.postcode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* walltet */}
                    <div
                      id="tab-8"
                      style={{ display: owl == "tab-8" ? "block" : "none" }}
                      className={
                        owl == "tab-8"
                          ? "tab-content active default product-block3"
                          : "tab-content product-block3"
                      }
                    >
                      <div className="row d-flex justify-content-center">

                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-12 "
                              style={{
                                fontWeight: 600,
                                fontSize: "12px",

                              }}>
                              {
                                walletdata &&
                                <p className="mb-3">Wallet Total Amount: ₹{walletdata.amount}</p>

                              }

                              {walletdata ? (
                                <div>
                                  <p>Wallet History</p>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">S.NO</th>
                                        <th scope="col">Data</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Transaction</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {walletdata.history && walletdata.history.length > 0 ? (
                                        walletdata.history.slice().reverse().map((entry, index) => (
                                          <tr key={entry.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{new Date(entry.wallet_used_time).toLocaleString()}</td>
                                            <td style={{ color: entry.wallet_used_amount < 0 ? 'red' : 'green' }}>
                                              {entry.wallet_used_amount}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan="3">No wallet information available</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <p>No wallet information available</p>
                              )}


                            </div>




                          </div>
                        </div>


                      </div>
                    </div>



                    {/* walltet */}
                    <div
                      id="tab-4"
                      style={{ display: owl == "tab-4" ? "block" : "none" }}
                      className={
                        owl == "tab-4"
                          ? "tab-content active default product-block3"
                          : "tab-content product-block3"
                      }
                    >
                      <div className="row">
                        {/* <div className='row Voucher'> */}
                        <div class="table-responsive">
                          <table class="table">
                            <thead className="firstrow">
                              <tr>
                                <th scope="col">
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "12px",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "1",
                                      textWrap: "nowrap",
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {" "}
                                    Voucher No.
                                  </div>
                                </th>
                                {/* <th scope="col" style={{ fontWeight: 600, fontSize: "12px" }}>Customer</th> */}
                                {/* <th scope="col">
                                  {" "}
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "12px",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "1",
                                      textWrap: "nowrap",
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                    }}
                                  >
                                    Voucher Gst
                                  </div>{" "}
                                </th> */}
                                <th scope="col">
                                  {" "}
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "12px",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "1",
                                      textWrap: "nowrap",
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                    }}
                                  >
                                    Value
                                  </div>
                                </th>
                                {/* <th scope="col" style={{ fontWeight: 600, fontSize: "12px" }}>Created Date</th>
        <th scope="col" style={{ fontWeight: 600, fontSize: "12px" }}>Expired Date</th> */}
                                <th scope="col">
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "12px",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "1",
                                      textWrap: "nowrap",
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                    }}
                                  >
                                    Voucher Type
                                  </div>
                                </th>
                                <th scope="col">
                                  {" "}
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "12px",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "1",
                                      textWrap: "nowrap",
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                    }}
                                  >
                                    Applied Date
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {voucherdata[0] ? (
                                voucherdata.map((item) => (
                                  <tr className="secondrow">
                                    <th
                                      style={{
                                        color: "black",
                                        fontSize: "12px",
                                      }}
                                      scope="row"
                                    >
                                      {item.code}
                                    </th>
                                    {/* <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >Ajay Singh</span></td> */}

                                    {/* <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >
                              {item.start_datetime.split('T')[0]}
                            </span></td> */}
                                    {/* <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >
                              {item.end_datetime.split('T')[0]}
                              </span></td> */}
                                    {/* <td>
                                      {" "}
                                      <span
                                        style={{
                                          color: "black",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item.gst_rate}%
                                        <span style={{ fontSize: "10px" }}>
                                          (
                                          {item.voucher_type ==
                                            "shopping voucher"
                                            ? "excl"
                                            : "incl"}
                                          )
                                        </span>
                                      </span>
                                    </td> */}
                                    <td>
                                      {" "}
                                      <span
                                        style={{
                                          color: "black",
                                          fontSize: "12px",
                                        }}
                                      >
                                        ₹{item.offer}
                                      </span>
                                    </td>
                                    <td>
                                      {" "}
                                      <span
                                        style={{
                                          color: "black",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item.voucher_type}
                                      </span>{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <span
                                        style={{
                                          color: "black",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item.order_date.split("T")[0]}
                                      </span>{" "}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan={6}
                                    className="text-center"
                                    style={{ fontWeight: "500" }}
                                  >
                                    No Voucher Found !
                                  </td>
                                </tr>
                              )}
                              {/* <tr className='secondrow'>
        <th style={{
                                color: "black",
                                fontSize: "12px",
                                }} scope="row">#810</th>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >Ajay Singh</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >100.00</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >15 November 2023</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >30 November 2023</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >CGST</span></td>
        <td><img src="./images/icon/Frame 43.png" alt="404" /> </td>
      </tr>
      <tr className='secondrow'>
        <th style={{
                                color: "black",
                                fontSize: "12px",
                                }} scope="row">#820</th>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >Ajay Singh</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >100.00</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >15 November 2023</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >30 November 2023</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >CGST</span></td>
        <td><img src="./images/icon/Frame 42.png" alt="404" /> </td>
      </tr>
      <tr className='secondrow'>
        <th style={{
                                color: "black",
                                fontSize: "12px",
                                }} scope="row">#830</th>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >Ajay Singh</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >100.00</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >15 November 2023</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >30 November 2023</span></td>
        <td> <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                              }}
                            >CGST</span></td>
        <td><img src="./images/icon/Frame 43.png" alt="404" /></td>
      </tr> */}
                            </tbody>
                          </table>
                        </div>

                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal fade"
          id="exampleModal1400000"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
              {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
              <div className="modal-header mod-line d-none"></div>
              <div className="modal-body">
                <div className="row gy-3 mt-2">
                  {/* <div className="d-flex justify-content-center">
          <BsQuestionLg className='question-logo' />
            </div> */}
                  <h4
                    style={{
                      color: "rgb(35, 11, 179)",
                      cursor: "pointer",
                      textAlign: "center",
                      fontSize: "21px",
                      fontWeight: "800",
                    }}
                  >
                    Address Warning
                  </h4>
                  <p
                    className="ccedit-p "
                    style={{ textAlign: "center", fontSize: "12px" }}
                  >
                    Do You Really Want to Delete this Record ?
                  </p>
                </div>
              </div>
              <div
                className="modal-footer mod-line m-auto"
                style={{ border: "none" }}
              >
                {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
                <button
                  type="button"
                  className="btn closebtn "
                  style={{
                    paddingRight: "20px",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#230BB3",
                  }}
                  data-bs-dismiss="modal"
                // aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn closebtn profile-edit-btn justhoverwh"
                  data-bs-dismiss="modal"
                  // aria-label="Close"
                  onClick={() => {
                    delrecord();
                  }}
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}

        {/* <Footer /> */}

        {/* footer start */}
      </div>
    </div>
  );
};
export default Profile;