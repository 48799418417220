import * as Yup from "yup";

export const Register1val = Yup.object({
  companytype: Yup.string()
    .label("Company Type")
    .required("Company Type is required"),
    // companytype2: Yup.string()
    // .label("Company Type")
    // .required("Company Type is required"),
    industrytype: Yup.string()
    .label("Intustry Type")
    .required("Intustry Type is required"),
    weblink: Yup.string(),
  contactperson: Yup.string()
    .label("Primary Contact Person")
    .min(2)
    .required("Primary Contact Person is required"),
    companyname: Yup.string()
    .label("Company Name")
    .required("Name of Company is required"),
    designation: Yup.string()
    .label("Designation")
    .required("Designation is required"),
    email: Yup.string()
    .label("Email Id")
    .email()
    .required("Email Id is required"),
    mobile: Yup.number()
    .label("Mobile No")
    .min(1000000000, "Mobile number must be at least 10 digits")
    .max(9999999999, "Mobile number cannot be more than 10 digits")
    .required("Please Enter Your Mobile Number"),
      mobileotp: Yup.string()
    .required("Please Enter Your Mobile OTP"),
      emailotp: Yup.string()
    .required("Please Enter Your Email OTP"),
    terms: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions")
    // .required("You must accept the terms and conditions")
});
