import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { Register2val } from "../Validation/register2val";
import axios from "axios";
import { Register4val } from "../Validation/register4val";
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';

const Register4 = ({thirdform,step1id,changestep,buttonRefthree,step3id}) => {
  const [verifystatus_gst, setverifystatus_gst] = useState(0);
  const [checkbox, setcheckbox] = useState(false);
  const [gstData, setgstData] = useState({});
  const [mesgforerror, setmesgforerror] = useState("");
  const [currentStep, setCurrentStep] = useState(2);
  const [cityna,setcityna] = useState("")

  const nvg = useNavigate();
console.log("the is step 4",step1id)

  const fetchstateandcity = async (value,setFieldError,setFieldValue) =>{
    if(value.length == 6){
  try {
    let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
    const response = await axios.get(url);
    console.log(" new response vvv",response)
    if(response.data.city == "NA"){
      setFieldValue('city','');
      setcityna("NA");
    setFieldError('city',"Please Enter Your City")
    }else{
      setFieldValue('city',response.data.city);
      setcityna("");
    }
    setFieldValue('state',response.data.state)
    const countrydata = countriesdata.filter(item2=>item2.printable_name == response.data.country.trim())
    console.log(";;;;;;",countrydata)
    setFieldValue('country',countrydata[0].printable_name);
  
  } catch (error) {
    // console.log("error",error.response.data)
    // setFieldValue('city','')
    // setFieldValue('state','')
    setFieldError('pincode',error.response.data.message)
  }
  }else{
    setFieldValue('city','')
    setFieldValue('state','')
    setFieldValue('country','')
  }
  }


  const setgstaddress = (status,setFieldValue) =>{
if(status == false){
  // setcheckbox(true)

  setFieldValue("address",step1id.first_address);
  setFieldValue("address2",step1id.second_address);
  setFieldValue("postalcode",step1id.postal_code);
  setFieldValue("state",step1id.state);
  setFieldValue("city",step1id.city);
  setFieldValue("country",step1id.country );

}else{
  // setcheckbox(false)
  setFieldValue("address",'');
  setFieldValue("address2",'');
  setFieldValue("postalcode",'');
  setFieldValue("state",'');
  setFieldValue("city",'');
  setFieldValue("country",'');
}
  }




  const autofillvalue3 = (setFieldValue,step1idcopy) =>{
    console.log("this is form next fun",step1idcopy)
setTimeout(() => {
  if(step1id.first_address == step1idcopy.first_address && step1id.second_address == step1idcopy.second_address && step1id.postal_code == step1idcopy.postal_code && step1id.state == step1idcopy.state && step1id.city == step1idcopy.city && step1id.country == step1idcopy.country){
    setcheckbox(true)
  }
  setFieldValue("address",step1idcopy.first_address);
  setFieldValue("address2",step1idcopy.second_address);
  setFieldValue("postalcode",step1idcopy.postal_code);
  setFieldValue("state",step1idcopy.state);
  setFieldValue("city",step1idcopy.city);
  setFieldValue("country",step1idcopy.country );
}, 300);
    
 
  }

  const [countriesdata,setcountriesdata] = useState([])
  useEffect(()=>{ 
 async function getcountries() {
 let url = `${process.env.REACT_APP_API_URL}api/countries/`;
   try {
     const response = await axios.get(url);
     setcountriesdata(response.data)
   } catch (error) {
   }
 }
 getcountries()
   },[])

  return (
    <div style={{ backgroundColor: "rgb(249 249 249)",padding:'10px 0px'}}>
      <div
        className="fullwidth profileregister align-items-stretch"
        style={{ backgroundColor: "rgb(249 249 249)", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <div className="min-width stickyimage">
          <img
            src="./images/side.png"
            style={{
              marginBottom: "0px",
              cursor: "pointer",
              width: "100%",
              // height: "90vh",
              objectFit: "contain",
              position: "relative",
              top: "20px",
            }}
            alt
          />
        </div>
        <div className="min-width2">
          <div className="topsection">
            {/* </div>
          <div className="formsection topsection"> */}

            <Formik
              initialValues={{
                fullname: "",
                designation: "",
                email: "",
                mobile: "",
                address: "",
                 address2: "",
                 postalcode: "",
                 city: "",
                 state: "",
                country: "",
              }}
              validationSchema={Register4val}
              onSubmit={(values, { setFieldValue }) => {
                console.log("this is formik ", values);
                thirdform(values)
              }}
            >
              {({
                values,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                setFieldError,
                dirty,
                isValid,
              }) => (
                <Form
                  autoComplete="off"
                  className={`form-login container-fluid mt-2`}
                  style={{ width: "100%", minHeight: "90vh", height: "auto" }}
                  onSubmit={handleSubmit}
                >
                  <div className="row px-4">
                    <div className="col-xl-10 col-md-12 extrast">
                      {/* <img
                        src="./images/figmaimage9.png"
                        style={{
                          marginBottom: "0px",
                          cursor: "pointer",
                          width: "121%",
                          objectFit: "contain",
                          // position: "relative",
                          // top: "20px",
                        }}
                        alt
                      /> */}
                        <h5><span style={{color:'#9f9f9f',fontWeight:'500'}}> Step</span> 3/4 <span> <img src="./images/person.png" style={{width:'12.5px',marginLeft:'5px'}} alt="" /> </span> <span style={{color:'#3822ba'}}> Contact Information</span></h5>

                        <StepProgressBar
startingStep={currentStep}
  onSubmit={()=>{console.log("first")}}
  steps={[
    {
      name: 'step 1',
    },
    {
      name: 'step 2',
    },
    {
      name: 'step 3',
    },
    {
      name: 'step 4',
    }
  ]}primaryBtnClass="#230BB3" />
                    </div>
                    <div className="col-12">
                      <h4 className="title098">
                      Please Provide Contact Person Details
                      </h4>
                    </div>
                  {/* <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                        Full Name <span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="text"
                          name="fullname"
                          className="login-input mb-0"
                          placeholder={`Enter Your Full Name`}
                          value={values.fullname}
                          style={{
                            borderBottom:
                              errors.fullname && touched.fullname
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            setverifystatus_gst(0);
                            setFieldValue("fullname", e.target.value);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.fullname && touched.fullname ? (
                          <p style={{ color: "red" }}>{errors.fullname}</p>
                        ) : null}
                      </span>
                    
                    </div> 
                  <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                       Designation <span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="text"
                          name="designation"
                          className="login-input mb-0"
                          placeholder={`Enter Your Designation`}
                          value={values.designation}
                          style={{
                            borderBottom:
                              errors.designation && touched.designation
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            setverifystatus_gst(0);
                            setFieldValue("designation", e.target.value);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.designation && touched.designation ? (
                          <p style={{ color: "red" }}>{errors.designation}</p>
                        ) : null}
                      </span>
                    
                    </div> 
                  <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                        Contact Email <span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="text"
                          name="email"
                          className="login-input mb-0"
                          placeholder={`Enter Your Contact Email`}
                          value={values.email}
                          style={{
                            borderBottom:
                              errors.email && touched.email
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            setverifystatus_gst(0);
                            setFieldValue("email", e.target.value);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.email && touched.email ? (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        ) : null}
                      </span>
                    
                    </div> 
                  <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                        Contact Mobile No. <span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="tel"
                          name="mobile"
                          className="login-input mb-0"
                          placeholder={`Enter Your Contact Mobile No.`}
                          value={values.mobile}
                          style={{
                            borderBottom:
                              errors.mobile && touched.mobile
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            setverifystatus_gst(0);
                            setFieldValue("mobile", e.target.value.replace(/\D/g, ''));
                          }}
                        />

                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.mobile && touched.mobile ? (
                          <p style={{ color: "red" }}>{errors.mobile}</p>
                        ) : null}
                      </span>
                    
                    </div>  */}
                     <div className="col-xl-12 col-md-12 d-flex align-items-center" style={{gap:'4px',fontSize:'14px',fontWeight:'500'}} >
              {checkbox == false ? <input type="checkbox" name="" id="" onChange={()=>{setcheckbox(!checkbox);setgstaddress(checkbox,setFieldValue)}} /> : <input type="checkbox" onChange={()=>{setcheckbox(!checkbox);setgstaddress(checkbox,setFieldValue)}} checked name="" id="" /> }  
                    Same As GST Address
                     </div>
                     <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        > 
                         Registered Address <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="address"
                            className="login-input mb-0"
                            placeholder={`Enter Your Registered Address`}
                            value={values.address}
                            style={{
                              borderBottom:
                                errors.address && touched.address
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("address", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.address && touched.address ? (
                            <p style={{ color: "red" }}>{errors.address}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div>  


                      <div style={{position:'relative',overflow:'hidden'}}>
                      <button type="button" ref={buttonRefthree} style={{position:'absolute',visibility:'hidden',top:'0px',left:'0px'}} onClick={()=>{autofillvalue3(setFieldValue,step3id)}} ></button>
</div>

                      
                      <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        > 
                          Business Address <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="address2"
                            className="login-input mb-0"
                            placeholder={`Enter Your Business Address`}
                            value={values.address2}
                            style={{
                              borderBottom:
                                errors.address2 && touched.address2
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("address2", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.address2 && touched.address2 ? (
                            <p style={{ color: "red" }}>{errors.address2}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div>  
                      <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Postal Code <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="postalcode"
                            className="login-input mb-0"
                            placeholder={`Enter Your Postal Code`}
                            value={values.postalcode}
                            style={{
                              borderBottom:
                                errors.postalcode && touched.postalcode
                                  ? "1px solid red"
                                  : "",
                            }}
                            // onChange={(e) => {
                            //   setFieldValue("postalcode", e.target.value);
                            // }}
                          onChange={(e)=>{setFieldValue("postalcode",e.target.value.replace(/[^0-9\ ]/gi, ""));fetchstateandcity(e.target.value.replace(/[^0-9\ ]/gi, ""),setFieldError,setFieldValue)}}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.postalcode && touched.postalcode ? (
                            <p style={{ color: "red" }}>{errors.postalcode}</p>
                          ) : null}
                        </span>
  
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.postalcode == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> 
                      {values.state !== '' && values.state !== null && cityna == 'NA' ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="city"
                            className="login-input mb-0"
                            placeholder={`Enter Your City`}
                            value={values.city}
                            style={{
                              borderBottom:
                                errors.city && touched.city
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.city && touched.city ? (
                            <p style={{ color: "red" }}>{errors.city}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> : <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="city"
                            className="login-input mb-0"
                            placeholder={`Enter Your City`}
                            value={values.city}
                            readOnly
                            style={{
                              borderBottom:
                                errors.city && touched.city
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.city && touched.city ? (
                            <p style={{ color: "red" }}>{errors.city}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div>}
                       
                       <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          State <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="state"
                          readOnly
                            className="login-input mb-0"
                            placeholder={`Enter Your State`}
                            value={values.state}
                            style={{
                              borderBottom:
                                errors.state && touched.state
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("state", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.state && touched.state ? (
                            <p style={{ color: "red" }}>{errors.state}</p>
                          ) : null}
                        </span>

                      </div> 

                     
                      <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Country <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="country"
                            className="login-input mb-0"
                            placeholder={`Enter Your Country`}
                          readOnly
                            value={values.country}
                            style={{
                              borderBottom:
                                errors.country && touched.country
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("country", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.country && touched.country ? (
                            <p style={{ color: "red" }}>{errors.country}</p>
                          ) : null}
                        </span>

                      </div>
                       
                     
                   <div className="col-xl-12 col-md-12 d-flex justify-content-end py-4" style={{gap:'4px'}}>
                   <button
                        type="button"
                        // disabled={!(isValid && dirty)}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content',backgroundColor:'#a09e9e'}}
                        onClick={()=>{changestep(1)}}
                      >
                        Back
                        {/* <span>
                          <FaArrowRightLong color="white" />
                        </span> */}
                      </button>
                      <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Next
                        <span>
                          <FaArrowRightLong color="white" />
                        </span>
                      </button>
                    </div> 
                   
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register4;
