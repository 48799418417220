import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { Register2val } from "../Validation/register2val";
import axios from "axios";
import { Register4val } from "../Validation/register4val";
import { Register5val } from "../Validation/register5val";
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import Loader from "../components/Loader";

const Register5 = ({step3id,changestep,step1id}) => {
  const [verifystatus_gst, setverifystatus_gst] = useState(0);
  const [gstData, setgstData] = useState({});
  const [mesgforerror, setmesgforerror] = useState("");
  const [currentStep, setCurrentStep] = useState(3);
  const [statusstep5, setstatusstep5] = useState("");
  const [sub, setsub] = useState(0);
  const [loader4, setloader4] = useState(true);
  const [checkbox, setcheckbox] = useState(false);
  const setgstaddress = (status,setFieldValue,step1id) =>{
    if(status == false){
      // setcheckbox(true)
    console.log("qwertyuio11111rrrrp",step1id)
      setFieldValue("billingaddress",`${step1id.first_address} ${step1id.second_address} ${step1id.country} ${step1id.state} ${step1id.city} ${step1id.postal_code}`);
    
    }else{
      // setcheckbox(false)
      setFieldValue("billingaddress",'');
    }
      }
  const fourthform = async(values,setstatusstep5) =>{

    console.log("this is super resp")


   




    setloader4(false)
        const formdata = new FormData()
        // formdata.append('bank_name',values.bankname)
        // formdata.append('bank_acc_no',values.bankaccountnumber)
        // formdata.append('bank_address',values.bankaddress)
        formdata.append('billing_address',values.billingaddress)
        formdata.append('upload_pan',values.pan)
        formdata.append('upload_gst',values.gst)
        formdata.append('certificate_of_corporation',values.certificateofcorporation)
        formdata.append('msme',values.msme)
        formdata.append('cancelation_letter',values.cancelledcheque)
        formdata.append('authorization_letter',values.authorizationletter)
        // formdata.append('ifsc_code',values.ifsc)
        formdata.append('onboarding_alternative_perDetails',step3id.id)
        if(sub == 0){
          try {
            let url = `${process.env.REACT_APP_API_URL}useraccount/onboarding-bank-details/`;
            setsub(1)
          const response = await axios.post(url, formdata);
          console.log("respo of bussiness",response);
          setstatusstep5(response.data.message)
          setloader4(true)
          setstatusstep5(response.data.message)
      setTimeout(() => {
        nvg('/thankyoupageforonboarding')
      }, 2000);
          } catch (error) {
          }
        }else{
          console.log("first")
        }
        
        }


  const nvg = useNavigate();

  return loader4 == false ? <div style={{width:'100%',minHeight:'100vh'}} className="d-flex justify-content-center align-items-center"><Loader /></div> : <div style={{ backgroundColor: "rgb(249 249 249)",padding:'10px 0px'}}>
      <div
        className="fullwidth  profileregister align-items-stretch"
        style={{ backgroundColor: "rgb(249 249 249)", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <div className="min-width stickyimage">
          <img
            src="./images/side.png"
            style={{
              marginBottom: "0px",
              cursor: "pointer",
              width: "100%",
              height: "90vh",
              objectFit: "contain",
              position: "relative",
              top: "20px",
            }}
            alt
          />
        </div>
        <div className="min-width2">
          <div className="topsection">
            {/* </div>
          <div className="formsection topsection"> */}

            <Formik
              initialValues={{
                bankname: "",
                bankaccountnumber: "",
                billingaddress: "",
                bankaddress: "",
                ifsc: "",
                // terms:false,
                gst:null,
                pan:null,
                cancelledcheque:null,
                authorizationletter:null,
                msme:null,
                certificateofcorporation:null
              }}
              validationSchema={Register5val}
              onSubmit={(values, { setFieldValue }) => {
                console.log("this is formik ", values);
                fourthform(values,setstatusstep5)
              }}
            >
              {({
                values,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                dirty,
                isValid,
              }) => (
                <Form
                  autoComplete="off"
                  className={`form-login container-fluid mt-2`}
                  style={{ width: "100%", minHeight: "100vh", height: "auto" }}
                  onSubmit={handleSubmit}
                >
                  <div className="row px-4">
                    <div className="col-xl-10 col-md-12 extrast">
                      {/* <img
                        src="./images/figmaimage10.png"
                        style={{
                          marginBottom: "0px",
                          cursor: "pointer",
                          width: "121%",
                          objectFit: "contain",
                        }}
                        alt
                      /> */}
                                              <h5><span style={{color:'#9f9f9f',fontWeight:'500'}}> Step</span> 4/4 <span> <img src="./images/Vector2.png" style={{width:'16.5px',marginLeft:'5px'}} alt="" /> </span> <span style={{color:'#3822ba'}}> Financial Information</span></h5>

                        <StepProgressBar
startingStep={currentStep}
  onSubmit={()=>{console.log("first")}}
  steps={[
    {
      name: 'step 1',
    },
    {
      name: 'step 2',
    },
    {
      name: 'step 3',
    },
    {
      name: 'step 4',
    }
  ]}primaryBtnClass="#230BB3" />
                    </div>
                    <div className="col-12">
                      <h4 className="title098">
                      Please Enter Bank and Other Details
                      </h4>
                    </div>
                  {/* <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                        Bank Name <span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="text"
                          name="bankname"
                          className="login-input mb-0"
                          placeholder={`Enter Bank Name`}
                          value={values.bankname}
                          style={{
                            borderBottom:
                              errors.bankname && touched.bankname
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            setverifystatus_gst(0);
                            setFieldValue("bankname", e.target.value);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.bankname && touched.bankname ? (
                          <p style={{ color: "red" }}>{errors.bankname}</p>
                        ) : null}
                      </span>
                    
                    </div> 
                  <div className="col-xl-6 col-md-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                       Bank Account Number<span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="text"
                          name="bankaccountnumber"
                          className="login-input mb-0"
                          placeholder={`Enter Bank Account Number`}
                          value={values.bankaccountnumber}
                          style={{
                            borderBottom:
                              errors.bankaccountnumber && touched.bankaccountnumber
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            setverifystatus_gst(0);
                            setFieldValue("bankaccountnumber", e.target.value);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.bankaccountnumber && touched.bankaccountnumber ? (
                          <p style={{ color: "red" }}>{errors.bankaccountnumber}</p>
                        ) : null}
                      </span>
                    
                    </div> 
                  <div className="col-xl-6 col-md-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                       IFSC <span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="text"
                          name="bankaccountnumber"
                          className="login-input mb-0"
                          placeholder={`Enter IFSC Code`}
                          value={values.ifsc}
                          style={{
                            borderBottom:
                              errors.ifsc && touched.ifsc
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            setverifystatus_gst(0);
                            setFieldValue("ifsc", e.target.value);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.ifsc && touched.ifsc ? (
                          <p style={{ color: "red" }}>{errors.ifsc}</p>
                        ) : null}
                      </span>
                    
                    </div> 
                    <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                        Bank Address<span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="text"
                          name="bankaddress"
                          className="login-input mb-0"
                          placeholder={`Enter Bank Address`}
                          value={values.bankaddress}
                          style={{
                            borderBottom:
                              errors.bankaddress && touched.bankaddress
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            setverifystatus_gst(0);
                            setFieldValue("bankaddress", e.target.value);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.bankaddress && touched.bankaddress ? (
                          <p style={{ color: "red" }}>{errors.bankaddress}</p>
                        ) : null}
                      </span>
                    
                    </div>  */}
                     <div className="col-xl-12 col-md-12 d-flex align-items-center" style={{gap:'4px',fontSize:'14px',fontWeight:'500'}} >
              {checkbox == false ? <input type="checkbox" name="" id="" onChange={()=>{setcheckbox(!checkbox);setgstaddress(checkbox,setFieldValue,step1id)}} /> : <input type="checkbox" onChange={()=>{setcheckbox(!checkbox);setgstaddress(checkbox,setFieldValue)}} checked name="" id="" /> }  
                    Same As GST Address
                     </div>
                  <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                        Billing Address<span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="text"
                          name="billingaddress"
                          className="login-input mb-0"
                          placeholder={`Enter Billing Address`}
                          value={values.billingaddress}
                          style={{
                            borderBottom:
                              errors.billingaddress && touched.billingaddress
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            setverifystatus_gst(0);
                            setFieldValue("billingaddress", e.target.value);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.billingaddress && touched.billingaddress ? (
                          <p style={{ color: "red" }}>{errors.billingaddress}</p>
                        ) : null}
                      </span>
                    
                    </div> 


                    <div className="col-xl-12 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="profileImage">Upload PAN <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  name="pan"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.pan && touched.pan ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("pan", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.pan && touched.pan && <p style={{ color: "red" }}>{errors.pan}</p>}
              </span>
            </div>

            <div className="col-xl-12 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="GST">Upload GST <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  name="gst"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.gst && touched.gst ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("gst", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.gst && touched.gst && <p style={{ color: "red" }}>{errors.gst}</p>}
              </span>
              </div>

              <div className="col-xl-12 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="certificateofcorporation">Upload Certificate of Corporation <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  name="certificateofcorporation"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.certificateofcorporation && touched.certificateofcorporation ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("certificateofcorporation", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.certificateofcorporation && touched.certificateofcorporation && <p style={{ color: "red" }}>{errors.certificateofcorporation}</p>}
              </span>
              </div>


              <div className="col-xl-12 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="GST">Upload Authorization Letter<span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  name="authorizationletter"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.authorizationletter && touched.authorizationletter ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("authorizationletter", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.authorizationletter && touched.authorizationletter && <p style={{ color: "red" }}>{errors.authorizationletter}</p>}
              </span>
              </div>

              <div className="col-xl-12 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="GST">Upload Msme <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  name="msme"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.msme && touched.msme ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("msme", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.msme && touched.msme && <p style={{ color: "red" }}>{errors.msme}</p>}
              </span>
              </div>

              <div className="col-xl-12 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="GST">Upload Cancelled Cheque <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  name="cancelledcheque"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.cancelledcheque && touched.cancelledcheque ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("cancelledcheque", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.cancelledcheque && touched.cancelledcheque && <p style={{ color: "red" }}>{errors.cancelledcheque}</p>}
              </span>
              </div>
  <div className={`col-12 mt-2 mb-3`}>
                              <Field type="checkbox" name="terms" style={{position:'relative',top:'1px',left:'-4px'}}  />
                            I agree to <span style={{color:'#230BB3',cursor:'pointer'}} onClick={()=>{nvg("/useragreement")}}> User Agreement </span> and <span style={{color:'#230BB3',cursor:'pointer'}} onClick={()=>{nvg("/privacypolicy")}}> Privacy Policy
                            </span>                            </div>


                  {/* <div className="col-xl-12 col-md-12">
                  <Field type="checkbox" name="terms" style={{position:'relative',top:'1px',left:'-4px'}}  />
                  Same as Company Address
</div> */}
{statusstep5 == "" ? "" : <div className="col-xl-12">
<div class="alert alert-success mt-2" role="alert">
  {statusstep5}
</div>
</div>}

                  
                   <div className="col-xl-12 col-md-12 d-flex justify-content-end py-4" style={{gap:'5px'}}>
                   <button
                        type="button"
                        // disabled={!(isValid && dirty)}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content',backgroundColor:'#a09e9e'}}
                        onClick={()=>{changestep(2)}}
                      >
                        Back
                        {/* <span>
                          <FaArrowRightLong color="white" />
                        </span> */}
                      </button>
                      <button
                        type="submit"
                        disabled={!(isValid && dirty)}
                        className="btn "
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Submit
                        <span>
                          <FaArrowRightLong color="white" />
                        </span>
                      </button>
                    </div> 
                   
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
};

export default Register5;
