import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import { freezrorderstore, getfreeze, gettoken, getvoucher, removeToken, removefreeze } from "../Localstorage/Store";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const Pay = () => {
  const [data, setData] = useState([]);
  const [cartinfo, setcartinfo] = useState({});
  const [userloader, setuserloader] = useState(false);
  const [outstock, setoutstock] = useState(false);
  const [paymethod, setpaymethod] = useState("Online");
  const [loading, setloading] = useState(false);
  const [addloader, setaddloader] = useState(false);
  const [walletamount, setwalletamount] = useState(0);
  const [creditamount, setcreditamount] = useState(0);
  const [creditminusamount, setcreditminusamount] = useState(0);
  const [creditshippingstatus, setcreditshippingstatus] = useState(false);
  const [keyvalue, setkeyvalue] = useState([]);
  const [paywalletamount, setpaywalletamount] = useState("");
  const [paycreditamount, setpaycreditamount] = useState("");
  const [iswallet, setiswallet] = useState(false);
  const [iscredit, setiscredit] = useState(false);
  const [vouchererror, setvouchererror] = useState('');
  const tokenvalue = gettoken();
  const freezeid =getfreeze();
  const [qty_total, setqty_total] = useState(0);
  const closeaddressbtn = useRef(null);
  const buttonRef = useRef(null);
  const [mobileno, setmobileno] = useState("");
  const [otp, setotp] = useState('');


  console.log("this is frees rrttj",mobileno)
  const nvg = useNavigate();
  const location = useLocation();
  const localvouhcer = getvoucher();

  function checkPaymentMethod(method) {
    // if (paymethod == "Online" && method == "wallet") {
    //   setpaymethod("Online/wallet");
    // } else if (paymethod == "wallet" && method == "Online") {
    //   setpaymethod("Online/wallet");
    // } else if (paymethod == "Online/wallet") {
    //   setpaymethod(method);
    // } else if (method == "wallet") {
    //   setpaymethod("wallet");
    // } else if (method == "Online") {
    //   setpaymethod("Online");
    // }
    if (method == "Online" && iswallet == true) {
      setpaymethod("Online/wallet");
    } else {
      setpaymethod("Online");
    }
    console.log(paymethod);
  }
console.log("aaathis si location data 222222",location,"this is here")
  const handleInputChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, '');
    // Check if the new value exceeds the fixed amount
    if (newValue <= walletamount) {
      setpaywalletamount(newValue);
    } else {
      // If the new value exceeds the fixed amount, set it to the fixed amount
      setpaywalletamount(walletamount);
    }
  };
  const [creditgst,setcreditgst] = useState({status:false,message:''})
  const handleInputChange2 = (e) => {
    const newValue = e.target.value.replace(/\D/g, '');
    // Check if the new value exceeds the fixed amount
    // if (newValue <= creditamount) {
    if(newValue == null || newValue == undefined || newValue == 0 || newValue == ''){
      setpaycreditamount('');
    }else{
    if (newValue <= (location.state.total - creditminusamount - (creditshippingstatus == false ? parseInt(shippingcharnge?.shipping_price) : 0)) && newValue < creditamount) {
      // if(creditshippingstatus == false){
      //   setpaycreditamount(newValue - parseInt(shippingcharnge?.shipping_price));
      // }else{
        if(creditamount > (location.state.total - creditminusamount - (creditshippingstatus == false ? parseInt(shippingcharnge?.shipping_price) : 0))){
          setpaycreditamount(newValue);
        }else{
          // setpaycreditamount(creditamount);
          setpaycreditamount(newValue);
         }
        
        setcreditgst({status:false,message:''})
      // } 
      // ?parseInt(shippingcharnge?.shipping_price) 
    } else {
      // If the new value exceeds the fixed amount, set it to the fixed amount
      // setpaycreditamount(creditamount);
      // if(creditshippingstatus == false){
      //   // setpaycreditamount(newValue + parseInt(shippingcharnge?.shipping_price));
      //   setpaycreditamount((location.state.total - creditminusamount) - parseInt(shippingcharnge?.shipping_price));
      // }else{
        // setpaycreditamount(newValue);
        setcreditgst({status:true,message:creditminusamount > 0 ? creditshippingstatus == false ? ` ₹${creditminusamount} Product GST and Shipping Charges is Payabale` : `Product GST is Payabale` : creditshippingstatus == false ? `Shipping Charges is Payabale` : ``})
       if(creditamount > (location.state.total - creditminusamount - (creditshippingstatus == false ? parseInt(shippingcharnge?.shipping_price) : 0))){

        setpaycreditamount(location.state.total - creditminusamount - (creditshippingstatus == false ? parseInt(shippingcharnge?.shipping_price) : 0));
      }else{
        setpaycreditamount(creditamount);
       }
      // } 
    }
  }
  };

  async function walletAmountCheck() {
    console.log(tokenvalue.access);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      setuserloader(true);
      let url = `${process.env.REACT_APP_API_URL}my_api/wallet-amount/`;
      const response = await axios.get(url, config);
      console.log(response);
      console.log(response.status);
      if ((response.status = "200")) {
        setuserloader(false);
        console.log(response.amount);
        return response.data.amount;
      }

      //  setloading(false)
    } catch (error) {}
  }
  async function onlineAndCod() {
    // const walletAmount = await walletAmountCheck();
    if(Math.round(location.state.total) == paywalletamount){
      walletpay()
    }else{
    const ids = await fetchstatus()
    var total_amount_pay = location.state.total - paywalletamount;
    console.log("this is total a",total_amount_pay)
    // if (total_amount_pay < 0) {
    //   const wal = await walletpay();
    // } else {
    const userinfo = location.state.checkoutdata;
    // console.log(location.state);
    const shipping_address = {
      first_name: userinfo.shipping_address.first_name,
      last_name: userinfo.shipping_address.last_name,
      line1: userinfo.shipping_address.line1,
      line2: userinfo.shipping_address.line2,
      email: userinfo.shipping_address.email,
      country: userinfo.shipping_address.country,
      state: userinfo.shipping_address.state,
      line4: userinfo.shipping_address.line4,
      postcode: userinfo.shipping_address.postcode,
      phone_number: userinfo.shipping_address.phone_number,
      email: userinfo.shipping_address.email,
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    };
    // console.log("sssss",parseFloat(location.state.total * 100),"ggggg",location.state.total)
    var options = {
      key: "rzp_test_aiOVlfNqfFBdQC",
      // key: "rzp_live_qyRP6vevsxnvA3",
      key_secret: "mKKqmTlBRBIL0ulpzc6sCdjs",
      // key_secret: "6OlUr5rM3aj3isSidN88jUJq",
      amount: Number(parseFloat(total_amount_pay) * 100).toFixed(0),
      // amount: (Number(parseFloat(location.state.total) + parseFloat(shippingcharnge?.shipping_price)) * 100).toFixed(2),
      currency: "INR",
      order_receipt: "order_rcptid_" + userinfo.shipping_address.first_name,
      name: "ONEUP BRANDS",
      description: "for testing purpose",
      handler: async function (response) {
        const paymentId = response.razorpay_payment_id;
        const orderInfo =
          tokenvalue.msg == "Voucher login"
            ? {
              shipping_address:userinfo.shipping_address,
              billing_address:userinfo.billing_address,
                date: new Date().toLocaleString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                }),
                email: userinfo.guest_email,
                basket: userinfo.basket,
                payment_id: paymentId,
                status: "ACCEPTED",
                wallet: false,
                wallet_amount: paywalletamount,
                // payment_signature:"ss",
                shipping_method_code: "no-shipping-required",
                payment_types: "razorpay",
                total_amount: parseFloat(location.state.total),
                code: localvouhcer,
              }
            : {
              shipping_address:userinfo.shipping_address,
              billing_address:userinfo.billing_address,
                date: new Date().toLocaleString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                }),
                email: userinfo.guest_email,
                basket: userinfo.basket,
                payment_id: paymentId,
                status: "ACCEPTED",
                wallet: true,
                wallet_amount: paywalletamount,
                shipping_method_code: "no-shipping-required",
                payment_types: "Custome",
                total_amount: parseFloat(location.state.total),
              };

        try {
          // nvg("/thankyoupage", {
          //   state: {
          //     checkoutdata: location.state.checkoutdata,
          //     orderinfo:orderInfo
          //   },
          // });
         await unfreezestatus(freezeid,keyvalue,ids)
          thankyoupage(orderInfo);
        } catch (error) {
          // nvg("/")
        }
      },

      theme: {
        color: "#230bb3",
      },
      modal: {
        ondismiss: function () {
            console.log("Payment modal closed by the user");
            setTimeout(() => {
              unfreezestatus(freezeid,keyvalue,ids)
            }, 2000);
        }
    },
    };
    var pay = new window.Razorpay(options);
    pay.open();
    // }
  }
  }




  async function onlineAndCod2() {
    console.log("check pay fun")
    // const walletAmount = await walletAmountCheck();
    // if(Math.round(location.state.total) == paycreditamount){
    //   Creditpay()
    // }else{

//       const configcredit = {
//         headers: {
//           Authorization: `Bearer ${tokenvalue.access}`,
//           "Content-Type": "application/json",
//         },
//       };
//       const formdatacredit = new FormData();
//       formdatacredit.append("amount",paycreditamount);
//       let urlcredit = `${process.env.REACT_APP_API_URL}credit/remove-amount/`;
//       const response = await axios.post(urlcredit, formdatacredit, configcredit);
// console.log("this is post credit api test",response);

  //  if(response.data.succes == 'amount removed test'){

  if((location.state.total - paycreditamount) > 0){
  // console.log("dkdkdkdkdkdksllssdddds",location.state.total - paycreditamount)
  console.log("op payment")

    const ids = await fetchstatus()
    var total_amount_pay = location.state.total - paycreditamount;
    console.log("this is total a",total_amount_pay)
    // if (total_amount_pay < 0) {
    //   const wal = await walletpay();
    // } else {
    const userinfo = location.state.checkoutdata;
    // console.log(location.state);
    const shipping_address = {
      first_name: userinfo.shipping_address.first_name,
      last_name: userinfo.shipping_address.last_name,
      line1: userinfo.shipping_address.line1,
      line2: userinfo.shipping_address.line2,
      email: userinfo.shipping_address.email,
      country: userinfo.shipping_address.country,
      state: userinfo.shipping_address.state,
      line4: userinfo.shipping_address.line4,
      postcode: userinfo.shipping_address.postcode,
      phone_number: userinfo.shipping_address.phone_number,
      email: userinfo.shipping_address.email,
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    };
    // console.log("sssss",parseFloat(location.state.total * 100),"ggggg",location.state.total)
    var options = {
      key: "rzp_test_aiOVlfNqfFBdQC",
      // key: "rzp_live_qyRP6vevsxnvA3",
      key_secret: "mKKqmTlBRBIL0ulpzc6sCdjs",
      // key_secret: "6OlUr5rM3aj3isSidN88jUJq",
      amount: Number(parseFloat(total_amount_pay) * 100).toFixed(0) > 0 ? Number(parseFloat(total_amount_pay) * 100).toFixed(0) : 0,
      // amount: (Number(parseFloat(location.state.total) + parseFloat(shippingcharnge?.shipping_price)) * 100).toFixed(2),
      currency: "INR",
      order_receipt: "order_rcptid_" + userinfo.shipping_address.first_name,
      name: "ONEUP BRANDS",
      description: "for testing purpose",
      handler: async function (response) {
        const paymentId = response.razorpay_payment_id;
        const orderInfo = {
              shipping_address:userinfo.shipping_address,
              billing_address:userinfo.billing_address,
                date: new Date().toLocaleString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                }),
                email: userinfo.guest_email,
                basket: userinfo.basket,
                payment_id: paymentId,
                status: "ACCEPTED",
                credit: true,
                credit_amount: paycreditamount,
                shipping_method_code: "no-shipping-required",
                payment_types: "Custome",
                total_amount: parseFloat(location.state.total),
              };

        try {
          // nvg("/thankyoupage", {
          //   state: {
          //     checkoutdata: location.state.checkoutdata,
          //     orderinfo:orderInfo
          //   },
          // });
         await unfreezestatus(freezeid,keyvalue,ids)
          thankyoupage(orderInfo);
        } catch (error) {
          // nvg("/")
        }
      },

      theme: {
        color: "#230bb3",
      },
      modal: {
        ondismiss: function () {
            console.log("Payment modal closed by the user");
            setTimeout(() => {
              unfreezestatus(freezeid,keyvalue,ids)
            }, 2000);
        }
    },
    };
    var pay = new window.Razorpay(options);
    pay.open();
    // }
  //  }else{
  //   console.log("this is error test api")
  //  }

    
  // }
  }else{
    console.log("op credit")
    Creditpay()
  }




  }








// Send Mobile otp api start here 


const sendotp = async () =>{

  // setloading(true)
  const formdata = new FormData()
    formdata.append('mobile_number',realmobile)
  
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
      },
    };
    try {
      let url = `${process.env.REACT_APP_API_URL}credit/send-otp/`;
    const response = await axios.post(url,formdata,config);
  //   setshowotp(true)
  // setverifyotpstatus(1)
  // sethidesend(1)
  
  // setTimeout(() => {
  // setverifyotpstatus(0)
  // }, 5000);
    // setloading(false)
    } catch (error) {
    // setloading(false)
    }
  }

const verifyotp = async () =>{

  // setloading(true)
  const formdata = new FormData()
    formdata.append('mobile_number',realmobile)
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
      },
    };
    try {
      const data = {
        'mobile_number':realmobile,
        'otp':otp,
      }
          let url = `${process.env.REACT_APP_API_URL}credit/verify-otp/`;
        const response = await axios.post(url,data,config);
        console.log("verify data of dkdk",response)
        if(response.data.message == 'phone verified'){

          onlineAndCod2()
          
        }
  //   setshowotp(true)
  // setverifyotpstatus(1)
  // sethidesend(1)
  
  // setTimeout(() => {
  // setverifyotpstatus(0)
  // }, 5000);
    // setloading(false)
    } catch (error) {
    // setloading(false)
    }
  }
  // Send Mobile otp api end here 









  // console.log("location data of noq", location);
  const checkoutform = async () => {
    thankyoupage(location.state.checkoutdata);
  };


const [freezefun,setfreezefun] = useState(false)
  const fetchstatus = async () => {
    if(freezefun == false){
      setfreezefun(true)
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}my_api/freeze-stock/`;
  
      const response = await axios.get(url, config);
      console.log("this is freeze api",response)
      freezrorderstore(response.data.freeze_ids)
      setkeyvalue(response.data.freeze_ids)
      setfreezefun(false)
      return response.data.freeze_ids;
    }
    

  }
  console.log("this is unfreezw api",freezeid,keyvalue)

  const unfreezestatus = async (freezeid,keyvalue,ids) => {
    console.log("this is unfreezw api",freezeid,keyvalue,ids)
    if(freezeid || keyvalue.length > 0 || ids){
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      const formdata = {freeze_id_list:freezeid ? freezeid : ids};
      let url = `${process.env.REACT_APP_API_URL}my_api/unfreeze-stock/`;
  
      const response = await axios.post(url,formdata, config);
      console.log("this is freeze api",response)
      removefreeze()

    }
  }
  const shippingcharnge = location.state;
  
  const Onlinepay = async () => {
    console.log("fitrst erorr is here");
    if(localvouhcer){
      const formdata = new FormData()
      formdata.append('code',localvouhcer)
    
        let url3 = `${process.env.REACT_APP_API_URL}homepageapi/already-used-voucher-code/`;
        const response2 = await axios.post(url3, formdata);
      if(response2.data.message == `This code (${localvouhcer}) is valid.`){
        const userinfo = location.state.checkoutdata;
        console.log("this is current funt",location.state);
        const ids = await fetchstatus()
        console.log("ids",ids)
        const shipping_address = {
          first_name: userinfo.shipping_address.first_name,
          last_name: userinfo.shipping_address.last_name,
          line1: userinfo.shipping_address.line1,
          line2: userinfo.shipping_address.line2,
          email: userinfo.shipping_address.email,
          country: userinfo.shipping_address.country,
          state: userinfo.shipping_address.state,
          line4: userinfo.shipping_address.line4,
          postcode: userinfo.shipping_address.postcode,
          phone_number: userinfo.shipping_address.phone_number,
          date: new Date().toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }),
        };
        // console.log("sssss",parseFloat(location.state.total * 100),"ggggg",location.state.total)
        var options = {
          key: "rzp_test_aiOVlfNqfFBdQC",
          // key: "rzp_live_qyRP6vevsxnvA3",
          key_secret: "mKKqmTlBRBIL0ulpzc6sCdjs",
          // key_secret: "6OlUr5rM3aj3isSidN88jUJq",
          amount: Number(parseFloat(location.state.total) * 100).toFixed(0),
          // amount: (Number(parseFloat(location.state.total) + parseFloat(shippingcharnge?.shipping_price)) * 100).toFixed(2),
          currency: "INR",
          order_receipt: "order_rcptid_" + userinfo.shipping_address.first_name,
          name: "ONEUP BRANDS",
          description: "for testing purpose",
          handler: async function (response) {
            const paymentId = response.razorpay_payment_id;
            const orderInfo =
              tokenvalue.msg == "Voucher login"
                ? {
                  shipping_address:userinfo.shipping_address,
                  billing_address:userinfo.billing_address,
                    date: new Date().toLocaleString("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    }),
                    email: userinfo.guest_email,
                    basket: userinfo.basket,
                    payment_id: paymentId,
                    status: "ACCEPTED",
                    // payment_signature:"ss",
                    shipping_method_code: "no-shipping-required",
                    payment_types: "razorpay",
                    total_amount: parseFloat(location.state.total),
                    code: localvouhcer,
                  }
                : {
                  shipping_address:userinfo.shipping_address,
                  billing_address:userinfo.billing_address,
                    date: new Date().toLocaleString("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    }),
                    email: userinfo.guest_email,
                    basket: userinfo.basket,
                    payment_id: paymentId,
                    status: "ACCEPTED",
                    wallet: false,
                    wallet_amount: 0,
                    // payment_signature:"ss",
                    shipping_method_code: "no-shipping-required",
                    payment_types: "razorpay",
                    total_amount: parseFloat(location.state.total),
                  };
    
            try {
              // nvg("/thankyoupage", {
              //   state: {
              //     checkoutdata: location.state.checkoutdata,
              //     orderinfo:orderInfo
              //   },
              // });
             await unfreezestatus(freezeid,keyvalue,ids)
              thankyoupage(orderInfo);
            } catch (error) {
              // nvg("/")
            }
          },
    
          theme: {
            color: "#230bb3",
          },
          modal: {
            ondismiss: function () {
                console.log("Payment modal closed by the user");
                setTimeout(() => {
                  unfreezestatus(freezeid,keyvalue,ids)
                }, 2000);
            }
        },
        };
    
        var pay = new window.Razorpay(options);
        pay.open();
      }else{
        setvouchererror("This Voucher has already been used")
      }

    }else{







      // (response2.data.message == `This code (${localvouhcer}) is valid.`){
        const userinfo = location.state.checkoutdata;
        console.log("this is current funt",location.state);
        const ids = await fetchstatus()
        console.log("ids",ids)
        const shipping_address = {
          first_name: userinfo.shipping_address.first_name,
          last_name: userinfo.shipping_address.last_name,
          line1: userinfo.shipping_address.line1,
          line2: userinfo.shipping_address.line2,
          email: userinfo.shipping_address.email,
          country: userinfo.shipping_address.country,
          state: userinfo.shipping_address.state,
          line4: userinfo.shipping_address.line4,
          postcode: userinfo.shipping_address.postcode,
          phone_number: userinfo.shipping_address.phone_number,
          date: new Date().toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }),
        };
        // console.log("sssss",parseFloat(location.state.total * 100),"ggggg",location.state.total)
        var options = {
          key: "rzp_test_aiOVlfNqfFBdQC",
          // key: "rzp_live_qyRP6vevsxnvA3",
          key_secret: "mKKqmTlBRBIL0ulpzc6sCdjs",
          // key_secret: "6OlUr5rM3aj3isSidN88jUJq",
          amount: Number(parseFloat(location.state.total) * 100).toFixed(0),
          // amount: (Number(parseFloat(location.state.total) + parseFloat(shippingcharnge?.shipping_price)) * 100).toFixed(2),
          currency: "INR",
          order_receipt: "order_rcptid_" + userinfo.shipping_address.first_name,
          name: "ONEUP BRANDS",
          description: "for testing purpose",
          handler: async function (response) {
            const paymentId = response.razorpay_payment_id;
            const orderInfo =
              tokenvalue.msg == "Voucher login"
                ? {
                  shipping_address:userinfo.shipping_address,
                  billing_address:userinfo.billing_address,
                    date: new Date().toLocaleString("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    }),
                    email: userinfo.guest_email,
                    basket: userinfo.basket,
                    payment_id: paymentId,
                    status: "ACCEPTED",
                    // payment_signature:"ss",
                    shipping_method_code: "no-shipping-required",
                    payment_types: "razorpay",
                    total_amount: parseFloat(location.state.total),
                    code: localvouhcer,
                  }
                : {
                  shipping_address:userinfo.shipping_address,
                  billing_address:userinfo.billing_address,
                    date: new Date().toLocaleString("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    }),
                    email: userinfo.guest_email,
                    basket: userinfo.basket,
                    payment_id: paymentId,
                    status: "ACCEPTED",
                    wallet: false,
                    wallet_amount: 0,
                    // payment_signature:"ss",
                    shipping_method_code: "no-shipping-required",
                    payment_types: "razorpay",
                    total_amount: parseFloat(location.state.total),
                  };
    
            try {
              // nvg("/thankyoupage", {
              //   state: {
              //     checkoutdata: location.state.checkoutdata,
              //     orderinfo:orderInfo
              //   },
              // });
             await unfreezestatus(freezeid,keyvalue,ids)
              thankyoupage(orderInfo);
            } catch (error) {
              // nvg("/")
            }
          },
    
          theme: {
            color: "#230bb3",
          },
          modal: {
            ondismiss: function () {
                console.log("Payment modal closed by the user");
                setTimeout(() => {
                  unfreezestatus(freezeid,keyvalue,ids)
                }, 2000);
            }
        },
        };
    
        var pay = new window.Razorpay(options);
        pay.open();
      
    }
   
   

  };

  const walletpay = async () => {
    console.log("wallet pay");

    const userinfo = location.state.checkoutdata;
    const shipping_address = {
      first_name: userinfo.shipping_address.first_name,
      last_name: userinfo.shipping_address.last_name,
      line1: userinfo.shipping_address.line1,
      line2: userinfo.shipping_address.line2,
      email: userinfo.shipping_address.email,
      country: userinfo.shipping_address.country,
      state: userinfo.shipping_address.state,
      line4: userinfo.shipping_address.line4,
      postcode: userinfo.shipping_address.postcode,
      email: userinfo.shipping_address.email,
      phone_number: userinfo.shipping_address.phone_number,
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    };
    const walletdata = {
      shipping_address:userinfo.shipping_address,
              billing_address:userinfo.billing_address,
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
      email: userinfo.guest_email,
      basket: userinfo.basket,
      wallet: "total",
      wallet_amount: parseFloat(location.state.total),
      status: "ACCEPTED",
      // payment_signature:"ss",
      payment_types: "Wallet",
      shipping_method_code: "no-shipping-required",
      // payment_types:"razorpay",
      total_amount: parseFloat(location.state.total),
    };

    thankyoupage(walletdata);
  };
  const Creditpay = async () => {
    console.log("credit pay function is here");

    const userinfo = location.state.checkoutdata;
    const shipping_address = {
      first_name: userinfo.shipping_address.first_name,
      last_name: userinfo.shipping_address.last_name,
      line1: userinfo.shipping_address.line1,
      line2: userinfo.shipping_address.line2,
      email: userinfo.shipping_address.email,
      country: userinfo.shipping_address.country,
      state: userinfo.shipping_address.state,
      line4: userinfo.shipping_address.line4,
      postcode: userinfo.shipping_address.postcode,
      email: userinfo.shipping_address.email,
      phone_number: userinfo.shipping_address.phone_number,
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    };
    const walletdata = {
      shipping_address:userinfo.shipping_address,
              billing_address:userinfo.billing_address,
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
      email: userinfo.guest_email,
      basket: userinfo.basket,
      wallet: "total",
      wallet_amount: parseFloat(location.state.total),
      status: "ACCEPTED",
      // payment_signature:"ss",
      payment_types: "Credit",
      shipping_method_code: "no-shipping-required",
      // payment_types:"razorpay",
      total_amount: parseFloat(location.state.total),
    };

    thankyoupage(walletdata);
  };

  const thankyoupage = async (orderinfo) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      setuserloader(true);
      let url = `${process.env.REACT_APP_API_URL}my_api/checkout/`;
      const response = await axios.post(url, orderinfo, config);
      console.log("this is the response of ",response);
      if (response.data.number) {
        setuserloader(false);
        if (tokenvalue.msg == "Voucher login") {
          removeToken();

          nvg("/thankyoupagelogin", {
            state: {
              orderid: response.data.number,
            },
          });
        } else {
          nvg("/thankyoupage", {
            state: {
              orderid: response.data.number,
            },
          });
        }
      }
      setuserloader(false);

      //  setloading(false)
    } catch (error) {
console.log("this is error message of voucher check",error.response)
if(error.response.data.detail == "This coupon has already been used"){
  setvouchererror("This Voucher has already been used")
  setuserloader(false);

}

    }
  };  

  useEffect(() => {
    async function fetchcategory() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      setloading(true);
      let url = `${process.env.REACT_APP_API_URL}homepageapi/BasketView`;
      const response = await axios.get(url, config);
      // console.log(
      //   "first full basket view ",
      //   response.data.product_deatils.length
      // );
      if (response.data.product_deatils.length == 0) {
        nvg("/");
      }
    }

    async function walletAmountCheck() {
      // console.log(tokenvalue.access);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${tokenvalue.access}`,
          },
        };
        setuserloader(true);
        let url = `${process.env.REACT_APP_API_URL}my_api/wallet-amount/`;
        const response = await axios.get(url, config);
        // console.log("this is wallet amount", response);
        console.log(response.status);
        if ((response.status = "200")) {
          setwalletamount(response.data.amount);
          setuserloader(false);
          // console.log(response.amount);
          return response.data.amount;
        }

        //  setloading(false)
      } catch (error) {}
    }

    async function creditAmountCheck() {
      // console.log(tokenvalue.access);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${tokenvalue.access}`,
          },
        };
        setuserloader(true);
        let url = `${process.env.REACT_APP_API_URL}credit/total_amount/`;
        const response = await axios.get(url, config);
        // console.log("this is wallet amount", response);
        console.log(response.status);
        if ((response.status = "200")) {
          setcreditamount(response.data.amount);
          setcreditminusamount(response.data.minus_amount)
          setcreditshippingstatus(response.data.shipping_value)
        // settotalcredit(response.data.amount);
          // setuserloader(false);
          // console.log(response.amount);
          // return response.data.amount;
        }

        //  setloading(false)
      } catch (error) {}
    }

    if(getfreeze){
      unfreezestatus(freezeid,keyvalue)
    }

    if(tokenvalue.msg !== "Voucher login"){
      creditAmountCheck();
      walletAmountCheck();
    }
    fetchcategory();
  }, []);
  const [realmobile,setrealmobile] = useState(0)





console.log("ddkdkdkdcc,c,c,c",mobileno)

  useEffect(() => {
    async function fetchcategory() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      setloading(true);
      let url = `${process.env.REACT_APP_API_URL}homepageapi/BasketView`;
      const response = await axios.get(url, config);
      setData(response.data.product_deatils);
      setcartinfo(response.data);
      
      const qtytotal = response.data.product_deatils.reduce(
        (total, item) => total + item.quantity,
        0
      );
      
      console.log("tiis pridct data",qtytotal)
      setqty_total(qtytotal);
      setloading(false);
    }




    async function userinfo() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${process.env.REACT_APP_API_URL}useraccount/clientinfo/`;
      try {
        const response = await axios.get(url, config);
        // console.log("this is ere",response.data)
        if (response.data.UserProfile) {
          setrealmobile(response.data.UserProfile.mobile_number);
          const countryCode = '+91'; // Extracts +91
          // const countryCode = response.data.UserProfile.mobile_number.substring(0, 3); // Extracts +91
         const maskedPart = 'XXXXXX'; // The part to be masked
           const visiblePart = response.data.UserProfile.mobile_number.slice(-4); // Last 4 digits of the number
           console.log("this cdddddis ere",visiblePart)

          setmobileno(`${countryCode}${maskedPart}${visiblePart}`);
        } else {      
          setrealmobile(response.data.ClientDetails.mobile_no);
          const countryCode = "+91"; // Extracts +91
          if (response.data.ClientDetails.mobile_no.startsWith(countryCode)){
 const countryCode2 = response.data.ClientDetails.mobile_no.substring(0, 3); // Extracts +91
 const maskedPart = 'XXXXXX'; // The part to be masked
 const visiblePart = response.data.ClientDetails.mobile_no.slice(-4); // Last 4 digits of the number
 console.log("this cdddddis edddddre",visiblePart)

setmobileno(`${countryCode2}${maskedPart}${visiblePart}`);
          }else{
             // const countryCode = response.data.ClientDetails.mobile_no.substring(0, 3); // Extracts +91
         const maskedPart = 'XXXXXX'; // The part to be masked
         const visiblePart = response.data.ClientDetails.mobile_no.slice(-4); // Last 4 digits of the number
         console.log("this cdddddis edddddre",visiblePart)

        setmobileno(`${countryCode}${maskedPart}${visiblePart}`);
          }
         
       }
      } catch (error) {
      }
    }
    userinfo()
    fetchcategory();
  }, []);

  return (
    <>
      {" "}
      <Header />
      {addloader == false ? (
        userloader == false ? (
          <>
            {/* breadcrumb start */}
            <div className="breadcrumb-main margincart">
              <div className="container m-0">
                <div className="row">
                  <div className="col">
                    <div className="breadcrumb-contain">
                      <div>
                        {/* <ul>
                <li><a href="javascript:void(0)">home</a></li>
                <li>></li>
                <li><a href="javascript:void(0)">checkout</a></li>
              </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* breadcrumb End */}
            {/* section start */}
            <section className="section-big-py-space b-g-light">
              <div className="custom-container">
                <div className="checkout-page contact-page">
                  <div className="checkout-form">
                    <div
                      className="row justify-content-center"
                      style={{ gap: "39px" }}
                    >
                      <div className="col-lg-6 col-sm-12 col-xs-12 d-flex">
                        <div
                          className="row theme-form"
                          style={{ alignItems: "stretch" }}
                        >
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 p-0" style={{display:'none'}}>
                                {tokenvalue.msg == "Voucher login" ? (
                                  ""
                                ) : (
                                  <>
                                    {" "}
                                    <h5
                                      style={{
                                        padding: "5px 2px",
                                        display:
                                          paymethod == "COD" ? "none" : "",
                                      }}
                                    >
                                      Oneup Wallet
                                    </h5>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        display:
                                          paymethod == "COD" ? "none" : "flex",
                                      }}
                                    >
                                      {iswallet == true ? (
                                        <input
                                          type="checkbox"
                                          className=""
                                          style={{
                                            width: "20px",
                                            height: "18px",
                                            position: "relative",
                                          }}
                                          checked
                                          onChange={() => {
                                            setiswallet(!iswallet);
                                          }}
                                          name=""
                                          id=""
                                        />
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className=""
                                          style={{
                                            width: "20px",
                                            height: "18px",
                                            position: "relative",
                                          }}
                                          onChange={() => {
                                            setiswallet(!iswallet);
                                          }}
                                          name=""
                                          id=""
                                        />
                                      )}
                                      <span
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {/* <span> Oneup Wallet &nbsp;</span> */}
                                        <span>
                                       
                        
                        
                        Your current balance is ₹
                                          {walletamount}
                                        </span>
                                      </span>
                                      {iswallet == false ? (
                                        ""
                                      ) : (
                                        <input
                                          type="number"
                                          name=""
                                          className="form-control hidemobile"
                                          value={paywalletamount}
                                          style={{
                                            width: "40%",
                                            fontSize: "13px",
                                            marginLeft: "auto",
                                          }}
                                          onChange={handleInputChange}
                                          placeholder="Enter Amount"
                                          id=""
                                        />
                                      )}

                                      {/* {paywalletamount} */}
                                    </div>
                                    <div>
                                      {iswallet == false ? (
                                        ""
                                      ) : (
                                        <input
                                          type="number"
                                          name=""
                                          className="form-control hidewindow"
                                          value={paywalletamount}
                                          style={{
                                            width: "100%",
                                            fontSize: "13px",
                                            marginLeft: "auto",
                                          }}
                                          onChange={handleInputChange}
                                          placeholder="Enter Amount"
                                          id=""
                                        />
                                      )}
                                    </div>
                                    {paymethod == "COD" ? "" : <hr />}
                                  </>
                                )}
                                {/* <h5 style={{ padding: "5px 2px" }}>
                                  Select Payment Mode
                                </h5> */}
                              </div>



                              {/* credit balance is here  */}









                              <div className="col-12 p-0">
                                {tokenvalue.msg == "Voucher login" ? (
                                  ""
                                ) : (
                                  <>
                                    {" "}
                                    <h5
                                      style={{
                                        padding: "5px 2px",
                                        display:
                                          paymethod == "COD" ? "none" : "",
                                      }}
                                    >
                                      Oneup Credit  ddd
                                       {/* <button
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "#fff",
                            padding: "3px 3px",
                            color: "#230BB3",
                            width: "fit-content",
                            fontWeight: 600,
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModaladdressone2"
                        >
                          credit
                        </button> */}
                                    </h5>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        display:
                                          paymethod == "COD" ? "none" : "flex",
                                      }}
                                    >
                                      {iscredit == true ? (
                                        <input
                                          type="checkbox"
                                          className=""
                                          style={{
                                            width: "20px",
                                            height: "18px",
                                            position: "relative",
                                          }}
                                          checked
                                          onChange={() => {
                                            setiscredit(!iscredit);
                                          }}
                                          name=""
                                          id=""
                                        />
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className=""
                                          style={{
                                            width: "20px",
                                            height: "18px",
                                            position: "relative",
                                          }}
                                          onChange={() => {
                                            setiscredit(!iscredit);
                                          }}
                                          name=""
                                          id=""
                                        />
                                      )}
                                      <span
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {/* <span> Oneup Wallet &nbsp;</span> */}
                                        <span>
                                          Your current balance is ₹
                                          {creditamount}
                                        </span>
                                      </span>
                                      {iscredit == false ? (
                                        ""
                                      ) : (
                                        <input
                                          type="tel"
                                          name=""
                                          className="form-control hidemobile"
                                          value={paycreditamount}
                                          style={{
                                            width: "40%",
                                            fontSize: "13px",
                                            marginLeft: "auto",
                                          }}
                                          maxLength={creditamount}
                                          onChange={handleInputChange2}
                                          placeholder="Enter Amount "
                                          id=""
                                        /> 
                                      )}
                                      
                                      {/* {paywalletamount} */}
                                    </div>
<span className="hidemobile" style={{textAlign:'end',color:'#230bb3',fontWeight:'500',position:'relative',top:'3px'}}>{creditgst.status == true ? creditgst.message : ''}</span>







                                    <div>
                                      {iscredit == false ? (
                                        ""
                                      ) : (
                                        
                                        <input
                                          type="tel"
                                          name=""
                                          className="form-control hidewindow"
                                          value={paycreditamount}
                                          style={{
                                            width: "100%",
                                            fontSize: "13px",
                                            marginLeft: "auto",
                                          }}
                                          maxLength={creditamount}
                                          onChange={handleInputChange2}
                                          placeholder="Enter Amount "
                                          id=""
                                        />
                                      )}
                                    </div>
<span className="hidewindow" style={{textAlign:'end',color:'#230bb3',fontWeight:'500',position:'relative',top:'3px'}}>{creditgst.status == true ? creditgst.message : ''}</span>
<div></div>



                                    
                                    {paymethod == "COD" ? "" : <hr />}
                                  </>
                                )}
                                <h5 style={{ padding: "5px 0px 5px 2px" }}>
                                  Select Payment Mode
                                </h5>
                              </div>













                              <div
                                className="col-xl-12 col-sm-12 d-flex p-0"
                                // className="col-xl-4 col-sm-12 d-flex border p-0"
                                style={{height:'fit-content',gap:'6px' }}
                              >
                                {/* <div className="row" style={{width:'100%'}}> */}
                                <div
                                  className={"tabdes col-xl-4 col-md-6"}
                                  onClick={() => {
                                    checkPaymentMethod("Online");
                                  }}
                                  style={{
                                    padding: "10px",
                                    fontSize: "14px",
                                    // color:
                                    //   paymethod == "Online" ||
                                    //   paymethod == "Online/wallet"
                                    //     ? "#fff"
                                    //     : "#333",
                                    color:'#230bb3',
                                    fontWeight: "550",
                                    textAlign: "center",
                                    background:'#fff',
                                    borderColor:
                                      paymethod == "Online" ||
                                      paymethod == "Online/wallet"
                                        ? "#230bb3"
                                        : "#fff",
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "start",
                                    borderWidth:'1px',
                                    borderStyle:'solid',
                                    alignItems: "start",
                                    flexDirection:'column',
                                    height: "40%",
                                  }}
                                >
<span>

<img
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/cash.png`}
            // src={`http://localhost:3000/images/cash.png`}
            className="img-fluid"
            style={{ width:'73%' }}
            alt="logo"
            />
            </span>
          {/* <br /> */}
          <span>
                                  Online Pay

          </span>
                                </div>
                                <div
                                  // className="tabdes col-4"
                                  className={"tabdes col-xl-4 col-md-6"}
                                  // onClick={() => {
                                  //   setpaymethod("COD");
                                  //   setiswallet(false);
                                  // }}
                                  onMouseEnter={()=>{setoutstock(true)}}
                                  onMouseLeave={()=>{setoutstock(false)}}
                                  style={{
                                    padding: "10px",
                                    fontSize: "14px",
                                    color: paymethod == "COD" ? "#fff" : "#333",
                                    fontWeight: "550",
                                    display:'flex',
                                    // textAlign: "center",
                                    backgroundColor:
                                      paymethod == "COD" ? "#230bb3" : "#fff",
                                    cursor: "pointer",
                                    justifyContent: "start",
                                    borderWidth:'1px',
                                    borderStyle:'solid',
                                    alignItems: "start",
                                    flexDirection:'column',
                                    height: "40%",
                                  }}
                                >
<span>

<img
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/online.png`}
            // src={`http://localhost:3000/images/online.png`}
            className="img-fluid"
            style={{ width:'73%' }}
            alt="logo"
            />
            </span>
          {/* <br /> */}
          <span style={{color:outstock == true ? "red" : ''}}>

                                {outstock == true ? "Not Available" : "Cash on Delivery "}  
          </span>
                                </div>
                                {/* {tokenvalue.msg == "Voucher login" ? '' : <div
                          className="tabdes col-4"
                          onClick={() => {
                            checkPaymentMethod("wallet")
                          }}
                          style={{
                            padding: "10px",
                            fontSize: "14px",
                            color: paymethod == "wallet" || paymethod == "Online/wallet"? "#fff" : "#333",
                            fontWeight: "550",
                            textAlign:'center',
                            backgroundColor:
                              paymethod == "wallet" || paymethod == "Online/wallet" ? "#230bb3" : "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Pay From Wallet
                        </div>}  */}
                                {/* </div> */}
                              </div>
                              <div className="col-xl-8 col-sm-12 px-0">
                                <div className="paycontainer">
                                  {/* <h6
                                    style={{
                                      color: "#333",
                                      padding: "7px 8px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {paymethod == "COD"
                                      ? "Cash on Delivery"
                                      : paymethod == "wallet"
                                      ? "Wallet Pay"
                                      : "Online Pay"}
                                  </h6> */}

                                  <div
                                    className=""
                                    style={{ padding: "7px 0px" }}
                                  >
                                    <p
                                      style={{
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      By placing this order, you agree to Our
                                      <span
                                        style={{ cursor: "pointer",fontWeight:'500' }}
                                        onClick={() => {
                                          nvg("/termsconditions");
                                        }}
                                      >
                                        {" "}
                                        {/* ONE UP BRANDS T&C */}
                                        T&C </span>
                                       and
                                      <span
                                        style={{ cursor: "pointer",fontWeight:'500' }}
                                        onClick={() => {
                                          nvg("/privacypolicy");
                                        }}
                                      >
                                        {" "}
                                        {/* ONE UP BRANDS T&C */}
                                        {/* PRIVACY POLICY */}
                                         Privacy Policy
                                      </span>
                                    </p>
                                    {paymethod == "COD" ? (
                                      <button
                                        type="button"
                                        onClick={checkoutform}
                                        style={{ fontSize: "12px" }}
                                        className="btn-normal btn"
                                      >
                                        Place Order 
                                      </button>
                                    ) : paymethod == "wallet" ? (
                                      <button
                                        type="button"
                                        onClick={walletpay}
                                        style={{ fontSize: "12px" }}
                                        className="btn-normal btn"
                                      >
                                        Place Order 
                                      </button>
                                    ) : paymethod == "Online" && iswallet == true ? (
                                      <button
                                        type="button"
                                        onClick={Onlinepay}
                                        style={{ fontSize: "12px" }}
                                        className="btn-normal btn"
                                      >
                                        Place Order 
                                      </button>
                                    ) : paymethod == "Online" && iscredit == true ? (
                                       paycreditamount == 0 || Math.round(location.state.total) < paycreditamount ? <button
                                        type="button"
                                        //  data-bs-toggle="modal"
                          // data-bs-target="#exampleModaladdressone2"
disabled
                                        // onClick={()=>{sendotp()}}
                                        // disabled={paycreditamount == 0 || Math.round(location.state.total) < paycreditamount ? true : false}
                                        style={{ fontSize: "12px" }}
                                        className="btn-normal btn"
                                      >
                                        Place Order 
                                      </button> : <button
                                        type="button"
                                         data-bs-toggle="modal"
                          data-bs-target="#exampleModaladdressone2"

                                        // onClick={()=>{onlineAndCod2()}}
                                        onClick={()=>{sendotp()}}
                                        // disabled={paycreditamount == 0 || Math.round(location.state.total) < paycreditamount ? true : false}
                                        style={{ fontSize: "12px" }}
                                        className="btn-normal btn"
                                      >
                                        Place Order 
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={Onlinepay}
                                        // disabled={paywalletamount == 0 || Math.round(location.state.total) < paywalletamount ? true : false}
                                        style={{ fontSize: "12px" }}
                                        className="btn-normal btn"
                                      >
                                        Place Order 
                                      </button>
                                    )}
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12 col-xs-12">
                        <div className="checkout-details theme-form ">
                          <div className="order-box">
                            <div
                              className="title-box"
                              style={{ border: "none" }}
                            >
                              <div>
                                <h5>Order Summary</h5>
                              </div>
                            </div>
                            <div className="title-box">
                              {/* <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  color: "#1d1c1c",
                                }}
                              >
                                Product <span>Total</span>
                              </div> */}
                            </div>
                            {/* <ul className="qty">
                              {data[0]?.product__title
                                ? data.map((item, index) => (
                                    <li
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {item.product__title.length > 12
                                        ? `${item.product__title.substring(
                                            0,
                                            12
                                          )}...`
                                        : item.product__title}{" "}
                                      × {item.quantity}{" "}
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        ₹
                                        {(
                                          item.quantity * item.price_excl_tax
                                        ).toFixed(0)}
                                      </span>
                                    </li>
                                  ))
                                : ""}
                            </ul> */}
                            <ul className="sub-total">
                              <li
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  color: "#1d1c1c",
                                }}
                              >
                                {qty_total} Item(s){" "}
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                  className="count"
                                >
                                  {/* ₹{cartinfo.balance_payable} */}
                                  ₹{(location.state.subtotal).toFixed(0)}
                                </span>
                              </li>
                              <li
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  color: "#1d1c1c",
                                }}
                              >
                                Shipping{" "}
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                  className="count"
                                >
                                  ₹{shippingcharnge?.shipping_price}
                                  {/* {0.0} */}
                                </span>
                              </li>
                            </ul>
                            <ul className="sub-total">
                              <li
                                style={{
                                  fontWeight: "600",
                                  fontSize: "12px",
                                  color: "#1d1c1c",
                                }}
                              >
                                Order Total{" "}
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                  className="count"
                                >
                                  ₹
                                  {/* {Number(
                                    parseFloat(cartinfo.balance_payable) +
                                      (parseFloat(shippingcharnge?.shippingstatus == true ? 0 : shippingcharnge?.shipping_price))
                                  ).toFixed(0)} */}
                                  {location.state.total}
                                  {/* ₹{cartinfo.balance_payable + shippingcharnge?.shipping_price} */}
                                </span>
                              </li>
                            </ul>
                              <li>
                                <span style={{color:'red'}}>{vouchererror}</span>
                              </li>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>





            <div
            class="modal fade"
            id="exampleModaladdressone2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog newmargin3" style={{maxWidth: "570px" }}>
              <div class="modal-content">
                {/* <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Add Address
                  </h1>
                  <button
                    type="button"
                    ref={closeaddressbtn}
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div> */}
                <div class="modal-body">
                 
                    
                        <div className="row check-out justify-content-center">
                         <div className="col-12 d-flex py-3 justify-content-center">
                          <h4 style={{fontSize:'22px'}}>Verify OTP</h4>
                         </div>
                          <div className="form-group col-md-11 col-sm-11 col-xs-11">
                            <label className="acounttitle m-0 pb-1">
                              OTP has Been Send to Your {mobileno}
                              {/* +91XXXXXX9471 */}
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='First Name' /> */}

                            <input
                              type="text"
                              name="first_name"
                              className="form-control"
                              style={{ fontSize: "12px",height:'40px' }}
                              placeholder="Enter Your OTP"
                              value={otp}
                              onChange={(e)=>{setotp(e.target.value)}}
                             
                            />
                            {/* <div className="error">
                              {errors.first_name && touched.first_name ? (
                                <p style={{ color: "red" }}>
                                  {errors.first_name}
                                </p>
                              ) : null}
                            </div> */}
                          </div>

                          <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                            <div className="payment-box">
                              <div
                                className="text-left"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "4px",
                                }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    fontSize: "12px",
                                    background: "#bdbfc0",
                                    color: "#000",
                                  }}
                                  className="btn-normal btn"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  ref={buttonRef}
                                >
                                  Cancel
                                </button>
                               {otp.length == 6 ? <button
                                  type="submit"
                                  onClick={()=>{console.log("first open state");verifyotp()}}
                                  style={{ fontSize: "12px" }}
                                  className="btn-normal btn"
                                >
                                  Verify
                                </button> : <button
                                  type="submit"
                                  disabled
                                  style={{ fontSize: "12px" }}
                                  className="btn-normal btn"
                                >
                                  Verify
                                </button>} 
                              </div>
                            </div>
                          </div>

                         
                        </div>
                    
                    
                </div>
                {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
              </div>
            </div>
          </div>



          </>
        ) : (
          <>
            <div
              style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  paddingTop: "15px",
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                <Loader />
              </h2>
            </div>
          </>
        )
      ) : (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                paddingTop: "15px",
                fontWeight: "600",
                fontSize: "20px",
              }}
            >
              <Loader />
            </h2>
          </div>
        </>
      )}
    </>
  );
};
export default Pay;
