import Footer from "../components/Footer";
import Loginheader from "../components/Loginheader";
import "../css/login.css";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
const Verifyotp = () => {
  const nvg = useNavigate();
  const { userId } = useParams();
  const match = userId.match(/user-id=(\d+)/);
  const userIdvalue = match ? match[1] : null;
  const [otperror, setotperror] = useState("");
  const [otp, setotp] = useState("");
  const [switchfrom, setswitchfrom] = useState(0);
  const [cpassword, setcpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [matchpass, setmatchpass] = useState(false);

  const [loading, setloading] = useState(false);
  const [clientloginerrors, setclientloginerrors] = useState({});
  const [clientloginsuccess, setclientloginsuccess] = useState({});
  const [voucherloginerror, setvoucherloginerror] = useState(null);

  // verifyotp register form start here
  const verifyotpfun = async (e) => {
    e.preventDefault();
    setloading(true);
    const formdata = new FormData();
    formdata.append("OTP", otp);

    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/verify-OTP/${userIdvalue}/`;
      const response2 = await axios.post(url, formdata);
      console.log("show verify otp response", response2);
      if (response2.data.verified == true) {
        setswitchfrom(1);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      setotperror("Invalid OTP")

    }
  };
  // verifyotp register form end here

  // verifyotp register form start here
  const changepassword = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("password", cpassword);
    formdata.append("confirm_password", confirmpassword);
if(cpassword == confirmpassword){
    setloading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/reset-password/${userIdvalue}/`;
      const response2 = await axios.post(url, formdata);
      if (response2.data[0] == "Password updated successfully!") {
        nvg("/");
      }
      setloading(false);
    } catch (error) {
      setloading(false);
    }
}else{
    setmatchpass(true)
}
  };
  // verifyotp register form end here

  return (
    <>
      {/* <Loginheader /> */}

      <div
        className={
          loading == true
            ? "d-flex align-items-center justify-content-center"
            : "d-none"
        }
        style={{ position: "fixed", width: "100vw", height: "100vh" }}
      >
        {" "}
        {loading == true ? <Loader /> : ""}
      </div>

      {/*header end*/}
      <section
        className="login-page bg-body pt-5"
        style={{ width: "100%",height:'98vh' }}
      >
        <div
          className="container pt-lg-5 pt-0"
          style={{
            background: "white",
            // marginTop: "50px",
            paddingBottom: "76px",
          }}
        >
          <div className="row align-items-center g-5 ">
            <div
              className="col-10 col-sm-8 col-lg-6"
              style={{ margin: "0 auto" }}
            >
              <img
                src="https://oneupv1.stackerbee.com/images/login-img.png"
                className="d-block mx-lg-auto img-fluid"
                alt="img"
                width={500}
                height={500}
                loading="lazy"
              />
            </div>

            {/* <div className="col-lg-6" style={{overflowY:"clip"}}> */}
            <div className="col-lg-6">
              <div className="container newpadding hidepadding">
                <div className="card p-1 " style={{ border: "none" }}>
                  <img
                    onClick={()=>{nvg('/')}}
                    src="https://dev.oneupbrands.com/images/logo.png"
                    className="login-tabs-img"
                    style={{marginBottom:'0px',cursor:'pointer',maxWidth:'320px'}}
                    alt
                  />
             
                  <div className="tab-content p-2 " id="nav-tabContent">
                    <div
                      className={
                        switchfrom == 0
                          ? "tab-pane active fade show"
                          : "tab-pane fade show"
                      }
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <div className="album py-1">
                        <form
                          autoComplete="off"
                          className="form-login"
                          style={{height:'fit-content'}}
                          onSubmit={verifyotpfun}
                        >
                          <label htmlFor="text">OTP</label>

                          <input
                            type="tel"
                            name="otp"
                            className="login-input mb-0"
                            placeholder="Enter your OTP"
                            value={otp}
                            onChange={(e) => {
                              setotp(e.target.value.replace(/\D/g, ''));
                            }}
                            // onChange={(e) => {
                            //   setotp(e.target.value);
                            // }}
                          />
                          <span
                            style={{ display: "block", marginBottom: "20px" }}
                          >
                            {" "}
                            {otperror ? (
                              <p style={{ color: "red" }}>{otperror}</p>
                            ) : null}
                          </span>

                          {otp == "" || otp == undefined ? (
                            <button
                              type="submit"
                              disabled
                              className="btn"
                              id="myForm"
                            >
                              Verify OTP
                            </button>
                          ) : (
                            <button type="submit" className="btn" id="myForm">
                              Verify OTP
                            </button>
                          )}
                          <span
                            style={{
                              color: "red",
                              textAlign: "center",
                              display: "block",
                              marginTop: "3px",
                              fontSize: "15px",
                            }}
                          >
                            {clientloginerrors ? clientloginerrors.msg : ""}
                          </span>
                          <span
                            style={{
                              color: "green",
                              textAlign: "center",
                              display: "block",
                              marginTop: "3px",
                              fontSize: "15px",
                            }}
                          >
                            {clientloginsuccess ? clientloginsuccess.msg : ""}
                          </span>
                        </form>
                      </div>
                    </div>

                    <div
                      className={
                        switchfrom == 1
                          ? "tab-pane active fade show"
                          : "tab-pane fade show"
                      }
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div className="album py-1">
                        <form
                          autoComplete="off"
                          className="form-login"
                          style={{height:'fit-content'}}
                          onSubmit={changepassword}
                        >
                          <label htmlFor="text">Password</label>
                          <div className="log-sendotp">
                            <input
                              type="password"
                              name="vouchercode"
                              className="login-input mb-0"
                              placeholder="Password"
                              style={{ paddingRight: "50px" }}
                              value={cpassword}
                              onChange={(e) => {
                                setcpassword(e.target.value);
                              }}
                            />
                          </div>

                          <label htmlFor="text" style={{paddingTop:"7px"}}>Confirm Password</label>
                          <div
                            className="log-sendotp"
                            style={{ marginBottom: "8px" }}
                          >
                            <input
                              type="password"
                              name="confirmpassword"
                              className="login-input mb-0"
                              placeholder="Confirm Password"
                              style={{ paddingRight: "50px" }}
                              value={confirmpassword}
                              onChange={(e) => {
                                setconfirmpassword(e.target.value);
                              }}
                            />
                          </div>

                          {cpassword == "" || confirmpassword == "" ? (
                            <button
                              type="submit"
                              disabled
                              className="btn"
                              id="myForm"
                            >
                              Save
                            </button>
                          ) : (
                            <button type="submit" className="btn" id="myForm">
                              Save
                            </button>
                          )}
                          <span
                            style={{
                              color: "red",
                              textAlign: "center",
                              display: "block",
                              marginTop: "3px",
                              fontSize: "15px",
                              textTransform: "capitalize",
                            }}
                          >
                            {matchpass == true ? 'password and confirm password do not match' : ""}
                          </span>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section style={{ marginTop: "50px" }}>
        <div className="container">
          <div className="row">
            <h2 className="login-about customline3">
              About One<span className="ab-1">Up</span>
              <span className="ab-2">Brands</span>
            </h2>
            <p className="login-para">
              Lorem ipsum dolor sit amet consectetur. Sit augue maecenas mattis
              senectus quam eu. Aenean consequat pretium vitae egestas turpis
              aliquam. Egestas et porttitor vitae volutpat aliquet netus
              volutpat. Netus auctor dui mattis vulputate posuere sagittis
              fermentum convallis. Porta cras risus quis dictumst velit.
              Interdum amet accumsan nullam porta facilisi. Malesuada tincidunt
              tempor venenatis duis. Et enim enim dictum varius consequat nunc
              condimentum. Id eu viverra purus vestibulum accumsan rhoncus
              tincidunt commodo. Ornare porttitor sit ornare consequat sed nunc
              aliquam leo sollicitudin. Proin sapien arcu nunc duis. Enim quam
              velit gravida feugiat consequat purus nam ultrices. Viverra mollis
              volutpat in consectetur venenatis sodales ut placerat dis.
            </p>
          </div>
        </div>
      </section>
      <Footer /> */}
      {/* footer end */}
    </>
  );
};
export default Verifyotp;
