import React, { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { Register2val } from "../Validation/register2val";
import axios from "axios";
import { Register3val } from "../Validation/register3val";
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';

const Register3 = ({secondform,changestep,step2id,buttonReftwo}) => {
  const [verifystatus_gst, setverifystatus_gst] = useState(0);
  const [gstData, setgstData] = useState({});
  const [mesgforerror, setmesgforerror] = useState("");
  const [companytype, setcompanytype] = useState("LLP");
  const [companytype2, setcompanytype2] = useState("Manufacturer");
  const [currentStep, setCurrentStep] = useState(1);

  const autofillvaluetwo = (setFieldValue,step1idcopy) =>{
console.log("this is form next fun",step1idcopy)
setTimeout(() => {
  if(step1idcopy.first_company_type){
    setFieldValue('companytype',step1idcopy.first_company_type)
  }
  if(step1idcopy.industry_type){
    setFieldValue('companytype2',step1idcopy.second_company_type)
  }
  // if(step1idcopy.onboarding_gst_verify){
  //   setFieldValue('postalcode',step1idcopy.onboarding_gst_verify)
  // }
if(step1idcopy.second_company_type){
  setFieldValue('industrytype',step1idcopy.industry_type)
}

if(step1idcopy.website_link){
  setFieldValue('weblink',step1idcopy.website_link)
}
}, 300);
    
 
  }

  const nvg = useNavigate();

  return (
    <div style={{ backgroundColor: "rgb(249 249 249)",padding:'10px 0px'}}>
      <div
        className="fullwidth profileregister align-items-stretch"
        style={{ backgroundColor: "rgb(249 249 249)", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <div className="min-width stickyimage">
          <img
            src="./images/side.png"
            style={{
              marginBottom: "0px",
              cursor: "pointer",
              width: "100%",
              // height: "90vh",
              objectFit: "contain",
              position: "relative",
              top: "20px",
            }}
            alt
          />
        </div>
        <div className="min-width2">
          <div className="topsection">
            {/* </div>
          <div className="formsection topsection"> */}

            <Formik
              initialValues={{
                companytype: "LLP",
                companytype2: "Manufacturer",
                industrytype: "Ecommerce",
                weblink: "",
              }}
              validationSchema={Register3val}
              onSubmit={(values, { setFieldValue }) => {
                secondform(values)
              }}
            >
              {({
                values,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                dirty,
                isValid,
              }) => (
                <Form
                  autoComplete="off"
                  className={`form-login container-fluid mt-2`}
                  style={{ width: "100%", minHeight: "90vh", height: "auto" }}
                  onSubmit={handleSubmit}
                >
                  <div className="row px-4">
                    <div className="col-xl-10 col-md-12 extrast">
                      {/* <img
                        src="./images/figmaimage8.png"
                        style={{
                          marginBottom: "0px",
                          cursor: "pointer",
                          width: "121%",
                          objectFit: "contain",
                          // position: "relative",
                          // top: "20px",
                        }}
                        alt
                      /> */}
                        <h5><span style={{color:'#9f9f9f',fontWeight:'500',position:'relative',left:'2px'}}> Step</span> 2/4 <span> <img src="./images/vector.png" style={{width:'15.5px',marginLeft:'5px'}} alt="" /> </span><span style={{color:'#3822ba'}}> Business Profile</span></h5>
                        <StepProgressBar
startingStep={currentStep}
  onSubmit={()=>{console.log("first")}}
  steps={[
    {
      name: 'step 1',
    },
    {
      name: 'step 2',
    },
    {
      name: 'step 3',
    },
    {
      name: 'step 4',
    }
  ]}primaryBtnClass="#230BB3" />
                    </div>
                    <div className="col-12">
                      <h4 className="title098">
                      Please Verify Your Business Details
                      </h4>
                    </div>
                    <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                        Please Select Your Company Type <span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp d-flex py-2" style={{gap:'10px',flexWrap:'wrap'}}>
                       <div className="smallbox" onClick={()=>{setFieldValue('companytype','LLP')}} style={{backgroundColor:values.companytype == "LLP" ? "#230BB3" : '',color:values.companytype == "LLP" ? "#fff" : '',}}>
                        LLP</div> 
                       <div className="smallbox" onClick={()=>{setFieldValue('companytype','Private Limited')}} style={{backgroundColor:values.companytype == "Private Limited" ? "#230BB3" : '',color:values.companytype == "Private Limited" ? "#fff" : '',}}>
                       Private Limited</div> 
                       <div className="smallbox" onClick={()=>{setFieldValue('companytype','Partnership')}} style={{backgroundColor:values.companytype == "Partnership" ? "#230BB3" : '',color:values.companytype == "Partnership" ? "#fff" : '',}}>
                       Partnership</div> 
                       <div className="smallbox" onClick={()=>{setFieldValue('companytype','Proprietary')}} style={{backgroundColor:values.companytype == "Proprietary" ? "#230BB3" : '',color:values.companytype == "Proprietary" ? "#fff" : '',}}>
                       Proprietary</div> 
                        
                      </div>

                      <span
                        style={{
                          display: "block",
                          marginBottom: "20px",
                        }}
                      >
                        {verifystatus_gst == 2 ? (
                          <p
                            style={{
                              color: "red",
                              display: values.gstno == "" ? "none" : "block",
                            }}
                          >
                            {mesgforerror}
                            {/* <img src="./images/question.png" alt="404" /> */}
                          </p>
                        ) : null}
                      </span>
                    </div>
                    <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                       Please Select Your Company Type <span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp d-flex py-2" style={{gap:'10px',flexWrap:'wrap'}}>
                       <div className="smallbox" onClick={()=>{setFieldValue('companytype2','Manufacturer')}} style={{backgroundColor:values.companytype2 == "Manufacturer" ? "#230BB3" : '',color:values.companytype2 == "Manufacturer" ? "#fff" : '',}}>
                        Manufacturer</div> 
                       <div className="smallbox" onClick={()=>{setFieldValue('companytype2','Distributor')}} style={{backgroundColor:values.companytype2 == "Distributor" ? "#230BB3" : '',color:values.companytype2 == "Distributor" ? "#fff" : '',}}>
                       Distributor</div> 
                       <div className="smallbox" onClick={()=>{setFieldValue('companytype2','Retailer')}} style={{backgroundColor:values.companytype2 == "Retailer" ? "#230BB3" : '',color:values.companytype2 == "Retailer" ? "#fff" : '',}}>
                       Retailer</div> 
                       <div className="smallbox" onClick={()=>{setFieldValue('companytype2','Service Provider')}} style={{backgroundColor:values.companytype2 == "Service Provider" ? "#230BB3" : '',color:values.companytype2 == "Service Provider" ? "#fff" : '',}}>
                       Service Provider</div> 
                       <div className="smallbox" onClick={()=>{setFieldValue('companytype2','Other')}} style={{backgroundColor:values.companytype2 == "Other" ? "#230BB3" : '',color:values.companytype2 == "Other" ? "#fff" : '',}}>
                       Other</div> 
                        
                      </div>

                      <span
                        style={{
                          display: "block",
                          marginBottom: "20px",
                        }}
                      >
                        {verifystatus_gst == 2 ? (
                          <p
                            style={{
                              color: "red",
                              display: values.gstno == "" ? "none" : "block",
                            }}
                          >
                            {mesgforerror}
                            {/* <img src="./images/question.png" alt="404" /> */}
                          </p>
                        ) : null}
                      </span>
                    </div>
                  <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                        Please Select Your Industry Type <span style={{color:'red'}}>*</span>
                      </label>
                      <div className="log-sendotp">
                      <Field name="industrytype" className="form-control" style={{borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderColor:'rgba(143, 144, 145, 1)',color:'#bababa',outline:'none',shadow:'none'}} as="select">
            <option value="Ecommerce">Ecommerce</option>
            <option value="Agriculture">Agriculture</option>
            <option value="Education">Education</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Construction">Construction</option>
            <option value="Transportation">Transportation</option>
          </Field>
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.industrytype && touched.industrytype ? (
                          <p style={{ color: "red" }}>{errors.industrytype}</p>
                        ) : null}
                      </span>
                      {/* <span
                        style={{
                          display: "block",
                          marginBottom: "20px",
                        }}
                      >

                        {verifystatus_gst == 2 ? (
                          <p
                            style={{
                              color: "red",
                              display: values.emailotp == "" ? "none" : "block",
                            }}
                          >
                            Incorrect OTP, Try Again{" "}
                            <img src="./images/question.png" alt="404" />
                          </p>
                        ) : null}
                      </span> */}
                    </div> 


                    <div style={{position:'relative',overflow:'hidden'}}>
                      <button type="button" ref={buttonReftwo} style={{position:'absolute',visibility:'hidden',top:'0px',left:'0px'}} onClick={()=>{autofillvaluetwo(setFieldValue,step2id)}} >dd</button>
</div>
                  <div className="col-xl-12 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px" }}
                        htmlFor="text"
                      >
                        Please Provide Your Business Website If any 
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="text"
                          name="weblink"
                          className="login-input mb-0"
                          placeholder={`Enter Your Website Link`}
                          value={values.weblink}
                          style={{
                            borderBottom:
                              errors.weblink && touched.weblink
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            setverifystatus_gst(0);
                            setFieldValue("weblink", e.target.value);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.weblink && touched.weblink ? (
                          <p style={{ color: "red" }}>{errors.weblink}</p>
                        ) : null}
                      </span>
                    
                    </div> 
  <div className="col-xl-12 col-md-12 d-flex justify-content-end py-4" style={{gap:'4px'}}>
                      <button
                        type="button"
                        // disabled={!(isValid && dirty)}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content',backgroundColor:'#a09e9e'}}
                        onClick={()=>{changestep(0)}}
                      >
                        Back
                        {/* <span>
                          <FaArrowRightLong color="white" />
                        </span> */}
                      </button>
                      <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        className="btn"
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Next
                        <span>
                          <FaArrowRightLong color="white" />
                        </span>
                      </button>
                    </div>
                   
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register3;
