import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Voucherlogin } from "../Validation/Voucherlogin";
import { useNavigate } from "react-router-dom";
import { Register1val } from "../Validation/register1val";
import axios from "axios";
import Gstform from "../components/Gstform";
import Alternativeform from "../components/Alternativeform";
import Gstformtwo from "../components/Gstformtwo";
import { data } from "jquery";
import { Register6val } from "../Validation/register6val";
import Alternativeformtwo from "../components/Alternativeformtwo";

const Register1 = () => {
  const [showotps, setshowotps] = useState(false);
  const [showotp, setshowotp] = useState(false);
  const closebtn = useRef(null)
  const [documents,setdocuments] = useState('')
  console.log("documents is heere",documents);
  const [termandcondition, settermandcondition] = useState(false);
  const [verifystatus_gst, setverifystatus_gst] = useState(0);
  const [mesgforerror, setmesgforerror] = useState("");
  const [unregisterdealer, setunregisterdealer] = useState(false);
  const [showotherform, setshowotherform] = useState(false);
  const [cityna,setcityna] = useState("")
  const [showmobile, setshowmobile] = useState(false);
  const [loading, setloading] = useState(false);
  const [verifystatus, setverifystatus] = useState(0);
  const [verifystatus_mobile, setverifystatus_mobile] = useState(0);
  const [verifystatus_email, setverifystatus_email] = useState(0);
  const [verifyotpstatus, setverifyotpstatus] = useState(0);
  const [voucherloginerror, setvoucherloginerror] = useState(null);
const openalert = useRef(null)
  const verifycode = () => {};


  const makeitunregister = (setFieldValue)=>{
    setunregisterdealer(!unregisterdealer)
    setFieldValue("GSTIN",'')
  }



const imagedec = (currentvalue,values) =>{
  // console.log("this is fist vaoue",currentvalue.target.value,"aur value",values)
  
  if(currentvalue.target.value  == "Proprietary" || currentvalue.target.value  == "Partnership"){
    setdocuments("pan,adhaar")
    setunregisterdealer(true)
  }else{
    setunregisterdealer(false)
    setdocuments("pan,gst,msme,authorization,corporation")
  }
//   if(values.GSTIN == ""){
//     setdocuments("pan,adhaar")
//   }else{
// if(currentvalue.target.value == "Proprietary"){
//   setdocuments("pan,adhaar,gst,msme")
// }else{
//   if(currentvalue.target.value == "Partnership"){
//     setdocuments("pan,adhaar,gst,msme,register,authorization")
//   }else{
//     setdocuments("pan,gst,msme,authorization,corporation")
//   }
// }
//   }


}
const imagedec2 = (currentvalue,values) =>{
  // console.log("this is fist vaoue",currentvalue.target.value,"aur value",values)
  if(currentvalue == ""){
    setdocuments("pan,adhaar")
  }else{
if(values.companytype == "Proprietary"){
  setdocuments("pan,adhaar,gst,msme")
}else{
  if(values.companytype == "Partnership"){
    setdocuments("pan,adhaar,gst,msme,register,authorization")
  }else{
    setdocuments("pan,gst,msme,authorization,corporation")
  }
}
  }
}

const [statusstep5, setstatusstep5] = useState("");

const changeotp = (setFieldValue,setFieldTouched) =>{
  setFieldValue("emailotp",34323)
  setFieldValue("mobileotp",84823)
  setFieldTouched("emailotp",true)
  setFieldTouched("mobileotp",true)
}


const fourthform = async(values) =>{

  setloading(false)
  


  const formdata = new FormData()

  let jsonData = {
    business: {
        primary_contact_person:values.contactperson,
        designation: values.designation,
        email: values.email,
        mobile_number: `+91${values.mobile}`,
        bussiness_name: values.companyname
    },
    gst: {
        gst_number:values.GSTIN == "" ? null : values.GSTIN,
        // first_address: values.address,
        // second_address: values.address2,
        // postal_code: values.postalcode,
        // city: values.city,
        // state: values.state,
        // country: values.country,
        date_of_establishment: values.registrationdate,
        pancard_no: values.panno
    },
    bussiness_details: {
        first_company_type: values.companytype,
        // second_company_type: values.companytype2,
        industry_type: values.industrytype,
        website_link: values.weblink
    },
    // alt_person: {
    //     alternative_person_name: values.alt_name,
    //     alternative_designation: values.alt_des,
    //     alternative_email: values.alt_email,
    //     alternative_mobile: `+91${values.alt_mobile}`
    // },
    
    comm: {
        communication_postal_code: values.office_postalcode,
        communication_country: values.office_country,
        communication_state: values.office_state,
        communication_city: values.office_city,
        communication_address:values.office_address
    },
    reg: {
        reg_postal_code: values.reg_postalcode,
        reg_country: values.reg_country,
        reg_state: values.reg_state,
        reg_city: values.reg_city,
        reg_address:values.reg_address
    },
    warehouse: {
      warehouse_postal_code: values.war_postalcode,
      warehouse_country: values.war_country,
      warehouse_state: values.war_state,
      warehouse_city: values.war_city,
      warehouse_address: values.war_address
    },
    outlet: {
      outlet_postal_code: values.outlet_postalcode,
      outlet_country: values.outlet_country,
      outlet_state: values.outlet_state,
      outlet_city: values.outlet_city,
      outlet_address: values.outlet_address
    }
};



  if(values.pan != null){
    formdata.append('upload_pan',values.pan)
  }

  if(values.gst != null){
    formdata.append('upload_gst',values.gst)
  }

  if(values.certificateofcorporation != null){
    formdata.append('certificate_of_corporation',values.certificateofcorporation)
  }
  
  if(values.msme != null){
    formdata.append('msme',values.msme)
  }

  if(values.cancelledcheque != null){
    formdata.append('aadhar',values.cancelledcheque)
  }

  if(values.authorizationletter != null){
    formdata.append('authorization_letter',values.authorizationletter)
  }
      formdata.append('json_data', JSON.stringify(jsonData));


        try {
          let url = `${process.env.REACT_APP_API_URL}useraccount/Onboarding_final`;
          // setsub(1)
        const response = await axios.post(url, formdata);
        // console.log("respo of bussiness",response);
        setstatusstep5(response.data.message)
        setloading(true)
        if (closebtn.current) {
               closebtn.current.focus();
             }
        setstatusstep5(response.data.message)
    setTimeout(() => {
      nvg('/thankyoupageforonboarding')
    }, 2000);
        } catch (error) {
          // setsub(0)
          console.log("this is error msg",error.response)
          // if(error.response.data.aadhar){
          //   setadhaarerror(error.response.data.aadhar[0])
          // }
          // if(error.response.data.upload_pan){
          //   setpanerror(error.response.data.upload_pan[0])
          // }
          // if(error.response.data.upload_gst){
          //   setgsterror(error.response.data.upload_gst[0])
          // }
          // if(error.response.data.certificate_of_corporation){
          //   setcertificate_of_corporation(error.response.data.certificate_of_corporation[0])
          // }
          // if(error.response.data.msme){
          //   setmsme(error.response.data.msme[0])
          // }
          // if(error.response.data.authorization_letter){
          //   setauthorization_letter(error.response.data.authorization_letter[0])
          // }

        }
      
      
      }


      useEffect(()=>{
        window.scrollTo(0, 0);
      },[])


  const inputfocus = useRef(null)
  const [polling, setPolling] = useState(false);
  const [loader, setloader] = useState(true);
  const [gstdata,setgstdata] = useState([])

  const verifygst = async (gstno,setFieldValue,setFieldError) => {
    const formdata = {
      gst_no: gstno,
    };
    try {

      let url23 = `https://adminoneupv1.stackerbee.com/webhooks/gst/`;
        const response23 = await axios.post(url23, formdata);
           console.log("this is true response",response23)
           if(response23.data.req_id){
            const formdata234 = {
              request_id: response23.data.req_id,
            };
              startPolling(formdata234,setFieldValue,setFieldError)
        
      }else{
      console.log("step 2 this is gst first api")
           }
    } catch (error) {
      console.log("this is gst first api",error)
    }
   
  };



  const startPolling = async (formdata234,setFieldValue,setFieldError) => {
    setPolling(true);
    let isComplete = false;
  setloader(false)
    while (!isComplete) {
      isComplete = await secondfunfoggt(formdata234,setFieldValue,setFieldError);
      if (!isComplete) {
        await new Promise(resolve => setTimeout(resolve, 4000));
      }
    }
  
    setPolling(false);
  };


  const secondfunfoggt = async(formdata234,setFieldValue,setFieldError) =>{
    let url234 = `https://adminoneupv1.stackerbee.com/webhooks/check_data/`;
    const response234 = await axios.post(url234, formdata234);
    // setTimeout(() => {
      console.log("final rsponse is here",response234)
       if(response234.data[0].status == 'completed'){
        setshowotherform(true)
        setloader(true)
  
  
  
  
  
  
        //  try {
        //   let url = `https://devbackend.kycfirst.io/api/search/`;
        //   const response = await axios.post(url, formdata);
        //   console.log("this is fst response is here", response);
        //   if (response.data.kycStatus == "SUCCESS") {
            // setgstData(gstno, response234.data[0].result);
          
            setverifystatus_gst(1);
            setgstdata(response234.data);
            setFieldValue("address",`${
                    response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name == null ? '' : response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name} ${
                    (response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.door_number)
                  }`)
            setFieldValue("address2",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.location)
            setFieldValue("postalcode",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.pincode)
            setFieldValue("country","india")
            setFieldValue("state",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.state_name)
            setFieldValue("city",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.dst)
            setFieldValue("pan",response234.data[0].result.source_output.gstin.slice(2, -3))
            setFieldValue("registrationdate",response234.data[0].result.source_output.date_of_registration)
            setFieldError("address",'')
            setFieldError("address2",'')
            setFieldError("postalcode",'')
            setFieldError("country","india")
            setFieldError("state",'')
            setFieldError("city",'')
            setFieldError("pan",'')
            setFieldError("registrationdate",'')
            console.log("this is real data",response234.data)
          //  await setFieldValue("businessname", response234.data[0].result.source_output.legal_name);
          //  await setFieldValue(
          //     "address",
          //     `${
          //       response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name == null ? '' : response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name} ${
          //       (response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.door_number)
          //     }`
          //   );
          //  await setFieldValue(
          //     "address2",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.location          
          //   );
          //   const processedGstNumber = response234.data[0].result.source_output.gstin.slice(2, -3);
          // await setFieldValue("pan", processedGstNumber);
          //  await setFieldValue(
          //     "postalcode",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.pincode);
          //  await setFieldValue(
          //     "state",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.state_name);
          //  await setFieldValue(
          //     "city",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.dst);
            // const [month, day, year] =
            //   response234.data.kycResult.registrationDate.split("/");
            // setFieldValue("registrationdate",response234.data[0].result.source_output.date_of_registration);
            // setFieldValue("registrationdate", `${year}-${month}-${day}`);
    
            // if (inputfocus.current) {
            //   inputfocus.current.focus();
            // }
            // setTouched({
            //   gst_number: false,
            //   bussiness_name: false,
            //   first_address: false,
            //   second_address: false,
            //   postal_code: false,
            //   city: false,
            //   state: false,
            //   pancard_no: false,
            //   country: false,
            //   date_of_establishment: false,
            // });

            // await validateForm();
        return true;
       }else{
        if(response234.data[0].status == 'failed'){
          setloader(true)
          setmesgforerror("Incorrect GST No");
            setverifystatus_gst(2);
        return true;
  
        }else{
          return false;
        }
       }
    // }, 2000);      
  }









  


  const fetchstateandcity = async (value,setFieldError,setFieldValue) =>{
    if(value.length == 6){
  try {
    let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
    const response = await axios.get(url);
    console.log(" new response vvv",response)
    if(response.data.city == "NA"){
      setFieldValue('city','');
      setcityna("NA");
    setFieldError('city',"Please Enter Your City")
    }else{
      setFieldValue('city',response.data.city);
      setcityna("");
    }
    setFieldValue('state',response.data.state)
    const countrydata = countriesdata.filter(item2=>item2.printable_name == response.data.country.trim())
    console.log(";;;;;;",countrydata)
    setFieldValue('country',countrydata[0].printable_name);
  
  } catch (error) {
    // console.log("error",error.response.data)
    // setFieldValue('city','')
    // setFieldValue('state','')
    setFieldError('pincode',error.response.data.message)
  }
  }else{
    setFieldValue('city','')
    setFieldValue('state','')
    setFieldValue('country','')
  }
  }













  // Send Mobile otp api start here

  const SendOtps = async(values, setFieldValue,setFieldError) => {


 
                            try {
                        

                              const emailcheck = 'https://adminoneupv1.stackerbee.com/useraccount/check-email-onboarding/';
                              const response231 = await axios.post(emailcheck,{email:values.email});
                              console.log("reddsssfdf",response231);
                              if(response231.data.data == "Email Already Exists."){
                               setFieldError('email',"Email Already Exists.")
                              }else{



                                handleResend()
                                handleResendtwo()
                               const statuts = await sendotpmobile(values.mobile);
                               if(statuts == 2){
                                console.log("lllooo2ddd")

                            setFieldError('mobile',"Mobile No Already Exists.")
                               }else{
                                console.log("lllooocv2")

                                 sendotpemail(values.email);
                                 setshowotps(true);
                                 setFieldValue("emailotp", "");
                                 setFieldValue("mobileotp", "");
                                  if (openalert.current) {
                               openalert.current.click();
                               }
                                }
                              }
                            } catch (error) {
                              setloading(false);
                              console.log("thsks",error.response.data)
                        
                              if(error.response.data.data == "Email Does Not Exists."){
                               
                                
                               const statuts = await sendotpmobile(values.mobile);
                               if(statuts == 2){
                                console.log("lllooo2",statuts)
                            setFieldError('mobile',"Mobile No Already Exists.")
                               }else{
                                console.log("lllooo2ffff",statuts)
                                handleResend()
                                handleResendtwo()
                                 sendotpemail(values.email);
                                 setshowotps(true);
                                  if (openalert.current) {
                               openalert.current.click();
                               }
                                 setFieldValue("emailotp", "");
                                 setFieldValue("mobileotp", "");
                                }
                              }
                             
                            }
                        
 

  
  };









  const resendotp = (value,setFieldError,handleResend) => {

    console.log("fddfdmm,,,")
    sendotpmobile(value,setFieldError,handleResend,2);
  };








  const resendotpforemail = async (value,setFieldError,status,handleResend) => {
    try {
      const emailcheck = 'https://adminoneupv1.stackerbee.com/useraccount/check-email-onboarding/';
      const response231 = await axios.post(emailcheck,{email:value});
      console.log("reddsssfdf",response231);
      if(response231.data.data == "Email Already Exists."){
    setFieldError('email',"Email Already Exists.")
      }else{
        handleResend()
        setverifystatus_email(0);
        sendotpemail(value,setFieldError,status);
      }
    } catch (error) {
      if(error.response.data.data == "Email Does Not Exists."){
        handleResend()
        setverifystatus_email(0);
        sendotpemail(value,setFieldError,status);
      }
    }
    
      
    
  };
  const sendotpmobile = async (value,setFieldError,handleResend,status=1) => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("mobile_number", value);

    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/SentOtpForMobile/`;
      const response = await axios.post(url, formdata);
      console.log("dsfsdfdsccvv3345678905fsfssds",response,status,verifystatus_mobile)

      if(response.data.message == "Your Mobile Number is already exists in client side."){
        
        if(status == 2){
setFieldError('mobile',"Mobile No Already Exists.")
        }else{

          return 2;
        }
      }else{
        // setverifystatus_mobile(0);
        handleResend()
        setshowotp(true);
        setverifyotpstatus(1);
  
        setTimeout(() => {
          setverifyotpstatus(0);
        }, 5000);
      }
      
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };
  const sendotpemail = async (value) => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("email", value);

    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/client-email-verify/`;
      const response = await axios.post(url, formdata);
      //   setshowotp(true)
      // setverifyotpstatus(1)

      // setTimeout(() => {
      // setverifyotpstatus(0)
      // }, 5000);

      console.log("email otp response", response);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const verifymobile = async (e) => {
    const formdata = new FormData();
    formdata.append("mobile_number", e.mobile);
    formdata.append("otp", e.mobileotp);

    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/VerifyRegistrations/`;
      const response = await axios.post(url, formdata);
      console.log("resonse of dkdkd", response);
      if (response.data.message == "otp verify") {
        setverifystatus_mobile(1);
        // nvg('/track-order-list',{ state: { otp:otp,mobile:mobile } })
      } else {
        setverifystatus_mobile(2);
        // setverifyotpstatus(3)

        // setTimeout(() => {
        // setverifyotpstatus(0)
        // }, 5000);
      }
    } catch (error) {}
  };
  const verifyemail = async (e) => {
    const formdata = new FormData();
    formdata.append("email", e.email);
    formdata.append("otp", e.emailotp);

    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/client-otp-verify/`;
      const response = await axios.post(url, formdata);
      console.log("resonse of dkdkd", response);

      if (response.data.Verified == true) {
        setverifystatus_email(1);
        // nvg('/track-order-list',{ state: { otp:otp,mobile:mobile } })
      } else {
        setverifystatus_email(2);
      }
    } catch (error) {
      console.log("this is error time")
      setverifystatus_email(2);
    }
  };

console.log("just check email statues ",verifystatus_email)

const [countdown, setCountdown] = useState(60);
const [resendDisabled, setResendDisabled] = useState(true);
const [countdowntwo, setCountdowntwo] = useState(60);
const [resendDisabledtwo, setResendDisabledtwo] = useState(true);
const [opengstfrom, setopengstfrom] = useState(false);


const steptwo = () =>{
  setopengstfrom(true)
}










const [countriesdata,setcountriesdata] = useState([])
useEffect(()=>{ 
async function getcountries() {
let url = `${process.env.REACT_APP_API_URL}api/countries/`;
 try {
   const response = await axios.get(url);
   setcountriesdata(response.data)
 } catch (error) {
 }
}
getcountries()
 },[])

 useEffect(()=>{
  window.scrollTo(0, 0);
},[])














useEffect(() => {
  if (resendDisabled) {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          setResendDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }
  if (resendDisabledtwo) {
    const timer = setInterval(() => {
      setCountdowntwo((prevCountdowntwo) => {
        if (prevCountdowntwo <= 1) {
          clearInterval(timer);
          setResendDisabledtwo(false);
          return 0;
        }
        return prevCountdowntwo - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }
}, [resendDisabled,resendDisabledtwo]);

const handleResend = () => {
  // alert('OTP has been resent.');
  // Add your OTP resend logic here
  setCountdown(60);
  setResendDisabled(true);
};
const handleResendtwo = () => {
  // alert('OTP has been resent.');
  // Add your OTP resend logic here
  console.log("12345678900-0099")
  setCountdowntwo(60);
  setResendDisabledtwo(true);
  console.log("12345678900-0099dddddddd")

};

  const finalaction = async(values,setFieldValue,setFieldError) =>{

// if(unregisterdealer == false){

//   const gstcheck = 'https://adminoneupv1.stackerbee.com/useraccount/check-gst-onboarding/';
//   const response231 = await axios.post(gstcheck,{gst:values.GSTIN});
//   if(response231.data.data == "Gst Does Not Exists."){
//     verifygst(values.GSTIN,setFieldValue,setFieldError)
//   }else{
//     setFieldError('GSTIN',"GSTIN Already Exists.")
//   }



// }else{
  setshowotherform(true)
  setgstdata([])

// }

  }
  const abc = () =>{
console.log("4555")
  }



  const gststatus = async (values,setFieldError,setFieldValue) =>{
  
  if(unregisterdealer == false){

  const gstcheck = 'https://adminoneupv1.stackerbee.com/useraccount/check-gst-onboarding/';
  const response231 = await axios.post(gstcheck,{gst:values.GSTIN});
  if(response231.data.data == "Gst Does Not Exists."){
  console.log("oopppopp 2")

    // verifygst(values.GSTIN,setFieldValue,setFieldError)
    if(showotherform == true && verifystatus_mobile == 1 && verifystatus_email == 1){
      fourthform(values,setFieldError)
}else{
if(verifystatus_mobile == 1 && verifystatus_email == 1){
 finalaction(values,setFieldValue,setFieldError);
}else{
  SendOtps(values, setFieldValue,setFieldError);
}
}
  }else{
  console.log("oopppopp 3")

    setFieldError('GSTIN',"GSTIN Already Exists.")
    return 2
  }
}else{
  console.log("oopppopp 1")
  if(showotherform == true && verifystatus_mobile == 1 && verifystatus_email == 1){
    fourthform(values,setFieldError)
}else{
if(verifystatus_mobile == 1 && verifystatus_email == 1){
finalaction(values,setFieldValue,setFieldError);
}else{
SendOtps(values, setFieldValue,setFieldError);
}
}
}
  }

  // Send Mobile otp api end here
  const nvg = useNavigate();
  return (
    <div>
      <section
        className="section-big-pt-space pb-2"
        style={{ backgroundColor: "rgb(249 249 249)" }}
      >
        <div className="col-lg-12 col-sm-12 col-xs-12 mt-lg-3 mt-xs-4">
          <div className="container-fuild emp-profile">
            <div
              className="row profileregister"
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <section className="tab-product-main-tab">
                <div className="row mt-5">
                  <div
                    id="tab-3"
                    style={{ display: "block" }}
                    className="tab-content active default product-block3"
                  >
                    <div
                      className="d-flex"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="card p-1 " style={{ border: "none" }}>
                        <img
                            onClick={()=>{nvg('/')}}
                          // src="./images/login-logo.png"
                          src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`}
                          className="login-tabs-img customwidth"
                          style={{ marginBottom: "0px", cursor: "pointer" }}
                          alt
                        />

                        {/* <h2 style={{ lineHeight: "49px", fontWeight: "600" }}>
                          <span style={{ color: "#230BB3" }}> Welcome </span>{" "}
                          <span style={{ color: "#2B2A29" }}>And Sign Up</span>
                        </h2> */}
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        companytype: "",
                        industrytype: "",
                        weblink: "",
                        GSTIN:"",
                        companyname: "",
                        contactperson: "",
                        designation: "",
                        email: "",
                        mobile: "",
                        mobileotp: "38383",
                        emailotp: "94938",
                        panno:"",
                        registrationdate:"",
                        reg_address:"",
                        reg_postalcode:"",
                        reg_city:"",
                        reg_state:"",
                        reg_country:"",
                        office_address:"",
                        office_postalcode:"",
                        office_city:"",
                        office_state:"",
                        office_country:"",
                        war_address:"",
                        war_postalcode:"",
                        war_city:"",
                        war_state:"",
                        war_country:"",
                        outlet_address:"",
                        outlet_postalcode:"",
                        outlet_city:"",
                        outlet_state:"",
                        outlet_country:"",
                        
                      }}
                      validationSchema={Register6val}
                      onSubmit={(values, { setFieldValue,setFieldError}) => {

                        if(showotherform == true && verifystatus_mobile == 1 && verifystatus_email == 1){
                          fourthform(values,setFieldError)
                    }else{
                      gststatus(values,setFieldError,setFieldValue);
                    }
                     
                      // if(gst == 1){
                      //   }
                      }}
                    >
                      {({
                        values,
                        errors,
                        handleSubmit,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError,
                        dirty,
                        isValid,
                      }) => (
                        <Form
                          autoComplete="off"
                          className={showotps ? `form-login container-fluid mt-3 mb-5` : `form-login container-fluid mt-3`}
                          style={{ width: "100%",height:'fit-content' }}
                          onSubmit={handleSubmit}
                        >
                          <div className="row px-2">
                            {console.log("this is form value 123",values,errors)}
                          <div className="col-lg-6 col-md-12">
                              <label
                                style={{ margin: "0px", paddingTop: "8px" }}
                                htmlFor="text"
                              >
                                {/* Primary Contact Person */}
                                Name of Company
                                 <span style={{color:'red'}}>*</span>
                              </label>
                              <div className="log-sendotp">
                                <Field
                                  type="text"
                                  disabled={showotherform}
                                  name="companyname"
                                  className="login-input newdesigninput mb-0"
                                  // placeholder="Enter Company Name"
                                  style={{ paddingRight: "50px",border:'1px solid #ced4da ', borderColor:
                                    touched.companyname && errors.companyname
                                      ? "red"
                                      : touched.companyname && !errors.companyname
                                      ? values.companyname == "" ? "#ced4da" : "green"
                                      : "#ced4da" }}
                                  value={values.companyname}
                                />
                              </div>

                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                }}
                              >
                                {errors.companyname &&
                                touched.companyname ? (
                                  <p style={{ color: "red" }}>
                                    {errors.companyname}
                                  </p>
                                ) : null}
                              </span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <label
                                style={{ margin: "0px", paddingTop: "8px" }}
                                htmlFor="text"
                              >
                                {/* Primary Contact Person */}
                               Company Type
                                 <span style={{color:'red'}}>*</span>
                              </label>
                              <div className="log-sendotp">
                                {/* <Field
                                  type="text"
                                  name="companytype"
                                  className="login-input newdesigninput mb-0"
                                  placeholder="Enter Your Company Type"
                                  style={{ paddingRight: "50px",borderBottom:errors.companytype &&
                                  touched.companytype ? "1px solid red" : '' }}
                                  value={values.companytype}
                                /> */}


<Field name="companytype" onChange={(e)=>{imagedec(e,values);setFieldValue("companytype",e.target.value);makeitunregister(setFieldValue);}} className="form-control newdesigninput" 
                                  disabled={showotherform}
                                  style={{height:'40px',color:'#bababa',borderRadius:'0px',outline:'none',shadow:'none',padding: "0.375rem 2px",fontSize:'14px',border:'1px solid #ced4da ', borderColor:
                                    touched.companytype && errors.companytype
                                      ? "red"
                                      : touched.companytype && !errors.companytype
                                      ? "green"
                                      : "#ced4da"}} as="select">
            <option value="" ></option>
           {/* {unregisterdealer == true ? "" :<option value="LLP">LLP</option>}
           {unregisterdealer == true ? "" : <option value="Private Limited">Private Limited</option>}
           {unregisterdealer == true ? "" : <option value="Public Limited">Public Limited</option>} */}
           <option value="LLP">LLP</option>
            <option value="Private Limited">Private Limited</option>
            <option value="Public Limited">Public Limited</option>
            <option value="Partnership">Partnership</option>
            <option value="Proprietary">Proprietary</option>
          </Field>
                              </div>

                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                  
                                }}
                              >
                                {errors.companytype &&
                                touched.companytype ? (
                                  <p style={{ color: "red" }}>
                                    {errors.companytype}
                                  </p>
                                ) : null}
                              </span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <label
                                style={{ margin: "0px", paddingTop: "8px" }}
                                htmlFor="text"
                              >
                                Nature of Business
                                 <span style={{color:'red'}}>*</span>
                              </label>
                              <div className="log-sendotp">
                                {/* <Field
                                  type="text"
                                  name="industrytype"
                                  className="login-input newdesigninput mb-0"
                                  placeholder="Enter Industry Type"
                                  style={{ paddingRight: "50px",borderBottom:errors.industrytype &&
                                  touched.industrytype ? "1px solid red" : '' }}
                                  value={values.industrytype}
                                /> */}


<Field name="industrytype" className="form-control newdesigninput"
                                  disabled={showotherform}
                                  style={{height:'40px',color:'#bababa',borderRadius:'0px',outline:'none',shadow:'none',padding: "0.375rem 2px",fontSize:'14px',border:'1px solid #ced4da ', borderColor:
                                    touched.industrytype && errors.industrytype
                                      ? "red"
                                      : touched.industrytype && !errors.industrytype
                                      ? "green"
                                      : "#ced4da"}} as="select">
            {/* <option value="" disabled>Nature of Business</option> */}
            <option value="" disabled></option>
            <option value="Media" disabled style={{fontWeight:'bold',color:'#737171'}}>Media</option>
            <option value="Newspapers">Newspapers</option>
            <option value="TV Channels">TV Channels</option>
            <option value="Radio Stations">Radio Stations</option>
            <option value="Magazines">Magazines</option>
            <option value="Cinema Screens">Cinema Screens</option>
            <option value="OOH Sites">OOH Sites</option>
            <option value="Digital Media">Digital Media</option>
            <option value="Goods" disabled style={{fontWeight:'bold',color:'#737171'}}>Goods</option>
            <option value="Electronics" >Electronics</option>
            <option value="Apparels" >Apparels</option>
            <option value="Appliances" >Appliances</option>
            <option value="Furnishing" >Furnishing</option>
            <option value="Accessories" >Accessories</option>
            <option value="Interiors" >Interiors</option>
            <option value="Merchandise" >Merchandise</option>
            <option value="Services" disabled style={{fontWeight:'bold',color:'#737171'}}>Services</option>

            <option value="Hotel Accommodation">Hotel Accommodation</option>
            <option value="Holiday Packages">Holiday Packages</option>
            <option value="Banquets / Conferences">Banquets / Conferences</option>
            <option value="Health Care & Wellness">Health Care & Wellness</option>
            <option value="Restaurants & Clubs">Restaurants & Clubs</option>
            <option value="Spas, Salons & Gyms">Spas, Salons & Gyms</option>
            <option value="Events & Exhibitions">Events & Exhibitions</option>
            <option value="Others">Others</option>
            {/* <option value="Education" style={{fontWeight:'bold',color:'#737171'}}>Education</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Construction">Construction</option>
            <option value="Transportation">Transportation</option> */}
          </Field>
                              </div>

                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                }}
                              >
                                {errors.industrytype &&
                                touched.industrytype ? (
                                  <p style={{ color: "red" }}>
                                    {errors.industrytype}
                                  </p>
                                ) : null}
                              </span>
                            </div>

                            <div className="col-lg-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Date of Establishment 
                          {/* (DD/MM/YYYY) */}
                           <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="date"
                            name="registrationdate"
                            style={{border:'1px solid #ced4da ', borderColor:
                              touched.registrationdate && errors.registrationdate
                                ? "red"
                                : touched.registrationdate && !errors.registrationdate
                                ? values.registrationdate == "" ? "#ced4da" : "green"
                                : "#ced4da"}}
                            className="login-input newdesigninput mb-0"
                            // placeholder={`Enter Your Business Legal Type`}
                            value={values.registrationdate}
                          
                          />
                        </div>
                      </div> 

                            
                      <div className="col-lg-6 col-md-12">
                              {/* <label
                                style={{ margin: "0px", paddingTop: "8px",display:'flex',justifyContent:'space-between' }}
                                htmlFor="text"
                              >
                                <span>
                               GSTIN
                                </span>
                                <span>
                                  {unregisterdealer == true ? <input type="checkbox" name=""  id="" style={{outline:'none'}} onClick={()=>{makeitunregister(setFieldValue)}} checked /> : <input type="checkbox" style={{outline:'none'}} onClick={()=>{makeitunregister(setFieldValue)}} name=""  id="" /> }
                                Unregistered Dealer
                                </span>
                              </label> */}
                              {/* <div className="log-sendotp">
                               {unregisterdealer == true ? <Field
                                  type="text"
                                  name="GSTIN"
                                  readOnly
                                  className="login-input newdesigninput mb-0"
                                  placeholder="Enter Your GSTIN"
                                  style={{ paddingRight: "50px",borderBottom:errors.GSTIN &&
                                  touched.GSTIN ? "1px solid red" : '' }}
                                  value={values.GSTIN}
                                /> : <Field
                                type="text"
                                name="GSTIN"
                                // readOnly
                                className="login-input newdesigninput mb-0"
                                placeholder="Enter Your GSTIN"
                                style={{ paddingRight: "50px",borderBottom:errors.GSTIN &&
                                touched.GSTIN ? "1px solid red" : '' }}
                                value={values.GSTIN}
                              />} 
                              </div> */}

                              {/* <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                }}
                              >
                                {errors.GSTIN &&
                                touched.GSTIN ? (
                                  <p style={{ color: "red" }}>
                                    {errors.GSTIN}
                                  </p>
                                ) : null}
                              </span> */}









                            
                        <label
                          style={{ margin: "0px", paddingTop: "8px",display:'flex',justifyContent:'space-between' }}
                          htmlFor="text"
                        >
                          <span>GSTIN {values.companytype != "Proprietary" && values.companytype != "Partnership" ? <span style={{color:'red'}}>*</span> : ''}</span>
                          <span style={{fontSize:'14px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                  {/* {showotherform == true || values.companytype == "Proprietary" || values.companytype == "Partnership" ? <input type="checkbox" checked readOnly name="" id="" /> : unregisterdealer == true ? <input type="checkbox" name=""  id="" style={{outline:'none'}} readOnly onClick={()=>{makeitunregister(setFieldValue);imagedec2('',values)}} checked /> : <input type="checkbox" style={{outline:'none'}} readOnly onClick={()=>{makeitunregister(setFieldValue);imagedec2('',values)}} disabled name=""  id="" /> } */}
                                  {values.companytype == "Proprietary" || values.companytype == "Partnership" ? <input type="checkbox" checked readOnly name="" id="" /> : <input type="checkbox" style={{outline:'none'}} readOnly onClick={()=>{makeitunregister(setFieldValue);imagedec2('',values)}} disabled name=""  id="" /> }
                                Unregistered Dealer
                                </span>
                        </label>
                        <div className="log-sendotp">
                          {/* <Field
                            type="text"
                            name="gstno"
                            className="login-input newdesigninput mb-0"
                            placeholder={`Enter GST No`}
                            value={values.gstno}
                            style={{
                              borderBottom:
                                verifystatus_gst == 2 ? "1px solid red" : "",
                            }}
                            onChange={(e) => {
                              setverifystatus_gst(0);
                              setFieldValue("gstno", e.target.value);
                            }}
                          /> */}






{values.companytype == "Proprietary" || values.companytype == "Partnership" ? <Field
                                  type="text"
                                  name="GSTIN"
                                  readOnly
                                  className="login-input newdesigninput mb-0"
                                  // placeholder="Enter Your GSTIN "
                                  style={{ paddingRight: "50px",border:'1px solid #ced4da ', borderColor:
                                    touched.GSTIN && errors.GSTIN
                                      ? "red"
                                      : touched.GSTIN && !errors.GSTIN
                                      ? values.GSTIN == "" ? "#ced4da" : "green"
                                      : "#ced4da"}}
                                  value={values.GSTIN}
                                /> : <Field
                                type="text"
                                name="GSTIN"
                                onChange={(e) => {
                                  setverifystatus_gst(0);
                                  setFieldValue("GSTIN", e.target.value);
                                  imagedec2(e.target.value,values)
                                }}
                                // readOnly
                                className="login-input newdesigninput mb-0"
                                // placeholder="Enter Your GSTIN"
                                style={{ paddingRight: "50px",border:'1px solid #ced4da ', borderColor:
                                  touched.GSTIN && errors.GSTIN
                                    ? "red"
                                    : touched.GSTIN && !errors.GSTIN
                                    ? values.GSTIN == "" ? "#ced4da" : "green" : "#ced4da" }}
                                value={values.GSTIN}
                              />}


                          
                                              </div>
  
                        <span
                          style={{
                            display: "block",
                            marginBottom: "10px",
                          }}
                        >
                          
                            {errors.GSTIN && touched.GSTIN ? (
                            <p style={{ color: "red" }}>{errors.GSTIN}</p>
                          ) : null}
                        </span>













                            </div>



                            <div className="col-lg-6 col-md-12">
                              <label
                                style={{ margin: "0px", paddingTop: "8px" }}
                                htmlFor="text"
                              >
                                Pan No.<span style={{color:'red'}}>*</span>
                              </label>
                              <div className="log-sendotp">
                                <Field
                                  type="text"
                                  name="panno"
                                  disabled={showotherform}
                                  maxLength="10" 
                                  className="login-input newdesigninput mb-0"
                                  // placeholder="Enter Pan No."
                                  style={{ paddingRight: "50px",border:'1px solid #ced4da ', borderColor:
                                    touched.panno && errors.panno
                                      ? "red"
                                      : touched.panno && !errors.panno
                                      ? values.panno == "" ? "#ced4da" : "green"
                                      : "#ced4da" }}
                                  value={values.panno}
                                />
                              </div>

                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                }}
                              >
                                {errors.panno &&
                                touched.panno ? (
                                  <p style={{ color: "red" }}>
                                    {errors.panno}
                                  </p>
                                ) : null}
                              </span>
                            </div>
                        
                         
                          {/* <div className="col-lg-6 col-md-12">
                              <label
                                style={{ margin: "0px", paddingTop: "8px" }}
                                htmlFor="text"
                              >
                               Nature of Business
                                 <span style={{color:'red'}}>*</span>
                              </label>
                              <div className="log-sendotp">
                              
                                <Field name="companytype2"
                                  disabled={showotherform}
                                  className="form-control" style={{borderTop:'0px',height:'40px',borderRight:'0px',borderLeft:'0px',borderColor:'rgba(143, 144, 145, 1)',color:'#bababa',outline:'none',shadow:'none',padding: "0.375rem 2px",fontSize:'14px'}} as="select">
            <option value="" disabled>Select Nature of Business</option>
            <option value="Manufacturer">Manufacturer</option>
            <option value="Distributor">Distributor</option>
            <option value="Retailer">Retailer</option>
            <option value="Service Provider">Service Provider</option>
            <option value="Other">Other</option>
          </Field>
                              </div>

                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                }}
                              >
                                {errors.companytype2 &&
                                touched.companytype2 ? (
                                  <p style={{ color: "red" }}>
                                    {errors.companytype2}
                                  </p>
                                ) : null}
                              </span>
                            </div> */}

                         
                          
                          
                            <div className="col-lg-6 col-md-12">
                              <label
                                style={{ margin: "0px", paddingTop: "8px" }}
                                htmlFor="text"
                              >
                                {/* Primary Contact Person */}
                                Name of Account User
                                 <span style={{color:'red'}}>*</span>
                              </label>
                              <div className="log-sendotp">
                                <Field
                                  type="text"
                                  name="contactperson"
                                  disabled={showotherform}
                                  className="login-input newdesigninput mb-0"
                                  // placeholder="Person Authorized to Operate This Account"
                                  style={{ paddingRight: "50px",border:'1px solid #ced4da ', borderColor:
                                    touched.contactperson && errors.contactperson
                                      ? "red"
                                      : touched.contactperson && !errors.contactperson
                                      ? values.contactperson == "" ? "#ced4da" : "green"
                                      : "#ced4da"}}
                                  value={values.contactperson}
                                />
                              </div>

                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                }}
                              >
                                {errors.contactperson &&
                                touched.contactperson ? (
                                  <p style={{ color: "red" }}>
                                    {errors.contactperson}
                                  </p>
                                ) : null}
                              </span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <label
                                style={{ margin: "0px", paddingTop: "8px" }}
                                htmlFor="text"
                              >
                                Designation of Account User <span style={{color:'red'}}>*</span>
                              </label>
                              <div className="log-sendotp">
                                <Field
                                  type="text"
                                  name="designation"
                                  disabled={showotherform}
                                  className="login-input newdesigninput mb-0"
                                  // placeholder="Enter Your Designation"
                                  style={{ paddingRight: "50px",border:'1px solid #ced4da ', borderColor:
                                    touched.designation && errors.designation
                                      ? "red"
                                      : touched.designation && !errors.designation
                                      ? values.designation == "" ? "#ced4da" : "green"
                                      : "#ced4da"}}
                                  value={values.designation}
                                />
                              </div>

                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                }}
                              >
                                {errors.designation && touched.designation ? (
                                  <p style={{ color: "red" }}>
                                    {errors.designation}
                                  </p>
                                ) : null}
                              </span>
                            </div>

                            <div className="col-lg-6 col-md-12">
                              <label
                                style={{ margin: "0px", paddingTop: "8px" }}
                                htmlFor="text"
                              >
                                Mobile No. of Account User <span style={{color:'red'}}>*</span>
                              </label>

                              <div className="log-sendotp">
                                <Field
                                  type="tel"
                                  name="mobile"
                                  disabled={showotherform}

                                  className="login-input newdesigninput mb-0"
                                  // placeholder="Enter Your Mobile No."
                                  value={values.mobile}
                                  style={{border:'1px solid #ced4da ', borderColor:
                                    touched.mobile && errors.mobile
                                      ? "red"
                                      : touched.mobile && !errors.mobile
                                      ? "green"
                                      : "#ced4da"}}
                                  onChange={(e) => {
                                    // setFieldValue("mobileotp", e.target.value);
                                    setFieldValue("mobile", e.target.value.replace(/\D/g, ''));
                                    setverifystatus_mobile(0);
                                  }}
                                />
                              </div>

                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                }}
                              >
                                {" "}
                                {errors.mobile && touched.mobile ? (
                                  <p style={{ color: "red" }}>
                                    {errors.mobile}
                                  </p>
                                ) : null}
                              </span>
                            </div>
                            
                            <div className="col-lg-6 col-md-12">
                              <label
                                style={{ margin: "0px", paddingTop: "8px" }}
                                htmlFor="text"
                              >
                                Email Address of Account User <span style={{color:'red'}}>*</span>
                              </label>
                              <div className="log-sendotp">
                                <Field
                                  type="email"
                                  name="email"
                                  disabled={showotherform}

                                  className="login-input newdesigninput mb-0"
                                  // placeholder="Enter Your Email Address"
                                  style={{ paddingRight: "50px",border:'1px solid #ced4da ', borderColor:
                                    touched.email && errors.email
                                      ? "red"
                                      : touched.email && !errors.email
                                      ? values.email == "" ? "#ced4da" : "green"
                                      : "#ced4da" }}
                                  value={values.email}
                                  onChange={(e) => {
                                    // setFieldValue("emailotp", e.target.value);
                                    setFieldValue("email", e.target.value);
                                  }}
                                />
                              </div>

                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                }}
                              >
                                {" "}
                                {verifystatus == 2 ? (
                                  ""
                                ) : errors.email && touched.email ? (
                                  <p style={{ color: "red" }}>{errors.email}</p>
                                ) : null}
                              </span>
                            </div>
                            

                          

                            {showotps ? (
                              // <div className="col-lg-6 col-md-12">
                              //   <label
                              //     style={{ margin: "0px", paddingTop: "8px" }}
                              //     htmlFor="text"
                              //   >
                              //     Email Verification <span style={{color:'red'}}>*</span>
                              //   </label>
                              //   <div className="log-sendotp">
                              //     <Field
                              //       type="tel"
                              //       name="emailotp"
                              //       className="login-input newdesigninput mb-0"
                              //       placeholder={`Enter OTP Sent To ${values.email}`}
                              //       value={values.emailotp}
                              //       style={{borderBottom:verifystatus_email == 2 ? "1px solid red" : ''}}
                              //       onChange={(e) => {
                              //         setverifystatus_email(0);
                              //         setFieldValue("emailotp", e.target.value.replace(/\D/g, ''));
                              //       }}
                              //     />
                                  
                              //     <span
                              //       style={{
                              //         cursor: "pointer",
                              //         backgroundColor: "white",
                              //       }}
                                   
                              //     >
                              //       {
                                    
                              //        verifystatus_email == 0 ? (
                              //         <div> <span className="relpostion" onClick={() => {verifyemail({
                              //                 emailotp: values.emailotp,
                              //                 email: values.email,
                              //               })
                              //         }} >Verify</span> <span className="relpostion">|</span> <span className="relpostion" onClick={resendDisabled == true ? () => {abc()} : () => {handleResend();resendotpforemail(values.email)}}>Resend {countdown == 0 ? '' : `(${countdown})`}</span></div>
                              //       ) : verifystatus_email == 1 ? (
                              //         <img
                              //           src="./images/sucess.png"
                              //           alt="404"
                              //         />
                              //       ) : verifystatus_email == 2 ? (
                              //         <div> <span className="relpostion" onClick={() => {verifyemail({
                              //           emailotp: values.emailotp,
                              //           email: values.email,
                              //         })
                              //   }} >Verify</span ><span className="relpostion">|</span> <span className="relpostion" onClick={resendDisabled == true ? () => {abc()} : () => {handleResend();resendotpforemail(values.email)}}>Resend {countdown == 0 ? '' : `(${countdown})`}</span></div>
                              //       ) : (
                              //         <div> <span className="relpostion" onClick={() => {verifyemail({
                              //           emailotp: values.emailotp,
                              //           email: values.email,
                              //         })
                              //   }} >Verify</span><span className="relpostion">|</span> <span className="relpostion" onClick={resendDisabled == true ? () => {abc()} : () => {handleResend();resendotpforemail(values.email)}}>Resend {countdown == 0 ? '' : `(${countdown})`}</span></div>
                              //       )}{" "}
                              //     </span>
                              //   </div>
                              
                              //   <span
                              //     style={{
                              //       display: "block",
                              //       marginBottom: "20px",
                              //     }}
                              //   >
                              //     {" "}
                                 
                              //     {verifystatus_email == 2 ? (
                              //       <p
                              //         style={{
                              //           color: "red",
                              //           display:
                              //             values.emailotp == ""
                              //               ? "none"
                              //               : "block",
                              //         }}
                              //       >
                              //         Incorrect OTP, Try Again{" "}
                              //         <img
                              //           src="./images/question.png"
                              //           alt="404"
                              //         />
                              //       </p>
                              //     ) : null}
                              //   </span>
                              // </div> 
                              ''
                            ) : (
                              ""
                            )}









                          
  <div
          className="modal fade"
          id="exampleModa1123456"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
            <div class="modal-header" style={{border:'0px',padding:'0px',height:'30px'}}>
        {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Add Address</h1> */}
        <button type="button" ref={closebtn} class="btn-close" style={{position:'relative',top:'8px',left:'-17px'}} onClick={()=>{changeotp(setFieldValue,setFieldTouched)}} data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
              {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
              {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
              <div className="modal-header mod-line d-none"></div>
              <div className="modal-body">
                <div className="row gy-3 mt-2">
                  {/* <div className="d-flex justify-content-center">
          // <BsQuestionLg className='question-logo' />
            </div> */}
            <div className="col-12 m-0">
                  <h4
                    style={{
                      color: "rgb(35, 11, 179)",
                      cursor: "pointer",
                      textAlign: "center",
                      fontSize: "21px",
                      fontWeight: "800",
                    }}
                    >
                    OTP Verification
                  </h4>
                    </div>


                    {showotps ? (  
  <OTPPopup2 setverifystatus_email={setverifystatus_email} showotherform={showotherform} verifystatus_email={verifystatus_email} values={values} resendotpforemail={resendotpforemail} verifyemail={verifyemail} setverifystatus_mobile={setverifystatus_mobile} setFieldValue={setFieldValue} setFieldError={setFieldError} />
 ) : ("")}




{showotps ? <OTPPopup verifystatus_mobile={verifystatus_mobile} showotherform={showotherform} values={values} handleResendtwo={handleResendtwo} resendotp={resendotp} verifymobile={verifymobile} setverifystatus_mobile={setverifystatus_mobile} setFieldValue={setFieldValue} setFieldError={setFieldError} /> : ("")}

                  <div className="col-12 d-flex justify-content-center">
                    <div style={{width:'70%'}}>
                    <button
                                type="submit"
                                disabled={!(isValid && dirty && verifystatus_mobile == 1 && verifystatus_email == 1)}
                                className="btn"
                                id="myForm"
                              >
                                Submit
                              </button></div>
                  </div>

                  {statusstep5 == "" ? "" : <div className="col-xl-12">
<div class="alert alert-success mt-2" role="alert">
  {statusstep5}
</div>
</div>}
                </div>
              </div>
              <div
                className="modal-footer mod-line m-auto"
                style={{ border: "none" }}
              >
                {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
                {/* <button
                  type="button"
                  className="btn closebtn "
                  style={{
                    paddingRight: "20px",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#230BB3",
                  }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn closebtn profile-edit-btn justhoverwh"
                  data-bs-dismiss="modal"
                  onClick={() => {nvg('/checkout')}}
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  Checkout
                </button> */}
              </div>
            </div>
          </div>
        </div>


 {/* <div className="col-lg-6 col-md-12">
<div className="row">
<div className="col-lg-12 col-md-12">
  <h4 style={{fontSize:'19px'}}>GSTIN DETAILS</h4>
</div>
<div className="col-lg-12 col-md-12">
 <Gstformtwo  showotherform={showotherform} setFieldValue={setFieldValue} setFieldError={setFieldError} errors={errors} touched={touched} values={values} gstdata={[]}  /> 
  
</div></div>
</div> */}




                            {/* <div className="col-lg-6 col-md-12">
                            
                            </div> */}


 <div className="col-lg-6 col-md-12">
<div className="row">
<div className="col-lg-12 col-md-12">
  <Alternativeform setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} fieldvalue={[{"address":"Registered Address","pincode":"Postal Code","City":"City","State":"State","Country":"Country","addressapi":"reg_address","pincodeapi":"reg_postalcode","Cityapi":"reg_city","Stateapi":"reg_state","Countryapi":"reg_country"},{"address":"Warehouse Address","pincode":"Postal Code","City":"City","State":"State","Country":"Country","addressapi":"war_address","pincodeapi":"war_postalcode","Cityapi":"war_city","Stateapi":"war_state","Countryapi":"war_country"}]} setFieldError={setFieldError} errors={errors} touched={touched} values={values} />
</div></div>
</div> 
 <div className="col-lg-6 col-md-12">
<div className="row">
<div className="col-lg-12 col-md-12">
  <Alternativeformtwo setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} fieldvalue={[{"address":"Office Address","pincode":"Postal Code","City":"City","State":"State","Country":"Country","addressapi":"office_address","pincodeapi":"office_postalcode","Cityapi":"office_city","Stateapi":"office_state","Countryapi":"office_country"},{"address":"Warehouse Address","pincode":"Postal Code","City":"City","State":"State","Country":"Country","addressapi":"war_address","pincodeapi":"war_postalcode","Cityapi":"war_city","Stateapi":"war_state","Countryapi":"war_country"}]}  setFieldError={setFieldError} errors={errors} touched={touched} values={values} />
</div></div>
</div> 

<div className="col-lg-6 col-md-12">
                              <label
                                style={{ margin: "0px", paddingTop: "8px" }}
                                htmlFor="text"
                              >
                                {/* Primary Contact Person */}
                                Website URL
                                 {/* <span style={{color:'red'}}>*</span> */}
                              </label>
                              <div className="log-sendotp">
                                <Field
                                  type="text"
                                  name="weblink"
                                  disabled={showotherform}

                                  className="login-input newdesigninput mb-0"
                                  // placeholder="Website URL"
                                  style={{ paddingRight: "50px",border:'1px solid #ced4da ', borderColor:
                                    touched.weblink && errors.weblink
                                      ? "red"
                                      : touched.weblink && !errors.weblink
                                      ? values.weblink == "" ? "#ced4da" : "green"
                                      : "#ced4da" }}
                                  value={values.weblink}
                                />
                              </div>

                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "16px",
                                }}
                              >
                                {errors.weblink &&
                                touched.weblink ? (
                                  <p style={{ color: "red" }}>
                                    {errors.weblink}
                                  </p>
                                ) : null}
                              </span>
                            </div>
<div className="col-lg-6 col-md-12"></div>


{documents == '' ? '' : <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px" }} htmlFor="profileImage">Upload PAN Card<span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="pan"
                  className="login-input mb-0 newdesigninput fileinput"
                  style={{border:'1px solid #ced4da',lineHeight:'26.4px', borderColor:
                     touched.pan && errors.pan
                      ? "red"
                      : touched.pan && !errors.pan
                      ? "green"
                      : "#ced4da"}}
                  onChange={(event) => {
                    setFieldValue("pan", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.pan && touched.pan && <p style={{ color: "red" }}>{errors.pan}</p>}
               {/* {panerror == '' ? '' : <p style={{ color: "red" }}>{panerror}</p>} */}

              </span>
            </div>}

<div className="col-lg-6 col-md-12"></div>
{documents.includes('gst') ? <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px" }} htmlFor="GST">Upload GSTIN Certificate <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="gst"
                  className="login-input newdesigninput mb-0 fileinput"
                  style={{border:'1px solid #ced4da ', borderColor:
                    touched.gst && errors.gst
                      ? "red"
                      : touched.gst && !errors.gst
                      ? "green"
                      : "#ced4da",lineHeight:'26.4px', }}
                  onChange={(event) => {
                    setFieldValue("gst", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.gst && touched.gst && <p style={{ color: "red" }}>{errors.gst}</p>}
                {/* {gsterror == '' ? '' :<p style={{ color: "red" }}> {gsterror}</p>} */}
              </span>
              </div> : ''}
              <div className="col-lg-6 col-md-12"></div>

              {documents.includes('corporation') ? <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px" }} htmlFor="certificateofcorporation">Upload Certificate of Incorporation <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="certificateofcorporation"
                  className="login-input newdesigninput mb-0 fileinput"
                  style={{border:'1px solid #ced4da ', borderColor:
                    touched.certificateofcorporation && errors.certificateofcorporation
                      ? "red"
                      : touched.certificateofcorporation && !errors.certificateofcorporation
                      ? "green"
                      : "#ced4da",lineHeight:'26.4px', }}
                  onChange={(event) => {
                    setFieldValue("certificateofcorporation", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.certificateofcorporation && touched.certificateofcorporation && <p style={{ color: "red" }}>{errors.certificateofcorporation}</p>}
                {/* {certificate_of_corporation == '' ? '' :<p style={{ color: "red" }}> {certificate_of_corporation}</p>} */}
              </span>
              </div>: ''}
              <div className="col-lg-6 col-md-12"></div>


              {documents.includes('authorization') ? <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px" }} htmlFor="GST">Upload Authorization Letter for Account User<span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="authorizationletter"
                  className="login-input newdesigninput mb-0 fileinput"
                  style={{ border:'1px solid #ced4da ', borderColor:
                    touched.authorizationletter && errors.authorizationletter
                      ? "red"
                      : touched.authorizationletter && !errors.authorizationletter
                      ? "green"
                      : "#ced4da",lineHeight:'26.4px', }}
                  onChange={(event) => {
                    setFieldValue("authorizationletter", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.authorizationletter && touched.authorizationletter && <p style={{ color: "red" }}>{errors.authorizationletter}</p>}
                {/* {authorization_letter == '' ? '' :<p style={{ color: "red" }}> {authorization_letter}</p>} */}
              </span>
              </div> : ''}
              <div className="col-lg-6 col-md-12"></div>

              {documents.includes('msme') ? <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px" }} htmlFor="GST">Upload MSME Certificate <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="msme"
                  className="login-input newdesigninput mb-0 fileinput"
                  style={{ border:'1px solid #ced4da ', borderColor:
                    touched.msme && errors.msme
                      ? "red"
                      : touched.msme && !errors.msme
                      ? "green"
                      : "#ced4da",lineHeight:'26.4px', }}
                  onChange={(event) => {
                    setFieldValue("msme", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.msme && touched.msme && <p style={{ color: "red" }}>{errors.msme}</p>}
{/* {msme == '' ? '' :<p style={{ color: "red" }}> {msme}</p>} */}
              </span>
              </div> : ''}
<div className="col-lg-6 col-md-12"></div>

              {documents.includes('adhaar') ? <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px" }} htmlFor="GST">Upload Aadhar Card <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="cancelledcheque"
                  className="login-input mb-0 newdesigninput fileinput"
                  style={{ border:'1px solid #ced4da ', borderColor:
                    touched.cancelledcheque && errors.cancelledcheque
                      ? "red"
                      : touched.cancelledcheque && !errors.cancelledcheque
                      ? "green"
                      : "#ced4da",lineHeight:'26.4px', }}
                  onChange={(event) => {
                    setFieldValue("cancelledcheque", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {errors.cancelledcheque && touched.cancelledcheque && <p style={{ color: "red" }}>{errors.cancelledcheque}</p>}
{/* {adhaarerror == '' ? '' :<p style={{ color: "red" }}> {adhaarerror}</p>} */}
              </span>
              </div> : ''}
  <div className={`col-12 mt-2 mb-3`}>
                           {termandcondition == true ? <Field type="checkbox" name="terms" value={true} checked onChange={()=>{settermandcondition(!termandcondition)}} style={{position:'relative',top:'1px',left:'-4px'}}  /> : <Field type="checkbox" name="terms" value={false} onChange={()=>{settermandcondition(!termandcondition)}} style={{position:'relative',top:'1px',left:'-4px'}}  />}   
                            I agree to <span style={{color:'#230BB3',cursor:'pointer'}} onClick={()=>{nvg("/useragreement")}}> User Agreement </span> and <span style={{color:'#230BB3',cursor:'pointer'}} onClick={()=>{nvg("/privacypolicy")}}> Privacy Policy
                            </span>                            </div>



                   <div className="col-lg-6 offset-lg-3 offset-md-0 col-md-12 ">
                           {unregisterdealer == true || values.GSTIN != "" ? <div>
                                 {/* <button
                                type="submit"
                                disabled={!(isValid && dirty)}
                                className="btn"
                                id="myForm"
                              >
                                Get OTP
                              </button> */}


{values.GSTIN == "" ? values.pan == null || values.cancelledcheque == null || termandcondition == false ?  <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        disabled
                        className="btn "
                        id="myForm"
                        // style={{padding:'6px 9px',width:'fit-content'}}
                      >
                       GET OTP
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : <button
                        type="submit"
                        disabled={!(isValid && dirty)}
                        // disabled
                        className="btn "
                        id="myForm"
                        // style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        GET OTP
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : '' }




                   {values.GSTIN != "" ? values.companytype == "Proprietary" ? values.pan == null || values.cancelledcheque == null || values.gst == null || values.msme == null || termandcondition == false ? <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        disabled
                        className="btn "
                        id="myForm"
                        // style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        GET OTP
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : <button
                        type="submit"
                        disabled={!(isValid && dirty)}
                        className="btn "
                        id="myForm"
                        // style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        GET OTP
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : values.companytype == "Partnership" ? values.pan == null || values.cancelledcheque == null || values.gst == null || values.msme == null || values.authorizationletter == null || termandcondition == false ? <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        disabled
                        className="btn "
                        id="myForm"
                        // style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        GET OTP
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : <button
                        type="submit"
                        disabled={!(isValid && dirty)}
                        className="btn "
                        id="myForm"
                        // style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        GET OTP
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : values.pan == null || values.gst == null || values.msme == null || values.authorizationletter == null || values.certificateofcorporation == null || termandcondition == false ? <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        disabled
                        className="btn "
                        id="myForm"
                        // style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        GET OTP
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : <button
                        type="submit"
                        disabled={!(isValid && dirty)}
                        // disabled
                        className="btn "
                        id="myForm"
                        // style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        GET OTP
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : '' }
                               </div> : <button
                              // if user not select gst or unregister deal 
                                type="submit"
                                disabled
                                className="btn"
                                id="myForm"
                              >
                                Get OTP 
                              </button>}   
                            </div>


{/* {showotherform == false ? "" : <div className="col-lg-6 offset-lg-3 offset-md-0 col-md-12 ">
                           {showotps ? <button
                                type="submit"
                                disabled={!(isValid && dirty && verifystatus_mobile == 1 && verifystatus_email == 1)}
                                className="btn"
                                id="myForm"
                              >
                                Submit
                              </button> : <button
                                type="submit"
                                disabled={!(isValid && dirty)}
                                className="btn"
                                id="myForm"
                              >
                                Submit
                              </button>}   
                            </div>} */}
















{/* {statusstep5 == "" ? "" : <div className="col-xl-12">
<div class="alert alert-success mt-2" role="alert">
  {statusstep5}
</div>
</div>} */}












                            {/* <div className="sign-up">
                              <p>
                                <b
                                  style={{ cursor: "pointer",color:'#333' }}
                          
                                >     
Already have an account?  <span style={{ cursor: "pointer",color:'#230bb3' }}
                                  onClick={() => {
                                    nvg("/clientlogin");
                                  }}>Sign in</span>
                                </b>
                              </p>
                            </div>
                            <span
                              style={{
                                color: "red",
                                textAlign: "center",
                                display: "block",
                                marginTop: "3px",
                                fontSize: "15px",
                                textTransform: "capitalize",
                              }}
                            >
                              {voucherloginerror ? voucherloginerror : ""}
                            </span> */}
                         
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

    
        <div style={{width:'100%',overflow:'hidden'}}>
        <button
                 ref={openalert}
                                                  type="button"
                                                  style={{ fontSize: "12px" ,position:'absolute',left:'0px'}}
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#exampleModa1123456"
                                                  class="dropdown-item"
                                                                                                  >
                                                {/* sss */}
                                               
                                                </button>
                                                </div>
    </div>
  );
};

export default Register1;




const OTPPopup = ({ verifystatus_mobile,values,resendotp,verifymobile,setverifystatus_mobile,setFieldValue,showotherform,setFieldError }) => {
  const [otp, setOTP] = useState('');
  const [countdown, setCountdown] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(true);

  useEffect(() => {
    if (resendDisabled) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setResendDisabled(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendDisabled]);


  const handleResend = () => {

    setCountdown(60);
    setResendDisabled(true);
  };

  

  const abc = () =>{

  }

  return (
    <div className="col-lg-12 col-md-12 m-0">
                                <label
                                  style={{ margin: "0px", paddingTop: "8px" }}
                                  htmlFor="text"
                                >
                                  Mobile No. Verification <span style={{color:'red'}}>*</span>
                                </label>
                                <div className="log-sendotp">
                                  <Field
                                    type="tel"
                                    name="mobileotp"
                                    disabled={showotherform}
                                    className="login-input  newdesigninput mb-0"
                                    // placeholder={`Enter OTP Sent To ${values.mobile}`}
                                    value={values.mobileotp}
                                    // style={{borderBottom:verifystatus_mobile == 2 ? "1px solid red" : ''}}
                                    style={{border:'1px solid #ced4da'}}
                                    onChange={(e) => {
                                      setverifystatus_mobile(0);
                                      setFieldValue(
                                        "mobileotp",
                                        e.target.value.replace(/\D/g, '')
                                      );
                                    }}
                                  />
                                  



<span
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: "white",
                                      right:'2px'
                                    }}
                                   
                                  >
                                    {                                   
                                     verifystatus_mobile == 0 ? (
                                      <div> <span className="relpostion" onClick={() => {verifymobile({
                                        mobileotp: values.mobileotp,
                                        mobile: values.mobile,
                                      });
                                      }} >Verify</span> <span className="relpostion">|</span> <span className="relpostion" onClick={resendDisabled == true ? () => {abc()} : () => {resendotp(values.mobile,setFieldError,handleResend)}}>Resend {countdown == 0 ? '' : `(${countdown})`}</span></div>
                                    ) : verifystatus_mobile == 1 ? (
                                      <img
                                        src="./images/sucess.png"
                                        alt="404"
                                      />
                                    ) : verifystatus_mobile == 2 ? (
                                      <div> <span className="relpostion" onClick={() => {verifymobile({
                                        mobileotp: values.mobileotp,
                                        mobile: values.mobile,
                                      });
                                }} >Verify</span ><span className="relpostion">|</span> <span className="relpostion" onClick={resendDisabled == true ? () => {abc()} : () => {resendotp(values.mobile,setFieldError,handleResend)}}>Resend {countdown == 0 ? '' : `(${countdown})`}</span></div>
                                    ) : (
                                      <div> <span className="relpostion" onClick={() => {verifymobile({
                                        mobileotp: values.mobileotp,
                                        mobile: values.mobile,
                                      });
                                }} >Verify</span><span className="relpostion">|</span> <span className="relpostion" onClick={resendDisabled == true ? () => {abc()} : () => {resendotp(values.mobile,setFieldError,handleResend)}}>Resend {countdown == 0 ? '' : `(${countdown})`}</span></div>
                                    )}{" "}
                                  </span>



                                </div>
                                <span
                                  style={{
                                    display: "block",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {verifystatus_mobile == 2 ? (
                                    <p
                                      style={{
                                        color: "red",
                                        display:
                                          values.mobileotp == ""
                                            ? "none"
                                            : "block",
                                      }}
                                    >
                                      Incorrect OTP, Try Again{" "}
                                      <img
                                        src="./images/question.png"
                                        alt="404"
                                      />
                                    </p>
                                  ) : null}
                                </span>
                              </div>
  );
};




const OTPPopup2 = ({ setverifystatus_email,values,resendotpforemail,verifyemail,verifystatus_email,setFieldValue,showotherform,setFieldError }) => {
  const [otp, setOTP] = useState('');
  const [countdown, setCountdown] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(true);

  useEffect(() => {
    if (resendDisabled) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setResendDisabled(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendDisabled]);


  const handleResend = () => {

    setCountdown(60);
    setResendDisabled(true);
  };

  

  const abc = () =>{

  }

  return (
    <div className="col-lg-12 col-md-12">
                                <label
                                  style={{ margin: "0px", paddingTop: "8px" }}
                                  htmlFor="text"
                                >
                                  Email Verification <span style={{color:'red'}}>*</span>
                                </label>
                                <div className="log-sendotp">
                                  <Field
                                    type="tel"
                                    name="emailotp"
                                    className="login-input newdesigninput mb-0"
                                    disabled={showotherform}
                                    // placeholder={`Enter OTP Sent To ${values.email}`}
                                    value={values.emailotp}
                                    // style={{borderBottom:verifystatus_email == 2 ? "1px solid red" : ''}}
                                    style={{border:'1px solid #ced4da'}}
                                    onChange={(e) => {
                                      setverifystatus_email(0);
                                      setFieldValue("emailotp", e.target.value.replace(/\D/g, ''));
                                    }}
                                  />
                              
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: "white",
                                      right:'2px'
                                    }}
                                    
                                  >
                                    {
                                    
                                     verifystatus_email == 0 ? (
                                      <div> <span className="relpostion" onClick={() => {verifyemail({
                                              emailotp: values.emailotp,
                                              email: values.email,
                                            })
                                      }} >Verify</span> <span className="relpostion">|</span> <span className="relpostion" onClick={resendDisabled == true ? () => {abc()} : () => {resendotpforemail(values.email,setFieldError,1,handleResend)}}>Resend {countdown == 0 ? '' : `(${countdown})`}</span></div>
                                    ) : verifystatus_email == 1 ? (
                                      <img
                                        src="./images/sucess.png"
                                        alt="404"
                                      />
                                    ) : verifystatus_email == 2 ? (
                                      <div> <span className="relpostion" onClick={() => {verifyemail({
                                        emailotp: values.emailotp,
                                        email: values.email,
                                      })
                                }} >Verify</span ><span className="relpostion">|</span> <span className="relpostion" onClick={resendDisabled == true ? () => {abc()} : () => {resendotpforemail(values.email,setFieldError,1,handleResend)}}>Resend {countdown == 0 ? '' : `(${countdown})`}</span></div>
                                    ) : (
                                      <div> <span className="relpostion" onClick={() => {verifyemail({
                                        emailotp: values.emailotp,
                                        email: values.email,
                                      })
                                }} >Verify</span><span className="relpostion">|</span> <span className="relpostion" onClick={resendDisabled == true ? () => {abc()} : () => {resendotpforemail(values.email,setFieldError,1,handleResend)}}>Resend {countdown == 0 ? '' : `(${countdown})`}</span></div>
                                    )}{" "}
                                  </span>
                                </div>
                                {/* <span
                                  style={{
                                    display: "block",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {" "}
                                  {errors.emailotp && touched.emailotp ? (
                                    <p style={{ color: "red" }}>
                                      {errors.emailotp}
                                    </p>
                                  ) : null}
                                </span> */}
                                <span
                                  style={{
                                    display: "block",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {" "}
                                  {/* {errors.mobileotp && touched.mobileotp ? (
                                    <p style={{ color: "red" }}>
                                      {errors.mobileotp}
                                    </p>
                                  ) : null} */}
                                  {verifystatus_email == 2 ? (
                                    <p
                                      style={{
                                        color: "red",
                                        display:
                                          values.emailotp == ""
                                            ? "none"
                                            : "block",
                                      }}
                                    >
                                      Incorrect OTP, Try Again{" "}
                                      <img
                                        src="./images/question.png"
                                        alt="404"
                                      />
                                    </p>
                                  ) : null}
                                </span>
                              </div>
  );
};