import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { Register2val } from "../Validation/register2val";
import axios from "axios";
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import Register3 from "./Register3";
import Register4 from "./Register4";
import Register5 from "./Register5";
import Loader from "../components/Loader";

const Register2 = () => {
  const [step, setstep] = useState(0);
  const [verifystatus_gst, setverifystatus_gst] = useState(0);
  const [ungesgister, setungesgister] = useState(false);
  const [gstData, setgstData] = useState({});
  const [step1id, setstep1id] = useState({});
  const [step2id, setstep2id] = useState({});
  const inputfocus = useRef(null)
  const [step3id, setstep3id] = useState({});
  const [mesgforerror, setmesgforerror] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [sub, setsub] = useState(0);
  const [status, setStatus] = useState('');
  const [polling, setPolling] = useState(false);
  const [loader, setloader] = useState(true);
  const [loader4, setloader4] = useState(true);
  const [cityna,setcityna] = useState("")
  const buttonReftwo = useRef(null);
  const buttonRefthree = useRef(null);


const secondfunfoggt = async(formdata234,setFieldValue,validateForm,setTouched) =>{
  let url234 = `https://adminoneupv1.stackerbee.com/webhooks/check_data/`;
  const response234 = await axios.post(url234, formdata234);
  // setTimeout(() => {
    console.log("final rsponse is here",response234)
     if(response234.data[0].status == 'completed'){

      setloader(true)






      //  try {
      //   let url = `https://devbackend.kycfirst.io/api/search/`;
      //   const response = await axios.post(url, formdata);
      //   console.log("this is fst response is here", response);
      //   if (response.data.kycStatus == "SUCCESS") {
          // setgstData(gstno, response234.data[0].result);
        
          setverifystatus_gst(1);
         await setFieldValue("businessname", response234.data[0].result.source_output.legal_name);
         await setFieldValue(
            "address",
            `${
              response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name == null ? '' : response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.building_name} ${
              (response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.door_number)
            }`
          );
         await setFieldValue(
            "address2",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.location          
          );
          const processedGstNumber = response234.data[0].result.source_output.gstin.slice(2, -3);
        await setFieldValue("pan", processedGstNumber);
         await setFieldValue(
            "postalcode",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.pincode);
         await setFieldValue(
            "state",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.state_name);
         await setFieldValue(
            "city",response234.data[0].result.source_output.principal_place_of_business_fields.principal_place_of_business_address.dst);
          // const [month, day, year] =
          //   response234.data.kycResult.registrationDate.split("/");
          setFieldValue("registrationdate",response234.data[0].result.source_output.date_of_registration);
          // setFieldValue("registrationdate", `${year}-${month}-${day}`);
  
          if (inputfocus.current) {
            inputfocus.current.focus();
          }
          setTouched({
            gst_number: false,
            bussiness_name: false,
            first_address: false,
            second_address: false,
            postal_code: false,
            city: false,
            state: false,
            pancard_no: false,
            country: false,
            date_of_establishment: false,
          });
  
          // Trigger form validation to update isValid
          await validateForm();
          
      //   } else {
      //     setmesgforerror(response.data.error.message);
      //     setverifystatus_gst(2);
      //   }
      // } catch (error) {
      //   setverifystatus_gst(2);
      //   setmesgforerror(error.response.data.error.message);
      // }







      return true;
     }else{
      if(response234.data[0].status == 'failed'){
        setloader(true)
        setmesgforerror("Incorrect GST No");
          setverifystatus_gst(2);
      return true;

      }else{
        return false;
      }
     }
  // }, 2000);      
}



const buttonRef = useRef(null);

const autofillvalue = (setFieldValue,step1idcopy) =>{
  console.log("2345678wertyksdfg",step1idcopy)
  // setFieldValue('gstno',step1idcopy.gstno)
  // setFieldValue('businessname',step1idcopy.businessname)
  // setFieldValue('address',"sksksksmmcmceei")
  // setFieldValue('address',step1idcopy.address)
  if(step1idcopy.first_address){
    setFieldValue('address',step1idcopy.first_address)
  }
  if(step1idcopy.second_address){
    setFieldValue('address2',step1idcopy.second_address)
  }
  if(step1idcopy.postal_code){
    setFieldValue('postalcode',step1idcopy.postal_code)
  }
if(step1idcopy.city){
  setFieldValue('city',step1idcopy.city)
}

if(step1idcopy.state){
  setFieldValue('state',step1idcopy.state)
}
if(step1idcopy.pancard_no){
  setFieldValue('pan',step1idcopy.pancard_no)
}
  setFieldValue('registrationdate',step1idcopy.date_of_establishment)
  setFieldValue('country',step1idcopy.country)
  // businessname: step1id?.businessname ? step1id.businessname : '',
  // address: step1id?.address ? step1id.address : '',
  // address2: step1id?.address2 ? step1id.address2 : '',
  // postalcode: step1id?.postalcode ? step1id.postalcode : '',
  // city: step1id?.city ? step1id.city : '',
  // state: step1id?.state ? step1id.state : '',
  // pan: step1id?.pan ? step1id.pan : '',
  // country: step1id?.country ? step1id.country : 'India',
  // // businesslegaltype: step1id?.address ? step1id.address : '',
  // registrationdate: step1id?.registrationdate ? step1id.registrationdate : '',
}


console.log("this is happen when",step1id)


const startPolling = async (formdata234,setFieldValue,validateForm,setTouched) => {
  setPolling(true);
  let isComplete = false;
setloader(false)
  while (!isComplete) {
    isComplete = await secondfunfoggt(formdata234,setFieldValue,validateForm,setTouched);
    if (!isComplete) {
      await new Promise(resolve => setTimeout(resolve, 4000));
    }
  }

  setPolling(false);
};

  const verifygst = async (gstno, setFieldValue,validateForm,setTouched) => {
    const formdata = {
      gst_no: gstno,
    };
    try {

      let url23 = `https://adminoneupv1.stackerbee.com/webhooks/gst/`;
        const response23 = await axios.post(url23, formdata);
           console.log("this is true response",response23)
           if(response23.data.req_id){
            const formdata234 = {
              request_id: response23.data.req_id,
            };
              startPolling(formdata234,setFieldValue,validateForm,setTouched)
        
      }else{
      console.log("step 2 this is gst first api")
           }
    } catch (error) {
      console.log("this is gst first api",error)
    }
   
  };

const changestep = (newstep) =>{
  console.log("44555555555555",step)
  if(newstep == 0){
    setTimeout(() => {
     
      if(buttonRef.current){
        buttonRef.current.click();
      }
    }, 500);
  }
  if(newstep == 1){
    setTimeout(() => {
  console.log("44555555555555again",step)
     
      if(buttonReftwo.current){
  console.log("44555555555555againagain",setstep2id)

        buttonReftwo.current.click();
      }
    }, 500);
  }
  if(newstep == 2){
    setTimeout(() => {
  console.log("44555555555555again",step)
     
      if(buttonRefthree.current){
  console.log("44555555555555againagain",setstep2id)

        buttonRefthree.current.click();
      }
    }, 500);
  }
  setstep(newstep)
}

  const [countriesdata,setcountriesdata] = useState([])
  useEffect(()=>{ 
 async function getcountries() {
 let url = `${process.env.REACT_APP_API_URL}api/countries/`;
   try {
     const response = await axios.get(url);
     setcountriesdata(response.data)
   } catch (error) {
   }
 }
 getcountries()
   },[])

  const nvg = useNavigate();
const location = useLocation()
console.log("this location is here",location)
console.log("this is sis ss",location.state.id)

  const gstform = async(values,setFieldError) =>{
    const formdata =  {
      request_user:location.state.id,
      gst_number:ungesgister == false ? values.gstno : null,
      // bussiness_name: values.businessname,
      first_address: values.address,
      second_address: values.address2,
      postal_code: values.postalcode,
      city:values.city,
      state: values.state,
      country: values.country,
      pancard_no:values.pan,
      date_of_establishment: values.registrationdate
  }
    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/onboardingGstVerify/`;
    const response = await axios.post(url, formdata);
    console.log("respo of gst",response);
    setstep1id(response.data.data)
    setstep(1)
  //  if(response.data.message == "otp verify"){
  //  }else{
    
  //  }

    } catch (error) {
      console.log("this is the error",error.response.data);
      setFieldError("gstno","GST Number already Exists")
    }
  }


  const secondform = async(values) =>{
    const formdata =  {
      onboarding_gst_verify:step2id?.onboarding_gst_verify ? step2id?.onboarding_gst_verify : step1id.id,
      first_company_type: values.companytype,
      second_company_type: values.companytype2,
      website_link:values.weblink, 
      industry_type: values.industrytype
  }
    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/onboarding-bussiness-details/`;
    const response = await axios.post(url, formdata);
    console.log("respo of bussiness",response);
    setstep2id(response.data.data)
    setstep(2)
   if(response.data.message == "otp verify"){
   }else{
    
   }

    } catch (error) {
    }
  }

  const fetchstateandcity = async (value,setFieldError,setFieldValue) =>{
    if(value.length == 6){
  try {
    let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
    const response = await axios.get(url);
    console.log(" new response vvv",response)
    if(response.data.city == "NA"){
      setFieldValue('city','');
      setcityna("NA");
    setFieldError('city',"Please Enter Your City")
    }else{
      setFieldValue('city',response.data.city);
      setcityna("");
    }
    setFieldValue('state',response.data.state)
    const countrydata = countriesdata.filter(item2=>item2.printable_name == response.data.country.trim())
    console.log(";;;;;;",countrydata)
    setFieldValue('country',countrydata[0].printable_name);
  
  } catch (error) {
    // console.log("error",error.response.data)
    // setFieldValue('city','')
    // setFieldValue('state','')
    setFieldError('pincode',error.response.data.message)
  }
  }else{
    setFieldValue('city','')
    setFieldValue('state','')
    setFieldValue('country','')
  }
  }

  const thirdform = async(values) =>{
    const formdata =  {
      onboarding_bussiness_details:step2id.id,
      person_name: values.fullname,
      designation: values.designation,
      email:values.email, 
      mobile_number: values.mobile,
      first_address: values.address,
      second_address: values.address2,
      postal_code: values.postalcode,
      city:values.city,
      state: values.state,
      country: values.country,
  }
    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/onboarding-alternative-person-details/`;
    const response = await axios.post(url, formdata);
    console.log("respo of bussiness",response);
    setstep3id(response.data.data)
    setstep(3)
   if(response.data.message == "otp verify"){
   }else{
    
   }

    } catch (error) {
    }
  }

  const fourthform = async(values,setstatusstep5) =>{

console.log("this is super resp")
setloader4(false)
    const formdata = new FormData()
    formdata.append('bank_name',values.bankname)
    formdata.append('bank_acc_no',values.bankaccountnumber)
    formdata.append('bank_address',values.bankaddress)
    formdata.append('billing_address',values.billingaddress)
    formdata.append('upload_pan',values.pan)
    formdata.append('upload_gst',values.gst)
    formdata.append('ifsc_code',values.ifsc)
    formdata.append('onboarding_alternative_perDetails',step3id.id)
    if(sub == 0){
      try {
        let url = `${process.env.REACT_APP_API_URL}useraccount/onboarding-bank-details/`;
        setsub(1)
      const response = await axios.post(url, formdata);
      console.log("respo of bussiness",response);
      setstatusstep5(response.data.message)
      setloader4(true)
      setstatusstep5(response.data.message)
  // setTimeout(() => {
  //   nvg('/')
  // }, 5000);
      } catch (error) {
      }
    }else{
      console.log("first")
    }
    
    }

  return <>
  {step == 0 && (<div   style={{ backgroundColor: "rgb(249 249 249)",padding:'10px 0px'}} >
        <div
          className="fullwidth profileregister align-items-stretch"
          style={{ backgroundColor: "rgb(249 249 249)", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
         }}
        >
          <div className="min-width pt-3 " >
            <img
              src="./images/side.png"
              style={{
                marginBottom: "0px",
                cursor: "pointer",
                width: "100%",
                // height: "90vh",
                objectFit: "contain",
                position: "relative",
                top: "0px",
              }}
              alt
            />
          </div>
          <div className="min-width2">
            <div className="topsection">
              {/* </div>
            <div className="formsection topsection"> */}
  
              <Formik
                initialValues={{
                  gstno: "",
                  businessname: step1id?.businessname ? step1id.businessname : '',
                  address: step1id?.address ? step1id.address : '',
                  address2: step1id?.address2 ? step1id.address2 : '',
                  postalcode: step1id?.postalcode ? step1id.postalcode : '',
                  city: step1id?.city ? step1id.city : '',
                  state: step1id?.state ? step1id.state : '',
                  pan: step1id?.pan ? step1id.pan : '',
                  country: step1id?.country ? step1id.country : 'India',
                  // businesslegaltype: step1id?.address ? step1id.address : '',
                  registrationdate: step1id?.registrationdate ? step1id.registrationdate : '',
                }}
                validationSchema={Register2val}
                onSubmit={(values, { setFieldValue,setFieldError }) => {
                  console.log("this is formik ", values);
                  gstform(values,setFieldError);
                }}
              >
                {({
                  values,
                  errors,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  setFieldError,
                  dirty,
                  isValid,
                  validateForm,
                  setTouched,
                  
                }) => (
                  <Form
                    autoComplete="off"
                    className={`form-login container-fluid mt-2`}
                    style={{ width: "100%", minHeight: "90vh", height: "auto" }}
                    onSubmit={handleSubmit}
                  >
                    {console.log("this is eror",errors,"this is value section",values,"this is dd",touched,"this is real error",isValid,dirty)}
                    <div className="row px-4">
                      <div className="col-xl-10 col-md-12">
                        {/* <img
                        className="labelimage"
                          src="./images/figmaimage13.png"
                          style={{
                            marginBottom: "0px",
                            cursor: "pointer",
                            // width: "121%",
                            objectFit: "contain",
                            // position: "relative",
                            // top: "20px",
                          }}
                          alt
                        /> */}
                        <h5><span style={{color:'#9f9f9f',fontWeight:'500'}}> Step</span> 1/4 <span> <img src="./images/vector.png" style={{width:'15.5px',marginLeft:'5px'}} alt="" /> </span><span style={{color:'#3822ba'}}> Business Profile</span></h5>
                        <StepProgressBar
  startingStep={currentStep}
    onSubmit={()=>{console.log("first")}}
    steps={[
      {
        name: 'step 1',
      },
      {
        name: 'step 2',
      },
      {
        name: 'step 3',
      },
      {
        name: 'step 4',
      }
    ]}primaryBtnClass="#230BB3" />
                      </div>
                      <div className="col-12">
                        <h4 className="title098">
                          Please Verify Your Business Details
                        </h4>
                      </div>
                      {ungesgister == false ? <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          GST No. <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="gstno"
                            className="login-input mb-0"
                            placeholder={`Enter GST No`}
                            value={values.gstno}
                            style={{
                              borderBottom:
                                verifystatus_gst == 2 ? "1px solid red" : "",
                            }}
                            onChange={(e) => {
                              setverifystatus_gst(0);
                              setFieldValue("gstno", e.target.value);
                            }}
                          />
                          <span
                            style={{
                              cursor: "pointer",
                              backgroundColor: "white",
                            }}
                            onClick={() => {
                              verifygst(values.gstno, setFieldValue,validateForm,setTouched);
                            }}
                          >
                            {values.gstno == "" ? (
                              ""
                            ) : verifystatus_gst == 0 ? (
                              loader == false ? "" : "Verify"
                            ) : verifystatus_gst == 1 ? (
                              <img src="./images/sucess.png" alt="404" />
                            ) : (
                              "Verify"
                            )}{" "}
                          </span>
                        </div>
  
                        <span
                          style={{
                            display: "block",
                            marginBottom: "10px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.gstno == "" ? "none" : "block",
                              }}
                            >
                              {mesgforerror}
                              {/* <img src="./images/question.png" alt="404" /> */}
                            </p>
                          ) : null}
                            {errors.gstno && touched.gstno ? (
                            <p style={{ color: "red" }}>{errors.gstno}</p>
                          ) : null}
                        </span>
                      </div> : ''}

                      <div className="col-xl-12 col-md-12 d-flex align-items-center" style={{gap:'5px',fontSize:'14px',fontWeight:'500'}}>
                        {verifystatus_gst == 1 && ungesgister == true ? <input type="checkbox" name="" id="" checked onChange={()=>{setungesgister(!ungesgister);setverifystatus_gst(0)}} /> : <input type="checkbox" name="" id="" onChange={()=>{setungesgister(!ungesgister);setverifystatus_gst(1)}} /> }
                          Unregistered Dealer 
                      </div>

                      {loader == false ? <div className="col-xl-12 col-md-12">
                      <div style={{width:'100%'}} className="d-flex justify-content-center align-items-center"><Loader /></div>
                      </div> : ''}
                      
                     {/* {verifystatus_gst == 1 ? <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Business Name <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            innerRef={inputfocus}
                            name="businessname"
                            className="login-input mb-0"
                            placeholder={`Enter Your Business Name`}
                            value={values.businessname}
                            style={{
                              borderBottom:
                                errors.businessname && touched.businessname
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("businessname", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.businessname && touched.businessname ? (
                            <p style={{ color: "red" }}>{errors.businessname}</p>
                          ) : null}
                        </span>
                       </div> : "" }  */}
                     {verifystatus_gst == 1 ? <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        > 
                          Pan Card <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="pan"
                            
                            className="login-input mb-0"
                            placeholder={`Enter Your pan`}
                            value={values.pan}
                            style={{
                              borderBottom:
                                errors.pan && touched.pan
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("pan", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.pan && touched.pan ? (
                            <p style={{ color: "red" }}>{errors.pan}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> : ''} 
                     {verifystatus_gst == 1 ? <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        > 
                          Address <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="address"
                            className="login-input mb-0"
                            placeholder={`Enter Your Address`}
                            value={values.address}
                            style={{
                              borderBottom:
                                errors.address && touched.address
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("address", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.address && touched.address ? (
                            <p style={{ color: "red" }}>{errors.address}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> : ''} 
                     {verifystatus_gst == 1 ? <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        > 
                          Address 2 <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="address2"
                            className="login-input mb-0"
                            placeholder={`Enter Your Address 2`}
                            value={values.address2}
                            style={{
                              borderBottom:
                                errors.address2 && touched.address2
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("address2", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.address2 && touched.address2 ? (
                            <p style={{ color: "red" }}>{errors.address2}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> : ''} 
                      {/* without gst number  */}

                      {verifystatus_gst == 1 && ungesgister == true ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Postal Code <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="postalcode"
                            className="login-input mb-0"
                            placeholder={`Enter Your Postal Code`}
                            value={values.postalcode}
                            style={{
                              borderBottom:
                                errors.postalcode && touched.postalcode
                                  ? "1px solid red"
                                  : "",
                            }}
                            // onChange={(e) => {
                            //   setFieldValue("postalcode", e.target.value);
                            // }}
                          onChange={(e)=>{setFieldValue("postalcode",e.target.value.replace(/[^0-9\ ]/gi, ""));fetchstateandcity(e.target.value.replace(/[^0-9\ ]/gi, ""),setFieldError,setFieldValue)}}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.postalcode && touched.postalcode ? (
                            <p style={{ color: "red" }}>{errors.postalcode}</p>
                          ) : null}
                        </span>
  
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.postalcode == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div>  : ''}
                      {verifystatus_gst == 1 && ungesgister == true ? values.state !== '' && values.state !== null && cityna == 'NA' ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="city"
                            className="login-input mb-0"
                            placeholder={`Enter Your City`}
                            value={values.city}
                            style={{
                              borderBottom:
                                errors.city && touched.city
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.city && touched.city ? (
                            <p style={{ color: "red" }}>{errors.city}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> : <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="city"
                            className="login-input mb-0"
                            placeholder={`Enter Your City`}
                            value={values.city}
                            readOnly
                            style={{
                              borderBottom:
                                errors.city && touched.city
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.city && touched.city ? (
                            <p style={{ color: "red" }}>{errors.city}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> : ''}
                      {verifystatus_gst == 1 && ungesgister == true ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          State <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="state"
                          readOnly
                            className="login-input mb-0"
                            placeholder={`Enter Your State`}
                            value={values.state}
                            style={{
                              borderBottom:
                                errors.state && touched.state
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("state", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.state && touched.state ? (
                            <p style={{ color: "red" }}>{errors.state}</p>
                          ) : null}
                        </span>

                      </div> : ''}
                      {verifystatus_gst == 1 && ungesgister == true ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Country <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="country"
                            className="login-input mb-0"
                            placeholder={`Enter Your Country`}
                          readOnly
                            value={values.country}
                            style={{
                              borderBottom:
                                errors.country && touched.country
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("country", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.country && touched.country ? (
                            <p style={{ color: "red" }}>{errors.country}</p>
                          ) : null}
                        </span>

                      </div> : ''}







                      {/* without gst number  */}
<div style={{position:'relative',overflow:'hidden'}}>
                      <button type="button" ref={buttonRef} style={{position:'absolute',visibility:'hidden',top:'0px',left:'0px'}} onClick={()=>{autofillvalue(setFieldValue,step1id)}} ></button>
</div>


                      {/* with gst number  */}
                      {verifystatus_gst == 1 && ungesgister == false ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Country <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="country"
                            className="login-input mb-0"
                            placeholder={`Enter Your Country`}
                            value={values.country}
                            style={{
                              borderBottom:
                                errors.country && touched.country
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("country", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.country && touched.country ? (
                            <p style={{ color: "red" }}>{errors.country}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> : ''}
                      {verifystatus_gst == 1 && ungesgister == false ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          State <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="state"
                            className="login-input mb-0"
                            placeholder={`Enter Your State`}
                            value={values.state}
                            style={{
                              borderBottom:
                                errors.state && touched.state
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("state", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.state && touched.state ? (
                            <p style={{ color: "red" }}>{errors.state}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> : ''}
                      {verifystatus_gst == 1 && ungesgister == false ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          City <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="city"
                            className="login-input mb-0"
                            placeholder={`Enter Your City`}
                            value={values.city}
                            style={{
                              borderBottom:
                                errors.city && touched.city
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("city", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.city && touched.city ? (
                            <p style={{ color: "red" }}>{errors.city}</p>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.emailotp == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> : ''}
                      
                      {verifystatus_gst == 1 && ungesgister == false ? <div className="col-xl-6 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Postal Code <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="postalcode"
                            className="login-input mb-0"
                            placeholder={`Enter Your Postal Code`}
                            value={values.postalcode}
                            style={{
                              borderBottom:
                                errors.postalcode && touched.postalcode
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              // setverifystatus_gst(0);
                              setFieldValue("postalcode", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.postalcode && touched.postalcode ? (
                            <p style={{ color: "red" }}>{errors.postalcode}</p>
                          ) : null}
                        </span>
  
                        {/* <span
                          style={{
                            display: "block",
                            marginBottom: "20px",
                          }}
                        >
                          {verifystatus_gst == 2 ? (
                            <p
                              style={{
                                color: "red",
                                display: values.postalcode == "" ? "none" : "block",
                              }}
                            >
                              Incorrect OTP, Try Again{" "}
                              <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}
                        </span> */}
                      </div> : ''}  

                      {/* with gst number  */}

                      {/* {verifystatus_gst == 1 ? <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Business Legal Type
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="text"
                            name="businesslegaltype"
                            className="login-input mb-0"
                            placeholder={`Enter Your Business Legal Type`}
                            value={values.businesslegaltype}
                            style={{
                              borderBottom:
                                errors.businesslegaltype &&
                                touched.businesslegaltype
                                  ? "1px solid red"
                                  : "",
                            }}
                            onChange={(e) => {
                              setverifystatus_gst(0);
                              setFieldValue("businesslegaltype", e.target.value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "16px",
                          }}
                        >
                          {errors.businesslegaltype &&
                          touched.businesslegaltype ? (
                            <p style={{ color: "red" }}>
                              {errors.businesslegaltype}
                            </p>
                          ) : null}
                        </span>
                      </div> : ''} */}
                      {verifystatus_gst == 1 ? <div className="col-xl-12 col-md-12">
                        <label
                          style={{ margin: "0px", paddingTop: "8px" }}
                          htmlFor="text"
                        >
                          Date of Establishment (DD/MM/YYYY) <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="log-sendotp">
                          <Field
                            type="date"
                            name="registrationdate"
                            className="login-input mb-0"
                            placeholder={`Enter Your Business Legal Type`}
                            value={values.registrationdate}
                            style={{
                              borderBottom:
                                errors.registrationdate &&
                                touched.registrationdate
                                  ? "1px solid red"
                                  : "",
                            }}
                          />
                        </div>
                      </div> : ''}
                      {verifystatus_gst == 1 ?  <div className="col-xl-12 col-md-12 d-flex justify-content-end py-4">
                        <button
                          type="submit"
                          // disabled={!(isValid && dirty)}
                          className="btn "
                          id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                        >
                          Next
                          <span>
                            <FaArrowRightLong color="white" />
                          </span>
                        </button>
                      </div> : ''}
                     
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
   {step == 1 && <Register3 secondform={secondform} step2id={step2id} buttonReftwo={buttonReftwo} changestep={changestep} />} 
   {step == 2 && <Register4 thirdform={thirdform} buttonRefthree={buttonRefthree} step3id={step3id} step1id={step1id}  changestep={changestep} />} 
   {step == 3 && <Register5 fourthform={fourthform} step3id={step3id} step1id={step1id} changestep={changestep}  />} 
  </>

};

export default Register2;








